import DIContainer from "@/models/DIContainer";
import { OrcViewResult } from "@/models/OrcViewResult";
import IResponseHelper from "@/models/response/IResponseHelper";
import { OrcScreen } from "@/models/screen/OrcScreen";

class ScreenService {
  private httpClient = DIContainer.HttpJsonClient;

  post = async (data: OrcScreen) => {
    const response = await this.httpClient.post<IResponseHelper<OrcScreen>>(
      "/api/v1/screen/post",
      data
    );

    return response;
  };

  postview = async (View: OrcViewResult) => {
    const response = await this.httpClient.post<IResponseHelper<OrcViewResult>>(
      `/api/v1/screen/postview`, View
    );

    return response;
  };
}

export default new ScreenService();
