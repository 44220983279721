import { Icon } from "@iconify/react";
import React from "react";

function CommandTile2() {
  return (
    <div className="relative w-52 h-52 p-4 bg-gray-200 text-foreground hover:bg-opacity-35 hover:cursor-pointer transition duration-200 hover:scale-110">
      My Sales Overview
      <Icon
        icon="material-symbols-light:money-outline-sharp"
        className="absolute rounded-full w-16 h-16 bottom-3 left-4 text-slate-700"
        width="18"
        height="18"
      />
    </div>
  );
}

export default CommandTile2;
