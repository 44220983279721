import axios, { AxiosError } from "axios";
import HttpError from "../models/errors/HttpError";
import BaseError from "../models/errors/BaseError";
import Cookies from "js-cookie";
import i18n from "@/i18n/config";
import React from "react";


export enum HttpMethodType {
  GET = "get",
  POST = "post",
  DELETE = "delete",
  PUT = "put",
  PATCH = "patch",
}

export default class HttpClient {
  baseUrl?: string;
  headers?: any;


  constructor(settings: { baseUrl?: string; headers?: any }) {

    this.baseUrl = settings.baseUrl;
    this.headers = settings.headers;



    //this.baseUrl = "http://localhost:3000";
    //this.baseUrl = "https://demo.orc365.com";

    //this.baseUrl = " https://localhost:7142";

    this.baseUrl = process.env.REACT_APP_APIHOSTNAME; // " http://localhost:5048";
  }

  addHeader = (key: string, value: string) => {
    let app_version = "1";
    this.headers = {
      ...this.headers,
      isMobile: "true",
      version: app_version,
      [key]: value,
    };
  };

  setBearerToken = (token: string) => {
    this.addHeader("Authorization", `Bearer ${token}`);
  };

  post = <T>(
    url: string,
    data: any,
    headers?: any,
    onUploadProgress?: any
  ): Promise<T> => {
    return this.request<T>(
      url,
      HttpMethodType.POST,
      data,
      headers,
      onUploadProgress
    );
  };

  put = <T>(url: string, data: any, headers?: any): Promise<T> => {
    return this.request<T>(url, HttpMethodType.PUT, data, headers);
  };

  get = <T>(url: string, headers?: any): Promise<T> => {
    return this.request<T>(url, HttpMethodType.GET, undefined, headers);
  };

  delete = <T>(url: string, headers?: any): Promise<T> => {
    return this.request<T>(url, HttpMethodType.DELETE, undefined, headers);
  };

  request = async <T>(
    url: string,
    methodType: HttpMethodType = HttpMethodType.GET,
    data?: any,
    headers?: any,
    onUploadProgress?: any
  ): Promise<T> => {
    try {
      debugger;
      this.addHeader("Langu", i18n.language.toUpperCase());
      // const token = Cookies.get("token");
      // if (token) this.setBearerToken(token);
      const axiosResponse = await axios(`${this.baseUrl}${url}`, {
        headers: {
          ...this.headers,
          ...headers,
        },
        method: methodType,
        data: data,
        onUploadProgress,
        withCredentials: true,
      });

      // const axiosResponse = await fetch(`${this.baseUrl}${url}`, {
      //   headers: {
      //     ...this.headers,
      //     ...headers,
      //   },
      //   method: methodType,
      //   body: data,
      //   cache: "force-cache",
      //   next: { revalidate: 5 },
      // });
      debugger;
      if (axiosResponse.data && axiosResponse.data.usermessage) {
        // const currentContext = useContext(OrcContext);
        //this.mycontext.showError(axiosResponse.data.usermessage);
        // toast({
        //   title: "Error in Service",
        //   description: axiosResponse.data.usermessage,
        // });
        // alert(axiosResponse.data.usermessage);
        // setTimeout(() => {
        //   StatusBar.setHidden(true);
        //   AppService.showAlert(undefined, axiosResponse.data.usermessage);
        // }, 500);
      }

      //return axiosResponse.json();
      return axiosResponse.data;
    } catch (error: any) {
      debugger;
      throw new Error(error);
      
     
      console.log("error", error);  
      //return new object;
      //const response = error.response || {};

      //Handle 401 error
      // if (response.status === 401) {
      //   console.log("401 error");

      //   throw new BaseError("Network Error", error);
      //   // await AppService.logout();
      // }

      // if (response) {
      //   throw new HttpError(url, response.status, response.data, error);
      // } else {
      //   throw new BaseError("Network Error", error);
      // }
    }
  };

  // request = async <T>(
  //   url: string,
  //   methodType: HttpMethodType = HttpMethodType.GET,
  //   data?: any,
  //   headers?: any
  // ): Promise<T> => {
  //   try {
  //     const axiosResponse = await axios(url, {
  //       baseURL: this.baseUrl,
  //       headers: {
  //         ...this.headers,
  //         ...headers
  //       },
  //       method: methodType.toString(),
  //       data: data
  //     });

  //     if (axiosResponse.data && axiosResponse.data.usermessage) {
  //       setTimeout(() => {
  //         StatusBar.setHidden(true);
  //         AppService.showAlert(undefined, axiosResponse.data.usermessage);
  //       }, 300);
  //     }

  //     return axiosResponse.data;
  //   } catch (error) {
  //     const response = error.response || {};

  //     // Handle 401 error
  //     if (response.status === 401) {
  //       await AppService.logout();
  //     }

  //     if (response) {
  //       log("Http Error", url, response.status, response.data);
  //       throw new HttpError(url, response.status, response.data, error);
  //     } else {
  //       throw new BaseError("Network Error", error);
  //     }
  //   }
  // };
}
