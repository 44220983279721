import LunchMenuTile from "@/components/CommandTile1";
import CommandTile1 from "@/components/CommandTile1";
import CommandTile2 from "@/components/CommandTile2";
import EmployeeTile1 from "@/components/EmployeeTile1";
import MyAbsenceDaysTile from "@/components/MyAbsenceDaysTile";
import FigureTile1 from "@/components/MyAbsenceDaysTile";
import MyInboxTile from "@/components/MyInboxTile";
import { OrcContext } from "@/context/OrcContext";
import { Button } from "@/shadcn/ui/button";
import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//
function SamplePage1() {
  //
  const navigate = useNavigate();
  const currentContext = useContext(OrcContext);
  const themename = currentContext.Theme;
  //
  const [loaded, setLoaded] = useState(false);
  debugger;

  useEffect(() => {
    setLoaded(false);
    // if (currentContext.User == null) navigate("/login");

    setLoaded(true);
  }, []);

  const usr = currentContext.User;

  // const RemoveUs = () => {
  //   currentContext.removeUser();
  //   setTimeout(() => {
  //     navigate("/login");
  //   }, 1000);
  // };

  const Alert = () => {
    alert("deneme");
  };

  const changeTheme = () => {
    currentContext.updateTheme("Dark");
  };

  return (
    <>
      {loaded && (
        <>
          <div className="flex flex-row justify-between">
            <div className="">
              <div className="text-5xl mb-2 font-semibold">Page Header</div>
              <div className="text-2xl mb-10">Page Subtitle</div>
            </div>
            <div>
              <div className="text-2xl mb-10 flex flex-row gap-3">
                <Icon icon="carbon:filter" width="36" height="36" />
                <Icon icon="carbon:settings" width="36" height="36" />
                <Icon
                  icon="carbon:user-avatar-filled"
                  width="36"
                  height="36"
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row flex-wrap gap-3">
            <EmployeeTile1></EmployeeTile1>
            <LunchMenuTile></LunchMenuTile>
            <CommandTile2></CommandTile2>
            <MyAbsenceDaysTile></MyAbsenceDaysTile>
            <MyInboxTile></MyInboxTile>

            <EmployeeTile1></EmployeeTile1>
            <EmployeeTile1></EmployeeTile1>
            <EmployeeTile1></EmployeeTile1>
            <EmployeeTile1></EmployeeTile1>
            <EmployeeTile1></EmployeeTile1>
            <EmployeeTile1></EmployeeTile1>
            <EmployeeTile1></EmployeeTile1>
            <EmployeeTile1></EmployeeTile1>
          </div>
        </>
      )}
    </>
  );
}

export default SamplePage1;
