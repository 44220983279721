import DIContainer from "@/models/DIContainer";
import HttpClient from "@/utils/HttpClient";
import HttpJsonClient from "@/utils/HttpJsonClient";

export default class BaseApiService {
  protected httpClient: HttpClient;
  protected httpJsonClient: HttpJsonClient;

  constructor() {
    this.httpClient = DIContainer.HttpClient;
    this.httpJsonClient = DIContainer.HttpJsonClient;
  }
}
