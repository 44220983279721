import React, { Children, PropsWithChildren } from "react";
import clsx from "clsx";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/shadcn/ui/select";

interface IModuleSelect {
  Value: string;
  Disabled?: boolean;
  OnSelect?: (_value: string) => void;
}
const ModuleSelect: React.FC<PropsWithChildren<IModuleSelect>> = (props) => {
  return (
    <>
    <Select
    
        value={props.Value}
        onValueChange={(e) => props.OnSelect ? props.OnSelect(e) : null}
        disabled={props.Disabled}
        
        >
        <SelectTrigger className="w-[240px] bg-white" disabled={props.Disabled}>
            <SelectValue placeholder="Select Module" />
        </SelectTrigger>
        <SelectContent>
            <SelectGroup>
            <SelectItem value="SYSTEM">System Management</SelectItem>
            <SelectItem value="HR_OM">Organization Management</SelectItem>
            <SelectItem value="HR_PA">Personnel Administration</SelectItem>
            <SelectItem value="HR_ONBOARD">OnBoarding</SelectItem>
            <SelectItem value="HR_TIME">Time Management</SelectItem>
            <SelectItem value="HR_PAYROLL">Payroll</SelectItem>
            <SelectItem value="HR_TRAINING">Training</SelectItem>
            <SelectItem value="HR_PERF">Performance Management</SelectItem>
            <SelectItem value="HR_REW">Reward Management</SelectItem>
            <SelectItem value="HR_SURVEY">Survey Management</SelectItem>
            <SelectItem value="HR_TRAVEL">Travel Management</SelectItem>
            <SelectItem value="HR_COMP">Compensation Management</SelectItem>
            <SelectItem value="HR_ISG">Healt and Safety</SelectItem>
            <SelectItem value="HR_RD">Research&Development</SelectItem>
            <SelectItem value="HR_OFFBOARD">OffBoarding</SelectItem>
            <SelectItem value="SHARE">Share</SelectItem>
            <SelectItem value="HR_ESS">NULL</SelectItem>
            <SelectItem value="HR_ESS_COM">My Company</SelectItem>
            <SelectItem value="HR_ESS_SGN">E-Sign</SelectItem>
            <SelectItem value="HR_ESS_PYR">ESS My Payroll</SelectItem>
            <SelectItem value="HR_ESS_TIM">ESS My Time Data</SelectItem>
            <SelectItem value="HR_MSS">Manager-Self Services</SelectItem>
            <SelectItem value="HR_KVKK">GDPR</SelectItem>
            <SelectItem value="HR_REC">Recruitment</SelectItem>
            <SelectItem value="CRM">CRM</SelectItem>
            <SelectItem value="WF">Workflows</SelectItem>
            </SelectGroup>
        </SelectContent>
    </Select>
    </>
  );
};

export default ModuleSelect;