import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { motion } from "framer-motion";
import { Outlet, useLocation } from "react-router-dom";

export default function EmptyLayout() {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="flex items-center justify-center h-screen w-screen">

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 0.55 }}
          key={pathname}
        >
          <Outlet />
        </motion.div>

    </div>
  );
}
