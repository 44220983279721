import { Button } from "@/shadcn/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/shadcn/ui/card";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "@/shadcn/ui/dropdown-menu";
import { Separator } from "@/shadcn/ui/separator";
import clsx from "clsx";

export interface IOrcCardTablee {
  FieldName: string;
  ClassName?: string;
  ParentFieldName?: string;
}

export default function OrcCardTable(props: IOrcCardTablee) {
  return (
    <>
        <Card>
            <CardHeader className="flex flex-row items-start bg-muted/50 p-2">
              <div className="grid gap-0.5">
                <CardTitle className="group flex items-center gap-2 text-lg">
                Organizasyon Bilgilerim
                </CardTitle>
                <CardDescription>sddsd</CardDescription>
              </div>
            </CardHeader>
              <CardContent className="p-6 text-sm">
                <div className="grid gap-3">
                  <ul className="grid gap-3">
                    <li className="flex items-center justify-between">
                      <span className="text-muted-foreground font-semibold">
                      Şirket Kodu
                      </span>
                      <span>DEMO</span>
                    </li>
                    <li className="flex items-center justify-between">
                      <span className="text-muted-foreground font-semibold">
                      Organizasyon Birimi
                      </span>
                      <span>İnsan Kaynakları</span>
                    </li>
                    <li className="flex items-center justify-between">
                      <span className="text-muted-foreground font-semibold">
                      Organizasyon Birimi
                      </span>
                      <span>İnsan Kaynakları</span>
                    </li>
                    <li className="flex items-center justify-between">
                      <span className="text-muted-foreground font-semibold">
                      Organizasyon Birimi
                      </span>
                      <span>İnsan Kaynakları</span>
                    </li>
                  </ul>
                </div>
              </CardContent>
              {/* <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
                <div className="text-xs text-muted-foreground">
                  Updated <time dateTime="2023-11-23">November 23, 2023</time>
                </div>

              </CardFooter> */}
            </Card>
    </>
  );
}
