import DIContainer from "@/models/DIContainer";
import IResponseHelper from "@/models/response/IResponseHelper";
import SHelpModel from "@/models/shelp/SHelpModel";

class SearchHelpService {
  private httpClient = DIContainer.HttpJsonClient;

  get = async (shname: string, filtervalue?: string) => {
    const response = await this.httpClient.get<IResponseHelper<SHelpModel>>(
      "/api/v1/dd/shelp?shelpname=" + shname + "&filtervalue=" + filtervalue
    );

    return response;
  };
}

export default new SearchHelpService();
