import React, { PropsWithChildren } from "react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/shadcn/ui/select";

interface ITableClass {
  Value?: string;
  Disabled?: boolean;
  OnSelect?: (_value: string) => void;
}
const TableClass: React.FC<PropsWithChildren<ITableClass>> = (props) => {
  return (
    <>
    <Select
    
        value={props.Value}
        onValueChange={(e) => props.OnSelect ? props.OnSelect(e) : null}
        disabled={props.Disabled}
        
        >
        <SelectTrigger className="w-[240px] bg-white" disabled={props.Disabled}>
            <SelectValue placeholder="Table Class" />
        </SelectTrigger>
        <SelectContent>
            <SelectGroup>
            <SelectItem value="A">Application Data</SelectItem>
            <SelectItem value="M">Master Data</SelectItem>
            <SelectItem value="O">Organization Data</SelectItem>
            <SelectItem value="S">System Data</SelectItem>
            <SelectItem value="C">Config Data</SelectItem>
            <SelectItem value="I">Identity</SelectItem>
            </SelectGroup>
        </SelectContent>
    </Select>
    </>
  );
};

export default TableClass;

