import Item from "../screen/Item";

export class View {
  ViewName: string = "";
  Status: string = "";
  KeyTable: string = "";
  ModuleCode: string = "SYSTEM";
  Enable_Add: boolean = true;
  Enable_Mod: boolean = true;
  Enable_Det: boolean = true;
  Enable_Del: boolean = true;
  Enable_Exc: boolean = true;
  MaxRow: number = 50;
  EditType: EditType = EditType.Inline;

  Definition: string = "";
  LastChanged: string = "";
  LastChangedOn: string = "";
  Package: string = "";
  ViewType: string = "CONFIG";
  SourceName?: string = "";
  ExitProg?: string = "";
  FilterText?: string = "";
  Columns: ViewColumn[] = [];
  Relations: ViewRelation[] = [];
}

export class ViewColumn {
  TabName: string = "";
  FieldName: string = "";
  Rollname: string = "";
  OScreen: boolean = false;
  DScreen: boolean = false;
  Enabled: boolean = false;
  Obligatory: boolean = false;
  Seqnr: number = 99;
  SourceName: string = "";
  SortType: SortType = SortType.None;
  SortOrder?: number;
  DefaultValue?: string;
  Domname: string = "";
  Datatype: string = "";
  Length?: number;
  Definition: string = "";
  Primary: boolean = false;
  FieldType: string = "textbox";

  ValueSource?: string;
  ValueTable?: string;
  ValueField?: string;
  TextField?: string;
  ValueFilter?: string;

  Items?: Item[] = [];
}

export class ViewRelation {
  RTabName: string = "";
  RFieldName: string = "";
  FieldName: string = "";
  Seqnr: number = 99;
}

export enum EditType {
  Inline = 0,
  Modal = 1,
  Page = 2,
}

export enum SortType {
  None = 0,
  Ascending = 1,
  Descending = 2,
}
