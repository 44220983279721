import EmployeeTile1 from "@/components/EmployeeTile1";
import { OrcContext } from "@/context/OrcContext";
import { Button } from "@/shadcn/ui/button";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DonutChart, HStack, OrcButton, OrcCheckBox, OrcSelect, OrcTextArea, OrcTextBox, PieChart, SomePeople, Tile, VStack } from "./comps/Index";
import OrcDatePicker from "./comps/OrcDatePicker";
import OrcTimePicker from "./comps/OrcTimePicker";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/shadcn/ui/dialog";
import { Label } from "@/shadcn/ui/label";

//https://tailwindflex.com/@sammytg7/user-profile
function HomePage() {
  //
  const navigate = useNavigate();
  const currentContext = useContext(OrcContext);
  const themename = currentContext.Theme;
  //
  const [loaded, setLoaded] = useState(false);
  const [formData, setFormData] = useState("");

  const [openModal, setOpenModal] = useState(false);
  
  const data1 = [
    {
      "id": "women",
      "label": "Women",
      "value": 300
    },
    {
      "id": "men",
      "label": "Men",
      "value": 564
    }

  ]

  const [data, setData] = useState(data1);

  const [dateData, setDateData] = useState("20240202");
  const [timeData, setTimeData] = useState("164400");
  const [boolData, setBoolData] = useState(true);
  debugger;

  useEffect(() => {
    setLoaded(false);
    // if (currentContext.User == null) navigate("/login");
    setData(data1);
    setLoaded(true);
  }, []);

  const usr = currentContext.User;

  // const RemoveUs = () => {
  //   currentContext.removeUser();
  //   setTimeout(() => {
  //     navigate("/login");
  //   }, 1000);
  // };

  const Alert = () => {
    alert("deneme");
  };


  const changeTheme = () => {
    currentContext.updateTheme("Dark");
  };

  const changeData = () => {
    debugger;
    const newdata = [ ...data];
    newdata[0].value = newdata[0].value + 100;
    setData(newdata);
  };  

  const changeData2 = (val: any) => {
    debugger;

    setFormData(val);
  };  
  const changeData3 = (val: any) => {
    debugger;
    setDateData(val);
    //alert(val);
  };  

  const changeData4 = (val: any) => {
    debugger;
    setTimeData(val);
    //alert(val);
  };  

  const changeData5 = (val: any) => {
    debugger;
    setBoolData(val);
    //alert(val);
  };  

  const HelpRequested = (e : any, fieldname: string) => {
    debugger;
    e.preventDefault();
    setOpenModal(true);
   // alert(fieldname);
  };  

  return (
    <>
      {loaded && data && (
        <div className="flex flex-col p-4 gap-2 bg-gray-500 h-auto">
          <HStack FieldName="hstack1">

          <Tile FieldName="allcount" Title="Total HC" SubTitle="Total number of people" Figure="344" ClassName="bg-white" />
          <Tile FieldName="whitecollars" Title="White Collars" SubTitle="Total number of white collars" Figure="220" ClassName="bg-white" />
          <Tile FieldName="bluecollars" Title="Blue Collars" SubTitle="Total number of blue collars" Figure="98" ClassName="bg-white" />
          <Tile FieldName="trainees" Title="Total HC" SubTitle="Total number of Trainees" Figure="26" ClassName="bg-white" />
          <SomePeople FieldName="somepeople1" Title="Happy Birthday:)" SubTitle="Wish you beatifull life" ClassName="bg-pink-800 text-white" ParentFieldName="#" />
          </HStack>
          <HStack FieldName="hstack2">
            <PieChart Data={data} Title="Gender %" ParentFieldName="" ClassName="bg-white" FieldName="pie1" Refresh={() => changeData()} />
            <DonutChart Data={data} Title="University/Other" ParentFieldName="" ClassName="bg-green-50" FieldName="pie1" Refresh={() => changeData()} />
            <SomePeople FieldName="somepeople2" Title="Welcome to Company" SubTitle="Say Congrats" ClassName="bg-blue-900 text-yellow-100" ParentFieldName="#" />
          </HStack>
          {/* <VStack FieldName="vstack1">
            <OrcDatePicker FieldName="date1" ClassName="bg-white" Data="2021-09-01" ParentFieldName="vstack1" />
          </VStack> */}
          <OrcDatePicker FieldName="date1" ClassName="bg-white" Data="2021-09-01" ParentFieldName="vstack1" HasError={true} Caption="Begin Date" CaptionClassName="text-yellow-200" />
          <OrcDatePicker FieldName="date2" ClassName="bg-white" Data="2021-09-01" ParentFieldName="vstack1" />
          <OrcDatePicker FieldName="date3" ClassName="bg-white" Data="2021-09-01" ParentFieldName="vstack1" HasError={true}/>
          <OrcTimePicker FieldName="time1" ClassName="bg-white" Data="2021-09-01" ParentFieldName="vstack1"  />
          <OrcTextBox FieldName="name" ClassName="w-48" Data={formData} ParentFieldName="vstack1" Caption="First Name" Required={true} Disabled={true}
          CaptionClassName="text-white" Length={20} Type="text" OnChange={(value) => changeData2(value)} HasError={false} HelpRequested={(e) => HelpRequested(e,"name")}   />
          <OrcTextArea FieldName="notes" ClassName="w-full" Data={formData} ParentFieldName="vstack1" Caption="Notes" Required={false} Disabled={false} HasError={false}
          CaptionClassName="text-white" Length={300} OnChange={(value) => changeData2(value)}  HelpRequested={(e) => HelpRequested(e,"name")}   />
          <OrcDatePicker FieldName="begda" ClassName="w-full" Data={dateData} ParentFieldName="vstack1" Caption="Hiring Date" Required={false} Disabled={true} HasError={false}
          CaptionClassName="text-white" OnChange={(value) => changeData3(value)}  HelpRequested={(e) => HelpRequested(e,"begda")}   />
          <OrcTimePicker FieldName="begtim" ClassName="w-full" Data={timeData} ParentFieldName="vstack1" Caption="Begin Time" Required={false} Disabled={true} HasError={false}
          CaptionClassName="text-white" OnChange={(value) => changeData4(value)}  HelpRequested={(e) => HelpRequested(e,"begtim")}   />
          <OrcSelect FieldName="abstype"  Items={[{ Value: "HR", Text: "Human" },{ Value: "CO", Text: "Controlling" }]} 
            ParentFieldName="vstack1" Caption="Absence Type" Required={false} Disabled={true} HasError={false}
            CaptionClassName="text-white" OnSelect={(value) => changeData4(value)}  HelpRequested={(e) => HelpRequested(e,"abstype")}   />
          <OrcCheckBox FieldName="payment" Data={boolData} ParentFieldName="vstack1" Caption="Advance Payment" Required={false} Disabled={false} HasError={false}
            CaptionClassName="text-white" OnChecked={(value) => changeData5(value)}  HelpRequested={(e) => HelpRequested(e,"payment")}   />
          <OrcButton FieldName="payment" ClassName="w-full" ParentFieldName="vstack1" Caption="Submit Form" Disabled={true}
            CaptionClassName="text-white"   />
          {formData}
          <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
              <DialogContent className="sm:max-w-[425px] font-sapFont">
                <DialogHeader   >
                  <DialogTitle>title</DialogTitle>
                  <DialogDescription>
                  subtitle
                  </DialogDescription>
                </DialogHeader>
                <hr/>
                <div className="grid gap-1 py-4">
                  <div className="grid grid-cols-5 items-center gap-4">
                    <Label htmlFor="name" className="col-span-2 text-left">
                      Screen Code
                    </Label>
                  </div>
                  <div className="grid grid-cols-5 items-center gap-4">
                    <Label htmlFor="username" className="col-span-2 text-left">
                      Screen Program
                    </Label>
                  </div>

                </div>
                <DialogFooter className="sm:justify-start">
          <DialogClose asChild>
            <Button type="button" variant="secondary" onClick={() => setOpenModal(false)}>
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
              </DialogContent>
          </Dialog>
        </div>

      )}  
    </>
  );
}

export default HomePage;
