import { RegisterCompanyModel } from "@/components/ClientRegisterComp";
import { ContactFormModel } from "@/components/ContactComp";
import DIContainer from "@/models/DIContainer";
import IResponseHelper from "@/models/response/IResponseHelper";

class CompanyFormService {
  private httpClient = DIContainer.HttpJsonClient;

  post = async (cForm : RegisterCompanyModel) => {
    const response = await this.httpClient.post<IResponseHelper<boolean>>(
      `/api/v1/newcompany`,
      cForm
    );

    return response;
  };
}

export default new CompanyFormService();
