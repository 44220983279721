import React, { Children, PropsWithChildren } from "react";
import clsx from "clsx";

interface IHStack {
  FieldName: string;
  ClassName?: string;
  ParentFieldName?: string;
}
const HStack: React.FC<PropsWithChildren<IHStack>> = (props) => {
  return (
    <>
      <div
        key={props.FieldName}
        className={clsx(
          "w-full flex flex-row flex-wrap gap-2",
          props.ClassName
        )}
      >
        {props.children}
      </div>
    </>
  );
};

export default HStack;
