import TopBrands from "@/components/TopBrands";
import PrivacyComp from "@/components/PrivacyComp";

function PrivacyPage() {
  return (
    <section className="w-full min-h-[500px]">
      <PrivacyComp />
      <TopBrands />
      {/* <ContactForm /> */}
    </section>
  );
}

export default PrivacyPage;
