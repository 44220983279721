import React from "react";
import ContactForm from "../components/ContactForm";
import ContactComp from "@/components/ContactComp";
import TopBrands from "@/components/TopBrands";
import HRDEVSHOPForm from "@/components/HRDEVSHOPForm";

function ContactHRDevShop() {
  return (
    <section className="w-full min-h-[500px]">
      <HRDEVSHOPForm />
      <TopBrands />
      {/* <ContactForm /> */}
    </section>
  );
}

export default ContactHRDevShop;
