import React, { PropsWithChildren } from "react";
import clsx from "clsx";

interface IVStack {
  FieldName: string;
  ClassName?: string;
  ParentFieldName?: string;
}
const VStack: React.FC<PropsWithChildren<IVStack>> = (props) => {
  return (
    <>
      <div
        key={props.FieldName}
        className={clsx("w-full flex flex-col gap-2", props.ClassName)}
      >
        {props.children}
      </div>
    </>
  );
};

export default VStack;
