import DIContainer from "@/models/DIContainer";
import IResponseHelper from "@/models/response/IResponseHelper";
import { Domain } from "@/models/datadictionary/Domain";

class DomainService {
  private httpClient = DIContainer.HttpJsonClient;

  post = async (data: Domain) => {
    const response = await this.httpClient.post<IResponseHelper<Domain>>(
      "/api/v1/dd/domain",
      data
    );

    return response;
  };

  activate = async (data: Domain) => {
    const response = await this.httpClient.post<IResponseHelper<Domain>>(
      "/api/v1/dd/domain/activate",
      data
    );

    return response;
  };

  put = async (data: Domain) => {
    const response = await this.httpClient.put<IResponseHelper<Domain>>(
      "/api/v1/dd/domain",
      data
    );

    return response;
  };

  isExist = async (domname: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/dd/domain/isexist?DomName=" + domname
    );

    return response.data;
  };

  getByName = async (domname: string) => {
    const response = await this.httpClient.get<IResponseHelper<Domain>>(
      "/api/v1/dd/domain/getByName?DomName=" + domname
    );

    return response.data;
  };

  delete = async (domname: string) => {
    const response = await this.httpClient.delete<IResponseHelper<Boolean>>(
      "/api/v1/dd/domain/delete?DomName=" + domname
    );

    return response.data;
  };
}

export default new DomainService();
