export enum Enum_Icons {
  HomeIcon = "HomeIcon",
  EssIcon = "EssIcon",
  EssTMIcon = "EssTMIcon",
  WorkflowIcon = "WorkflowIcon",
  GraphicIcon = "GraphicIcon",
  SocialIcon = "SocialIcon",
  OrgChartIcon = "OrgChartIcon",
  EmpDataIcon = "EmpDataIcon",
  PerformanceIcon = "PerformanceIcon",
  TimeAdminIcon = "TimeAdminIcon",
  PayrollIcon = "PayrollIcon",
  SettingsIcon = "SettingsIcon",
  PersonalIcon = "PersonalIcon",
  AddressIcon = "AddressIcon",
  PayIcon = "PayIcon",
  BankIcon = "BankIcon",
  FamilyIcon = "FamilyIcon",
  EduIcon = "EduIcon",
  ExperienceIcon = "ExperienceIcon",
  AssetsIcon = "BankIcon",
  DatesIcon = "AssetsIcon",
  CommIcon = "CommIcon",
  IdentityIcon = "IdentityIcon",
  AbsenceIcon = "AbsenceIcon",
  QuotaIcon = "QuotaIcon",
  TimeEventsIcon = "TimeEventsIcon",
  OvertimeIcon = "OvertimeIcon",
  UserIcon = "UserIcon",
  UserTieIcon = "UserTieIcon",
  Excel = "Excel",
  FaChartPie = "FaChartPie",
  FaRegFilePdf = "FaRegFilePdf",
  FaMobileAlt = "FaMobileAlt",
  GiBrickWall = "GiBrickWall",
  IoIosLink = "IoIosLink",
  FiUser = "FiUser",
}

// export enum Enum_WebDomains {
//   hrsp = "hrsp",
//   orcplus = "orcplus",
//   orchr = "orchr",
// }

export enum CurrentDomain {
  orchestraplus = "orchestra.plus",
  orchestrahr = "orchestrahr.com",
  hrspcomtr = "hrsp.com.tr",
  localhost = "localhost",
}

export enum HttpMethodType {
  GET = "get",
  POST = "post",
  DELETE = "delete",
  PUT = "put",
  PATCH = "patch",
}

export enum SchemaType {
  Screen = 0,
  Data = 1,
  Table = 2,
}

export enum EditType {
  Inline = 0,
  Modal = 1,
  Page = 2,
}

export enum ScreenEvent {
  Get = 1, //screen loading
  Post = 2, //screen post
  PostBack = 3, //screen field change
}

export enum DataTypes {
  CHAR = 0,
  NUMC = 1,
  BOOL = 2,
  ACCP = 3,
  BYTE = 4,
  DECM = 5,
  CUKY = 6,
  CURR = 7,
  DATS = 8,
  INT4 = 9,
  MAND = 10,
  QUAN = 11,
  TIMS = 12,
  UNIT = 13,
}

export enum FieldType {
  //Containers
  OrcDiv = 0,
  OrcHStack = 1,
  OrcVStack = 2,
  OrcTable = 3,
  OrcForm = 4,
  //Typography
  OrcHeader1 = 10,
  OrcHeader2 = 11,
  OrcHeader3 = 12,
  OrcHeader4 = 13,
  OrcText = 14,
  OrcBadge = 15,
  //Tiles
  OrcTile = 30,
  OrcFigureTile = 31,
  OrcPieTile = 32,
  //Form Fields
  OrcTextBox = 50,
  OrcTextArea = 51,
  OrcSelect = 52,
  OrcMultiSelect = 53, 
  OrcCheckBox = 54,
  OrcDate = 55,
  OrcFileUpload = 56,
  OrcImageBox = 57,
  OrcLink = 58,
  OrcButton = 59,

}
