import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "./PageHeader";
function RefCompanies() {
  const { t } = useTranslation();

  const hrspClients = [
    {
      Comp: "ABB",
      LogoLink: "/images/logo/abb.png",
      LogoClass: "h-24 md:h-34 w-auto",
    },
    // {
    //   Comp: "ARÇELİK",
    //   LogoLink: "/images/logo/arcelik.png",
    //   LogoClass: "h-20 md:h-28 w-auto",
    // },
    // {
    //   Comp: "A101",
    //   LogoLink: "/images/logo/a101.png",
    //   LogoClass: "h-12 md:h-24 w-auto",
    // },
    // {
    //   Comp: "Arçelik LG",
    //   LogoLink: "/images/logo/arceliklg.png",
    //   LogoClass: "h-12 md:h-28 w-auto",
    // },
    {
      Comp: "Aygaz",
      LogoLink: "/images/logo/aygaz.png",
      LogoClass: "h-12 md:h-28 w-auto",
    },
    {
      Comp: "CBN",
      LogoLink: "/images/logo/cbn.png",
      LogoClass: "h-12 md:h-20 w-auto",
    },
    {
      Comp: "Çayeli",
      LogoLink: "/images/logo/cayeli.png",
      LogoClass: "h-12 md:h-28 w-auto",
    },
    {
      Comp: "Divan",
      LogoLink: "/images/logo/divan.svg",
      LogoClass: "h-12 md:h-32 w-auto",
    },
    {
      Comp: "Düzey",
      LogoLink: "/images/logo/duzey.png",
      LogoClass: "h-12 md:h-28 w-auto",
    },
    {
      Comp: "Göltaş",
      LogoLink: "/images/logo/goltas.png",
      LogoClass: "h-12 md:h-28 w-auto",
    },
    // {
    //   Comp: "Güvenok",
    //   LogoLink: "/images/logo/guvenok.png",
    //   LogoClass: "h-12 md:h-32 w-auto",
    // },
    // {
    //   Comp: "Hapimag",
    //   LogoLink: "/images/logo/hapimag.png",
    //   LogoClass: "h-12 md:h-32 w-auto",
    // },
    {
      Comp: "Koçtaş",
      LogoLink: "/images/logo/koctas.png",
      LogoClass: "h-12 md:h-28 w-auto",
    },
    // {
    //   Comp: "Koçzer",
    //   LogoLink: "/images/logo/koczer.png",
    //   LogoClass: "h-12 md:h-16 w-auto",
    // },
    {
      Comp: "kocuniversity",
      LogoLink: "/images/logo/kocuniversity.png",
      LogoClass: "h-12 md:h-12 w-auto",
    },
    {
      Comp: "Koç Yaşa",
      LogoLink: "/images/logo/kocyasa.png",
      LogoClass: "h-12 md:h-20 w-auto",
    },
    // {
    //   Comp: "Koton",
    //   LogoLink: "/images/logo/koton.png",
    //   LogoClass: "h-12 md:h-22 w-auto",
    // },
    {
      Comp: "Lukoil",
      LogoLink: "c",
      LogoClass: "h-12 md:h-16 w-auto",
    },
    // {
    //   Comp: "LLK",
    //   LogoLink: "/images/logo/llk.png",
    //   LogoClass: "h-12 md:h-20 w-auto",
    // },
    {
      Comp: "Mercedes",
      LogoLink: "/images/logo/mercedes.png",
      LogoClass: "h-12 md:h-28 w-auto",
    },
    {
      Comp: "Orma",
      LogoLink: "/images/logo/orma.png",
      LogoClass: "h-12 md:h-28 w-auto",
    },
    // {
    //   Comp: "Penti",
    //   LogoLink: "/images/logo/penti.png",
    //   LogoClass: "h-12 md:h-16 w-auto",
    // },
    {
      Comp: "Prometeon",
      LogoLink: "/images/logo/prometeon.jpg",
      LogoClass: "h-12 md:h-12 w-auto",
    },
    // {
    //   Comp: "Prysmian",
    //   LogoLink: "/images/logo/prysmian.png",
    //   LogoClass: "h-12 md:h-32 w-auto",
    // },
    {
      Comp: "Sendeo",
      LogoLink: "/images/logo/sendeo.png",
      LogoClass: "h-12 md:h-26 w-auto",
    },
    {
      Comp: "Şölen",
      LogoLink: "/images/logo/solen.png",
      LogoClass: "h-12 md:h-24 w-auto",
    },
    // {
    //   Comp: "Standard Profil",
    //   LogoLink: "/images/logo/standardprofil.png",
    //   LogoClass: "h-12 md:h-24 w-auto",
    // },
    {
      Comp: "Tat",
      LogoLink: "/images/logo/tat.png",
      LogoClass: "h-12 md:h-24 w-auto",
    },
    {
      Comp: "Token",
      LogoLink: "/images/logo/token.png",
      LogoClass: "h-12 md:h-28 w-auto",
    },
    {
      Comp: "Uno",
      LogoLink: "/images/logo/uno.png",
      LogoClass: "h-12 md:h-28 w-auto",
    },
  ];

  return (
    <section className="py-10 xl:pt-10">
      <div className="px-4 mx-auto font-sapFont">
        <PageHeader
          title={t("ClientHeading")}
          subtitle={t("ClientSubHeading")}
        />
        {/* <div className="mb-8 text-center">
          <h3 className="mb-4 text-4xl md:text-5xl text-coolGray-900 font-bold tracking-tighter">
            {t("ClientHeading")}
          </h3>
          <p className="text-lg md:text-xl text-coolGray-500 font-medium">
            {t("ClientSubHeading")}
          </p>
        </div> */}
        <div className="flex flex-wrap justify-center -mx-4">
          {hrspClients.map((refcomp, index) => (
            <div
              key={"div" + index.toString()}
              className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 lg:mb-0"
            >
              <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-lg mb-4 shadow-md">
                <img
                  src={refcomp.LogoLink}
                  alt=""
                  className={clsx(
                    "hover:cursor-pointer hover:scale-125 transform transition duration-200 mx-auto",
                    refcomp.LogoClass
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default RefCompanies;
