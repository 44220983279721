import DIContainer from "@/models/DIContainer";
import { Table } from "@/models/datadictionary/Table";
import { View } from "@/models/datadictionary/View";
import IResponseHelper from "@/models/response/IResponseHelper";

class ViewService {
  private httpClient = DIContainer.HttpJsonClient;

  post = async (data: View) => {
    const response = await this.httpClient.post<IResponseHelper<View>>(
      "/api/v1/dd/view",
      data
    );

    return response;
  };

  activate = async (data: View) => {
    const response = await this.httpClient.post<IResponseHelper<View>>(
      "/api/v1/dd/view/activate",
      data
    );

    return response;
  };

  put = async (data: View) => {
    const response = await this.httpClient.put<IResponseHelper<View>>(
      "/api/v1/dd/view",
      data
    );

    return response;
  };

  isExist = async (tabname: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/dd/view/isexist?tabname=" + tabname
    );

    return response.data;
  };

  getByName = async (tabname: string) => {
    const response = await this.httpClient.get<IResponseHelper<View>>(
      "/api/v1/dd/view/getByName?tabname=" + tabname
    );

    return response.data;
  };

  delete = async (tabname: string) => {
    const response = await this.httpClient.delete<IResponseHelper<Boolean>>(
      "/api/v1/dd/view/delete?tabname=" + tabname
    );

    return response.data;
  };
}

export default new ViewService();
