
import HttpClient from "@/utils/HttpClient";
import HttpJsonClient from "../utils/HttpJsonClient";

export default {
  HttpClient: new HttpClient({
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Requested-With": "XMLHttpRequest",
    },
  }),
  HttpJsonClient: new HttpJsonClient({}),
};
