import React from "react";
import clsx from "clsx";

export interface IOrcHeader1 {
  FieldName: string;
  ClassName?: string;
  Text: string;
  ParentFieldName: string;
}

export default function OrcHeader1(props: IOrcHeader1) {
  return (
    <>
      <h1
        key={props.FieldName}
        id={props.FieldName}
        className={clsx("text-3xl font-bold text-black", props.ClassName)}
      >
        {props.Text}
      </h1>
    </>
  );
}
