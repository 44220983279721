import Contact from "@/pages/Contact";
import ContactFormService from "@/services/contactform/ContactFormService";
import clsx from "clsx";
import DOMPurify from "dompurify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export class HRDEVSHOPFormModel {
  EName : string = "";
  EMail : string = "";
  Phone : string = "";

}

export class ErrorField {
  FieldName : string = "";
  Error? : string = "";

}

function HRDEVSHOPForm() {
  //
  const { t, i18n } = useTranslation("", { keyPrefix: "HRDEVSHOPForm" });
  //
  let FormObject : HRDEVSHOPFormModel = { EName : "", EMail:"", Phone:""};
  let ErrorFieldArray : ErrorField[] = [];
  //
  // const[loaded, setLoaded] = useState<boolean>(true);
  const[formData, setContactData] = useState<HRDEVSHOPFormModel>(FormObject);
  const[errorFields, setErrorField] = useState<ErrorField[]>(ErrorFieldArray);
  //
  const navigate = useNavigate();

  const PropChanged = (fname: string, val: any) => {
    // Sanitize the input

    //val = DOMPurify.sanitize(val);
    // Update the state
    if (fname == "EName") {
      let newobj: HRDEVSHOPFormModel = { ...formData, EName: val };
      setContactData(newobj);
    } else if (fname == "EMail") {
      let newobj: HRDEVSHOPFormModel = { ...formData, EMail: val };
      setContactData(newobj);
    } else if (fname == "Phone") {
      let newobj: HRDEVSHOPFormModel = { ...formData, Phone: val };
      setContactData(newobj);
    } 
  };

  const ValidateForm = () => {
    
    let errFields: ErrorField[] = [];
    const tagRegex = /<.+?>/g;   
    //
    if(formData.EName.length==0)
      errFields.push({ FieldName : "EName" });
    else if (tagRegex.test(formData.EName)) // Check if email matches the regex pattern
      errFields.push({ FieldName : "EName", Error: "Forbidden character in text" }); // Add specific error for invalid email format
    else
      errFields = errFields.filter(o=>o.FieldName != "EName");


      
    if(formData.Phone.length==0)
      errFields.push({ FieldName : "Phone" });
    else if (tagRegex.test(formData.Phone)) // Check if email matches the regex pattern
      errFields.push({ FieldName : "Phone", Error: "Forbidden characted in text" }); // Add specific error for invalid email format
    else
      errFields = errFields.filter(o=>o.FieldName != "Phone");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(formData.EMail.length==0)
      errFields.push({ FieldName : "EMail" });
    else if (!emailRegex.test(formData.EMail)) // Check if email matches the regex pattern
      errFields.push({ FieldName : "EMail", Error: "Invalid email format" }); // Add specific error for invalid email format
    else
      errFields = errFields.filter(o=>o.FieldName != "EMail");

     

    

      debugger;
    setErrorField(errFields);

    if(errFields.length>0)
      return false;
    else
    {
     
      return true;
    }
     
  
  }

  const SubmitForm = async () => {
    debugger;

    if(ValidateForm() == false)
      return;

    const res = await ContactFormService.postHRDevShopForm(formData);

    if(res.data)
    {
      navigate("/thankyou");
    }


    //alert(JSON.stringify(contactData));
  };



  const HasError = (fieldname : string) => {

    return errorFields.filter(o=>o.FieldName == fieldname).length > 0;
  }


  return (
    true ? (<section className="px-8 md:px-24 py-32">
      <div className="flex flex-wrap -m-4">
        <div className="w-full lg:w-2/3 p-4">
          <p className="tracking-tight text-gray-600 mb-4">{t("Contactus")}</p>
          <h1 className="font-heading tracking-tight text-5xl md:text-6xl font-medium mb-8">{t("GetInTouch")}</h1>
          <ul>
            <li className="font-heading tracking-tight text-3xl md:text-3xl font-medium mb-4">Introduction</li>
            <p className="font-medium mb-8">
            Orchestra Build workshops are designed for business experts, developers and consultants to learn, design and develop together. In every session, we develop a solution for specific business requirement. While building the solution, we will discuss and find the best solution for customers. After the course, participants will have a solution that they can use in their own business. The course is paid, discounts applied for Orchestra partners.
            </p>
            <li className="font-heading tracking-tight text-3xl md:text-3xl font-medium mb-8">Requirements</li>
            <p className="font-medium mb-8">
            Participants should have basic knowledge of business processes and development skills. The course is designed for business experts, developers and consultants who like to develop solutions for their company or clients. As programming language C# is used for web and mobile development codebase. We advise companies to join with one functional one developer expert.
            </p>
            </ul>
          <form action="#">
            <div className="max-w-2xl">
              <div className="flex flex-wrap -m-4 mb-3">
                <div className="w-full sm:w-1/2 p-4">
                  <label className="block mb-3 text-sm font-medium tracking-tight">{t("YourName")}</label>
                  <input 
                    type="text" 
                    id="contact1-input1" 
                    className={clsx(
                      "w-full px-6 py-4 rounded-full border border-gray-900 placeholder-gray-500 focus:ring-4 focus:ring-orange-200 outline-none transition duration-200",
                      HasError("EName") ? "bg-red-50" : ""
                    )}  
                    placeholder="" 
                    value={formData.EName}
                    maxLength={40}
                    onChange={(e) => PropChanged("EName", e.target.value)}
                  />
                </div>
                <div className="w-full sm:w-1/2 p-4">
                  <label className="block mb-3 text-sm font-medium tracking-tight">{t("Phone")}</label>
                  <input 
                  type="phone" 
                  id="contact1-input2" 
                  className={clsx(
                    "w-full px-6 py-4 rounded-full border border-gray-900 placeholder-gray-500 focus:ring-4 focus:ring-orange-200 outline-none transition duration-200",
                    HasError("Phone") ? "bg-red-50" : ""
                  )}
                  value={formData.Phone}
                  maxLength={14}
                  onChange={(e) => PropChanged("Phone", e.target.value)}
                  
                  />
                </div>
              </div>
              <label className="block mb-3 text-sm font-medium tracking-tight">{t("EMail")}</label>
              <input 
                type="email" 
                id="contact1-input3" 
                className={clsx(
                  "w-full mb-3 px-6 py-4 rounded-full border border-gray-900 placeholder-gray-500 focus:ring-4 focus:ring-orange-200 outline-none transition duration-200",
                  HasError("EMail") ? "bg-red-50" : ""
                )}
                
                value={formData.EMail}
                maxLength={20}
                onChange={(e) => PropChanged("EMail", e.target.value)}
                />

              <button 
                type="button" 
                onClick={SubmitForm}
                className="bg-orange-900 h-16 rounded-full mt-2 px-6 py-4 inline-flex items-center justify-center gap-2 hover:bg-orange-600 focus:bg-orange-500 focus:ring-4 focus:ring-orange-200 transition duration-200">
                <span className="text-white tracking-tight">Join OrchestraBuild</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                  <path d="M14 6.66669H7.33333C4.38781 6.66669 2 9.0545 2 12V13.3334M14 6.66669L10 10.6667M14 6.66669L10 2.66669" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </button>
              <div className="mt-2 text-sm">
                *by clicking this button you agree to our <Link to="/privacy" className="text-blue-500">Privacy Policy</Link>
              </div>
            </div>
          </form>
        </div>
        <div className="w-full lg:w-1/3 p-4">
          <img className="rounded-2xl w-full object-cover h-[600px]"  src="/images/products/hrdevshopimage.png" alt="" />
        </div>
      </div>
    </section>) : <div></div>
  );
}

export default HRDEVSHOPForm;
