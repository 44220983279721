// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/pie
import { ResponsivePieCanvas } from '@nivo/pie'
import clsx from 'clsx';
import { constants } from 'perf_hooks';

export interface IPieChart {
  FieldName: string;
  ClassName?: string;
  Data: any;
  Title: string;
  ParentFieldName: string;
  Refresh: () => void
}

const PieChart = (props: IPieChart) => (
  <div key={props.FieldName} className={clsx("w-64 h-64 p-2 flex flex-col gap-0 border-2 border-gray-100 rounded-sm", props.ClassName)}>
    <div className="w-full flex flex-row justify-between">
        <div>{ props.Title}</div>
        <div onClick={props.Refresh} className="hover:cursor-pointer">...</div>
    </div>
    <div className='w-64 h-64'>

      <ResponsivePieCanvas
          data={props.Data}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={{ scheme: 'paired' }}
          borderColor={{
              from: 'color',
              modifiers: [
                  [
                      'darker',
                      0.6
                  ]
              ]
          }}
          enableArcLabels={false}
          enableArcLinkLabels={false}
      />
    </div>
  </div>

)

export default PieChart;