import { Input } from "@/shadcn/ui/input";
import { Label } from "@/shadcn/ui/label";
import React, { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";
import { Button } from "@/shadcn/ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Table } from "@/models/datadictionary/Table";
import TableService from "@/services/dd/TableService";
import { SearchHelp } from "../../comps/SearchHelp";
import { Checkbox } from "@/shadcn/ui/checkbox";
import DataElementService from "@/services/dd/DataElementService";
import { toast } from "@/shadcn/ui/use-toast";
import { Toaster } from "@/shadcn/ui/toaster";
import { Icon } from "@iconify/react";
import DevClass from "../../comps/DevClass";
import StructureService from "@/services/dd/StructureService";
//
function CreateTableDetail() {
  const navigate = useNavigate();

  const routeParams = useParams();
  console.log("routeParams = ", JSON.stringify(routeParams));
  const BreadcrumbPart = () => (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/tiles/develop");
            }}
          >
            Developments
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/ddic");
            }}
          >
            Data Dictionary
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Create Table</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );

  let InitialTable: Table = {
    TabName: routeParams.tabname ? routeParams.tabname : "",
    Status: "",
    Definition: "",
    LastChanged: "",
    LastChangedOn: "",
    Package: "ORC_HR",
    Cached: true,
    TxtTabName:"",
    Columns: [],
  };

  for (let index = 0; index < 10; index++) {
    InitialTable.Columns.push({
      TabName: "",
      FieldName: "",
      Rollname: "",
      Domname: "",
      Datatype: "",
      Definition: "",
      Primary: false,
      NotNull: false,
      Encrypted: false,
      Length: 0,
    });
  }

  const [PageObject, SetPage] = useState(InitialTable);
  const [loaded, SetLoaded] = useState(false);

  const PropChanged = (fname: string, val: any) => {
    if (fname === "TabName") {
      let newobj: Table = { ...PageObject, TabName: val };
      SetPage(newobj);
    } else if (fname === "Definition") {
      let newobj: Table = { ...PageObject, Definition: val };
      SetPage(newobj);
    } else if (fname === "Package") {
      let newobj: Table = { ...PageObject, Package: val };
      SetPage(newobj);
    } else if (fname === "TxtTabName") {
      let newobj: Table = { ...PageObject, TxtTabName: val };
      SetPage(newobj);
    } else if (fname === "Cached") {
      let newobj: Table = { ...PageObject, Cached: val };
      SetPage(newobj);
    }
  };

  const FieldListChanged = (fname: string, ind: number, val: any) => {
    if (fname === "RollName") {
      let newobj: Table = { ...PageObject };
      newobj.Columns[ind].Rollname = val;
      SetPage(newobj);
    } else if (fname === "FieldName") {
      let newobj: Table = { ...PageObject };
      newobj.Columns[ind].FieldName = val;
      SetPage(newobj);
    } else if (fname === "Primary") {
      let newobj: Table = { ...PageObject };
      newobj.Columns[ind].Primary = val;
      SetPage(newobj);
    } else if (fname === "NotNull") {
      let newobj: Table = { ...PageObject };
      newobj.Columns[ind].NotNull = val;
      SetPage(newobj);
    } else if (fname === "Encrypted") {
      let newobj: Table = { ...PageObject };
      newobj.Columns[ind].Encrypted = val;
      SetPage(newobj);
    }
  };

  // const FixValueChanged = (item: FixValue, value: string) => {
  //   let newobj: Domain = { ...PageObject };

  //   var index: number = newobj.FixValues.indexOf(item);
  //   newobj.FixValues[index].Value = value;

  //   SetPage(newobj);
  // };
  // const FixValueTextChanged = (item: FixValue, value: string) => {
  //   let newobj: Domain = { ...PageObject };

  //   var index: number = newobj.FixValues.indexOf(item);
  //   newobj.FixValues[index].Text = value;

  //   SetPage(newobj);
  // };

  const OnRollNameSelect = async (x: string, ind: number) => {
    let newobj: Table = { ...PageObject };
    newobj.Columns[ind].Rollname = x;

    var delData = await DataElementService.getByName(x);
    if (delData) {
      newobj.Columns[ind].Domname = delData.DomName;
      newobj.Columns[ind].Datatype = delData.Datatype;
      newobj.Columns[ind].Definition = delData.Definition;
      newobj.Columns[ind].Length = delData.Length;
    }

    SetPage(newobj);
  };

  const OnRollNameBlur = async (x: string, ind: number) => {
    let newobj: Table = { ...PageObject };
    newobj.Columns[ind].Rollname = x;
    if (x === "") {
      newobj.Columns[ind].Domname = "";
      newobj.Columns[ind].Datatype = "";
      newobj.Columns[ind].Definition = "";
      newobj.Columns[ind].Length = 0;
      SetPage(newobj);
      return;
    }
    var delData = await DataElementService.getByName(x);
    if (delData) {
      newobj.Columns[ind].Domname = delData.DomName;
      newobj.Columns[ind].Datatype = delData.Datatype;
      newobj.Columns[ind].Definition = delData.Definition;
      newobj.Columns[ind].Length = delData.Length;
    } else {
      var struDataExist = await StructureService.isExist(x);
      if (struDataExist) {
        var struData = await StructureService.getByName(x);
        if (struData) {
          newobj.Columns[ind].Domname = "";
          newobj.Columns[ind].Datatype = "STRU";
          newobj.Columns[ind].Definition = struData.Definition;
          newobj.Columns[ind].Length = 0;
        }
      }
      else{
        newobj.Columns[ind].Domname = "";
        newobj.Columns[ind].Datatype = "";
        newobj.Columns[ind].Definition = "";
        newobj.Columns[ind].Length = 0;
      }
    }

    SetPage(newobj);
  };

  /*
    Saves
  */
  const Save = async () => {
    debugger;
    SetLoaded(false);
    let newSaveobj: Table = { ...PageObject, Columns: [] };

    PageObject.Columns.forEach((col) => {
      if (col.FieldName) {
        newSaveobj.Columns.push({
          TabName: col.TabName,
          FieldName: col.FieldName,
          Rollname: col.Rollname,
          Domname: col.Domname,
          Datatype: col.Datatype,
          Definition: col.Definition,
          Primary: col.Primary,
          NotNull: col.NotNull,
          Encrypted: col.Encrypted, 
          Length: col.Length,
        });
      }
    });

    const resp = await TableService.post(newSaveobj);
    if (resp.data) {
      //navigate("/apps/ddic");
      let newSaveobj1: Table = { ...newSaveobj, Status: "P" };
      SetPage(newSaveobj1);
      toast({
        title: "Successfull:)",
        description: "Table activated and created in database.",
      });
    } else {
      toast({
        title: "Error in Service",
        description: resp.usermessage,
      });
    }

    SetLoaded(true);
  };

  /*
    Saves and activates
  */
  const Activate = async () => {
    debugger;
    SetLoaded(false);
    let newSaveobj: Table = { ...PageObject, Columns: [] };

    PageObject.Columns.forEach((col) => {
      if (col.FieldName) {
        newSaveobj.Columns.push({
          TabName: col.TabName,
          FieldName: col.FieldName,
          Rollname: col.Rollname,
          Domname: col.Domname,
          Datatype: col.Datatype,
          Definition: col.Definition,
          Primary: col.Primary,
          NotNull: col.NotNull,
          Encrypted: col.Encrypted,
          Length: col.Length,
        });
      }
    });
    const resp = await TableService.activate(newSaveobj);
    debugger;
    if (resp.data) {
      //navigate("/apps/ddic");
      let newSaveobj: Table = { ...PageObject, Status: "A" };
      SetPage(newSaveobj);
      toast({
        title: "Successfull:)",
        description: "Table activated and created in database.",
      });
    } else {
      toast({
        title: "Error in Service",
        description: resp.usermessage,
      });
    }
    SetLoaded(true);
  };

  return (
    <div className="p-4 bg-white h-screen">
      <h1 className="font-bold text-2xl text-red-800">Create Table</h1>
      <div className="mb-4">
        <BreadcrumbPart />
      </div>
      <Separator className="my-4" />
      <div className="flex flex-row flex-nowrap my-2 gap-1">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          // variant={"outline"}
          onClick={() => Save()}
          title="Save program"
        >
          <Icon
            icon="carbon:save"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Activate()}
          title="Activate program"
        >
          <Icon
            icon="line-md:speedometer-loop"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
      </div>

      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row items-center">
          <Label className="w-48">Table Name</Label>
          <Input
            className="bg-gray-100 w-40 uppercase"
            placeholder="?"
            value={PageObject.TabName}
            maxLength={50}
            disabled={true}
            onChange={(e) => PropChanged("TabName", e.target.value)}
          />
          <Input
            className="bg-gray-100 w-10 ms-1"
            value={PageObject.Status}
            maxLength={1}
          ></Input>
        </div>
        <div className="flex flex-row items-center">
          <Label className="w-48">Short Description</Label>
          <Input
            className="bg-white w-96"
            placeholder="?"
            value={PageObject.Definition}
            maxLength={60}
            onChange={(e) => PropChanged("Definition", e.target.value)}
          ></Input>
        </div>

        {/* Tab Start */}
        <Tabs defaultValue="properties" className="md:w-2/3 mt-5">
          <TabsList>
            <TabsTrigger value="properties">Properties</TabsTrigger>
            <TabsTrigger value="fields">Fields</TabsTrigger>
          </TabsList>
          {/* Tab 1 : Properties */}
          <TabsContent value="properties" className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed By</Label>
              <Input
                className="bg-gray-100 w-64 lowercase"
                placeholder=""
                disabled={true}
                value={PageObject.LastChanged}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed On</Label>
              <Input
                className="bg-gray-100 w-24"
                placeholder=""
                disabled={true}
                value={PageObject.LastChangedOn}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Development Class</Label>
              <DevClass Value={PageObject.Package} OnSelect={(e) => PropChanged("Package", e)}></DevClass>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Text Table</Label>
              <SearchHelp
                SHName="TABNAME"
                disabled={false}
                onChange={(e) =>
                  PropChanged("TxtTabName", e.target.value)
                }
                value={PageObject.TxtTabName}
                InputClassName="uppercase"
                OnSelect={(x) => PropChanged("TxtTabName", x)}
                onBlur={(e) => PropChanged("TxtTabName", e.target.value)}
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Caching</Label>
              <Checkbox
                    checked={PageObject.Cached}
                    disabled={false}
                    onCheckedChange={(checked) =>
                      PropChanged("Cached", checked)
                    }
                  />
            </div>
          </TabsContent>
          {/* Tab 2 : Fields */}
          <TabsContent value="fields" className="flex flex-col gap-2">
            <table>
              <thead>
                <tr className="font-unbold text-left">
                  <th>Field Name</th>
                  <th>K</th>
                  <th>I</th>
                  <th>E</th>
                  <th>Data Element</th>
                  <th>Domain</th>
                  <th>Data type</th>
                  <th>Length</th>
                  <th>Definition</th>
                </tr>
              </thead>
              <tbody>
                {PageObject.Columns.map((col, index) => (
                  <tr>
                    <td>
                      <Input
                        className="bg-white w-32 uppercase"
                        placeholder=""
                        value={col.FieldName}
                        onChange={(e) =>
                          FieldListChanged("FieldName", index, e.target.value)
                        }
                      ></Input>
                    </td>
                    <td className="w-12">
                      <Checkbox
                        checked={col.Primary}
                        onCheckedChange={(checked) =>
                          FieldListChanged("Primary", index, checked)
                        }
                      />
                    </td>
                    <td className="w-12">
                      <Checkbox
                        checked={col.NotNull}
                        onCheckedChange={(checked) =>
                          FieldListChanged("NotNull", index, checked)
                        }
                      />
                    </td>
                    <td className="w-12">
                      <Checkbox
                        checked={col.Encrypted}
                        onCheckedChange={(checked) =>
                          FieldListChanged("Encrypted", index, checked)
                        }
                      />
                    </td>
                    <td>
                      <SearchHelp
                        SHName="ROLLNAME"
                        onChange={(e) =>
                          FieldListChanged("RollName", index, e.target.value)
                        }
                        value={col.Rollname}
                        OnSelect={(x) => OnRollNameSelect(x, index)}
                        InputClassName="uppercase"
                        onBlur={(e) => OnRollNameBlur(e.target.value, index)}
                      />
                    </td>
                    <td>
                      {/* Domain */}
                      <Input
                        className="bg-gray-100 w-20"
                        placeholder=""
                        value={col.Domname}
                        disabled={false}
                      ></Input>
                    </td>
                    <td>
                      {/* Data Type */}
                      <Input
                        className="bg-gray-100 w-20"
                        placeholder=""
                        disabled={false}
                        value={col.Datatype}
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-gray-100 w-16"
                        placeholder=""
                        disabled={false}
                        value={col.Length == 0 ? "" : col.Length}
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-gray-100 w-64"
                        placeholder=""
                        disabled={false}
                        value={col.Definition}
                      ></Input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabsContent>
        </Tabs>
        <Toaster />
      </div>
      {/* {JSON.stringify(PageObject)} */}
    </div>
  );
}

export default CreateTableDetail;
