import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Label } from "@/shadcn/ui/label";
import { RadioGroup, RadioGroupItem } from "@/shadcn/ui/radio-group";
import { Separator } from "@/shadcn/ui/separator";
import { Toaster } from "@/shadcn/ui/toaster";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "@/shadcn/ui/use-toast";
import { SearchHelp } from "../../comps/SearchHelp";
import DomainService from "@/services/dd/DomainService";
import DataElementService from "@/services/dd/DataElementService";
import TableService from "@/services/dd/TableService";
import StructureService from "@/services/dd/StructureService";
import { Icon } from "@iconify/react";
import LockService from "@/services/dd/LockService";
import { cn } from "@/lib/utils";
import { isNullOrUndefined } from "util";
import ViewService from "@/services/dd/ViewService";
import { OrcScreen } from "@/models/screen/OrcScreen";
import { ScreenEvent } from "@/models/Enums";
import ScreenService from "@/services/program/ScreenService";

//
enum DDicType {
  domain = "domain",
  dataelement = "dataelement",
  struname = "structure",
  tabname = "table",
  viewname = "view",
}

function DataDictionary() {
  //selected dict element
  const [dictype, setDicType] = useState(DDicType.domain);
  const [domname, setDomName] = useState("");
  const [rollname, setRollName] = useState("");
  const [struname, setStruName] = useState("");
  const [tabname, setTabName] = useState("");
  const [viewname, setViewName] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [pagefound, setPageFound] = useState(false);
  const [unAuthorized, setunAuthorized] = useState(false);
  const navigate = useNavigate();


  let initialscreen: OrcScreen = new OrcScreen();
  initialscreen.UIPath =  encodeURIComponent(window.location.pathname.toLowerCase());
  initialscreen.HRef = window.location.href;
  initialscreen.ProgName = "";
  initialscreen.ScreenEvent = ScreenEvent.Get;

  const GetHistory = () => {
    if(localStorage.getItem("DICTYPE"))
      {
        const type = localStorage.getItem("DICTYPE");
        switch(type)
        {
          case "domain":
            setDicType(DDicType.domain);
            const _domname = localStorage.getItem("DICNAME");
            if(_domname)
            {
              setDomName(_domname);
            }
            break;
          case "dataelement":
            setDicType(DDicType.dataelement);
            const _dataelement = localStorage.getItem("DICNAME");
            if(_dataelement)
            {
              setRollName(_dataelement);
            }
            break;
          case "structure":
            setDicType(DDicType.struname);
            const _struname = localStorage.getItem("DICNAME");
            if(_struname)
            {
              setStruName(_struname);
            }
            break;
          case "table":
            setDicType(DDicType.tabname);
            const _tabname = localStorage.getItem("DICNAME");
            if(_tabname)
            {
              setTabName(_tabname);
            }
            break;
          case "view":
            setDicType(DDicType.viewname);
            const _viewname = localStorage.getItem("DICNAME");
            if(_viewname)
            {
              setViewName(_viewname);
            }
            break;
        }
  
  
  
      }

  }
  
  useState(() => {
    //get history
    GetHistory();
    
    
  });


  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoaded(false);
    const resp = await ScreenService.post(initialscreen);
    if (resp.data) {
      console.log(resp.data);
      setunAuthorized(resp.data.UnAuthorized);
    } else {
      setLoaded(true);
      setPageFound(false);
    }
    debugger;
  };



  const Display = async () => {
    if (dictype === DDicType.domain) {
      if (domname.length > 0) {
        var domExist = await DomainService.isExist(domname);
        if (domExist) {
          localStorage.setItem("DICNAME", domname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/domain/display/" + domname);
        } else {
          toast({
            title: "Domain does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select domain",
        });
      }
    } else if (dictype === DDicType.dataelement) {
      if (rollname.length > 0) {
        var isExist = await DataElementService.isExist(rollname);
        if (isExist) {
          localStorage.setItem("DICNAME", rollname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/dataelement/display/" + rollname);
        } else {
          toast({
            title: "Data element does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select data-element",
        });
      }
    } else if (dictype === DDicType.tabname) {
      if (tabname.length > 0) {
        var isExist = await TableService.isExist(tabname);
        if (isExist) {
          localStorage.setItem("DICNAME", tabname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/table/display/" + tabname);
        } else {
          toast({
            title: "Table does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select table",
        });
      }
    } else if (dictype === DDicType.struname) {
      if (struname.length > 0) {
        var isExist = await StructureService.isExist(struname);
        if (isExist) {
          localStorage.setItem("DICNAME", struname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/structure/display/" + struname);
        } else {
          toast({
            title: "Structure does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select structure",
        });
      }
    } else if (dictype === DDicType.viewname) {
      if (viewname.length > 0) {
        var isExist = await ViewService.isExist(viewname);
        if (isExist) {
          localStorage.setItem("DICNAME", viewname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/view/display/" + viewname);
        } else {
          toast({
            title: "View does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select view",
        });
      }
    }
  };

  const Change = async () => {
    if (dictype === DDicType.domain) {
      if (domname.length > 0) {
        var domExist = await DomainService.isExist(domname);
        if (domExist) {
          localStorage.setItem("DICNAME", domname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/domain/change/" + domname);
        } else {
          toast({
            title: "Domain does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select domain",
        });
      }
    } else if (dictype === DDicType.dataelement) {
      if (rollname.length > 0) {
        var DEExist = await DataElementService.isExist(rollname);
        if (DEExist) {
          localStorage.setItem("DICNAME", rollname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/dataelement/change/" + rollname);
        } else {
          toast({
            title: "Data element does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select data-element",
        });
      }
    } else if (dictype === DDicType.tabname) {
      if (tabname.length > 0) {
        var TABExist = await TableService.isExist(tabname);
        if (TABExist) {
          localStorage.setItem("DICNAME", tabname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/table/change/" + tabname);
        } else {
          toast({
            title: "Table does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select table",
        });
      }
    } else if (dictype === DDicType.struname) {
      if (struname.length > 0) {
        var TABExist = await StructureService.isExist(struname);
        if (TABExist) {
          localStorage.setItem("DICNAME", struname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/structure/change/" + struname);
        } else {
          toast({
            title: "Structure does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select structure",
        });
      }
    } else if (dictype === DDicType.viewname) {
      if (viewname.length > 0) {
        var TABExist = await ViewService.isExist(viewname);
        if (TABExist) {
          localStorage.setItem("DICNAME", viewname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/view/change/" + viewname);
        } else {
          toast({
            title: "View does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select view",
        });
      }
    }
  };

  const Create = async () => {
    if (dictype === DDicType.domain) {
      //
      if (domname.length > 0) {
        if (domname.includes(" ")) {
          toast({
            title: "Check Domain Name",
            description: "Dictionary element names should have no blank.",
          });
        } else {
          var domExist = await DomainService.isExist(domname);
          if (domExist === false) {
            // var isObjLocked  = await LockService.IsLocked({ LockType : "DOMN", LockKey : domname});
            // if(isObjLocked == false) {
            //   LockService.post({ LockType : "DOMN", LockKey : domname})
            localStorage.setItem("DICNAME", domname);
            localStorage.setItem("DICTYPE", dictype);
              navigate("/apps/domain/create/" + domname);
            // }
            // else
            // {
            //   toast({
            //     title: "Object has been locked.",
            //     description:
            //       "Object has been locked by another user.",
            //   });
            // }

          } else {
            toast({
              title: "Domain already exist.",
              description:
                "Domain already created, check your name and try again.",
            });
          }
        }
      } else {
        toast({
          title: "Enter domain name",
          description: "Please enter domain name to be created.",
        });
      }
      //
    } else if (dictype === DDicType.dataelement) {
      //
      if (rollname.length > 0) {
        if (rollname.includes(" ")) {
          toast({
            title: "Check Data Element Name",
            description: "Dictionary element names should have no blank.",
          });
        } else {
          var isExist = await DataElementService.isExist(rollname);
          if (isExist === false) {
            localStorage.setItem("DICNAME", rollname);
            localStorage.setItem("DICTYPE", dictype);
            navigate("/apps/dataelement/create/" + rollname);
          } else {
            toast({
              title: "Data element already exist.",
              description:
                "Data element already created, check your name and try again.",
            });
          }
        }
      } else {
        toast({
          title: "Enter data element name",
          description: "Please enter data element name to be created.",
        });
      }
      //
    } else if (dictype === DDicType.tabname) {
      //
      if (tabname.length > 0) {
        if (tabname.includes(" ")) {
          toast({
            title: "Check Table Name",
            description: "Dictionary element names should have no blank.",
          });
        } else {
          var isExist = await TableService.isExist(tabname);
          if (isExist === false) {
            localStorage.setItem("DICNAME", tabname);
            localStorage.setItem("DICTYPE", dictype);
            navigate("/apps/table/create/" + tabname);
          } else {
            toast({
              title: "Table already exist.",
              description:
                "Table already created, check your name and try again.",
            });
          }
        }
      } else {
        toast({
          title: "Enter table name",
          description: "Please enter table name to be created.",
        });
      }
      //
    } else if (dictype === DDicType.struname) {
      //
      if (struname.length > 0) {
        if (struname.includes(" ")) {
          toast({
            title: "Check Structure Name",
            description: "Structure names should have no blank.",
          });
        } else {
          var isExist = await StructureService.isExist(struname);
          if (isExist === false) {
            localStorage.setItem("DICNAME", struname);
            localStorage.setItem("DICTYPE", dictype);
            navigate("/apps/structure/create/" + struname);
          } else {
            toast({
              title: "Structure already exist.",
              description:
                "Structure already created, check your name and try again.",
            });
          }
        }
      } else {
        toast({
          title: "Enter structure name",
          description: "Please enter structure name to be created.",
        });
      }
      //
    } else if (dictype === DDicType.viewname) {
      //
      if (viewname.length > 0) {
        if (viewname.includes(" ")) {
          toast({
            title: "Check View Name",
            description: "View names should have no blank.",
          });
        } else {
          var isExist = await ViewService.isExist(viewname);
          if (isExist === false) {
            localStorage.setItem("DICNAME", viewname);
            localStorage.setItem("DICTYPE", dictype);
            navigate("/apps/view/create/" + viewname);
          } else {
            toast({
              title: "View already exist.",
              description:
                "View already created, check your name and try again.",
            });
          }
        }
      } else {
        toast({
          title: "Enter view name",
          description: "Please enter view name to be created.",
        });
      }
      //
    }
  };

  const Delete = async () => {
    if (dictype === DDicType.domain) {
      if (domname.length > 0) {
        var domExist = await DomainService.isExist(domname);
        if (domExist) {
          navigate("/apps/domain/delete/" + domname);
        } else {
          toast({
            title: "Domain does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select domain",
        });
      }
    } else if (dictype === DDicType.dataelement) {
      if (rollname.length > 0) {
        var isExist = await DataElementService.isExist(rollname);
        if (isExist) {
          navigate("/apps/dataelement/delete/" + rollname);
        } else {
          toast({
            title: "Data element does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select data-element",
        });
      }
    } else if (dictype === DDicType.tabname) {
      if (tabname.length > 0) {
        var isExist = await TableService.isExist(tabname);
        if (isExist) {
          navigate("/apps/table/delete/" + tabname);
        } else {
          toast({
            title: "Table does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select table",
        });
      }
    } else if (dictype === DDicType.struname) {
      if (struname.length > 0) {
        var isExist = await StructureService.isExist(struname);
        if (isExist) {
          navigate("/apps/structure/delete/" + struname);
        } else {
          toast({
            title: "Structure does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select structure",
        });
      }
    } else if (dictype === DDicType.viewname) {
      if (viewname.length > 0) {
        var isExist = await ViewService.isExist(viewname);
        if (isExist) {
          navigate("/apps/view/delete/" + viewname);
        } else {
          toast({
            title: "View does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select view",
        });
      }
    }
  };

  const OnDomainSelect = (x: string) => {
    setDomName(x);
  };
  const OnRollNameSelect = (x: string) => {
    setRollName(x);
  };
  const OnStructureSelect = (x: string) => {
    setStruName(x);
  };
  const OnViewNameSelect = (x: string) => {
    setViewName(x);
  };

  const OnTabNameSelect = (x: string) => {
    setTabName(x);
  };

  
  const UnAuthorizedView = (
    <div className="min-h-[500px] flex flex-col items-center justify-center text-4xl text-primary">
      <div className="font-bold text-6xl mb-4">401</div>
      <div>
        Page{" "}
        <span className="text-red-600">
          {decodeURIComponent(initialscreen.ProgName.toLowerCase())}
        </span>{" "}
        Not authorized
      </div>
    </div>
  );

  return (unAuthorized === false ? (  
    <div className="bg-white w-full h-screen p-4">
      <h1 className="font-bold text-2xl">Data Dictionary</h1>
      <div className="mb-4">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink
                className="hover:cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/apps/exec/basis/develop");
                }}
              >
                Developments
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Data Dictionary</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <Separator className="my-4" />
      {/* Commands Start */}
      <div className="flex flex-row flex-nowrap my-2 gap-1 mb-10">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Create()}
          title="Create"
        >
          <Icon
            icon="akar-icons:paper"
            width="18"
            height="18"
            className="text-black bg-white"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Change()}
          title="Change"
        >
          <Icon
            icon="solar:pen-outline"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Display()}
          title="Display"
        >
          <Icon
            icon="tabler:zoom"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Delete()}
          title="Delete"
        >
          <Icon
            icon="material-symbols-light:delete-outline"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
      </div>
      {/* Commands End */}
      <div>
        <RadioGroup defaultValue={dictype}>
          <div className="flex items-center">
            <div className="w-28">
              <RadioGroupItem
                value="domain"
                onClick={() => setDicType(DDicType.domain)}
              />
              <Label className="ms-2">Domain</Label>
            </div>
            <SearchHelp
              SHName="DOMNAME"
              className="ms-20"
              onChange={(e) => setDomName(e.target.value.toUpperCase())}
              value={domname}
              InputClassName="uppercase"
              OnSelect={(x) => OnDomainSelect(x.toUpperCase())}
            />
          </div>
          <div className="flex items-center">
            <div className="w-28">
              <RadioGroupItem
                value="dataelement"
                onClick={() => setDicType(DDicType.dataelement)}
              />
              <Label className="ms-2">Data Element</Label>
            </div>
            <SearchHelp
              SHName="ROLLNAME"
              className="ms-20 uppercase"
              onChange={(e) => setRollName(e.target.value.toUpperCase())}
              value={rollname}
              InputClassName="uppercase"
              OnSelect={(x) => OnRollNameSelect(x.toUpperCase())}
            />
          </div>
          <div className="flex items-center">
            <div className="w-28">
              <RadioGroupItem
                value="structure"
                onClick={() => setDicType(DDicType.struname)}
              />
              <Label className="ms-2">Structure</Label>
            </div>
            <SearchHelp
              SHName="STRUNAME"
              className="ms-20"
              onChange={(e) => setStruName(e.target.value.toUpperCase())}
              value={struname}
              InputClassName="uppercase"
              OnSelect={(x) => OnStructureSelect(x.toUpperCase())}
            />
          </div>
          <div className="flex items-center">
            <div className="w-28">
              <RadioGroupItem
                value="table"
                onClick={() => setDicType(DDicType.tabname)}
              />
              <Label className="ms-2">Table</Label>
            </div>
            <SearchHelp
              SHName="TABNAME"
              className="ms-20"
              onChange={(e) => setTabName(e.target.value.toUpperCase())}
              value={tabname}
              InputClassName="uppercase"
              OnSelect={(x) => OnTabNameSelect(x.toUpperCase())}
            />
          </div>
          <div className="flex items-center">
            <div className="w-28">
              <RadioGroupItem
                value="view"
                onClick={() => setDicType(DDicType.viewname)}
              />
              <Label className="ms-2">View</Label>
            </div>
            <SearchHelp
              SHName="VIEWNAME"
              className="ms-20"
              onChange={(e) => setViewName(e.target.value.toUpperCase())}
              value={viewname}
              InputClassName="uppercase"
              OnSelect={(x) => OnViewNameSelect(x.toUpperCase())}
            />
          </div>
        </RadioGroup>
      </div>

      <Toaster />
    </div>) : UnAuthorizedView
  )
}

export default DataDictionary;
