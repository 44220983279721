import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Icon } from "@iconify/react";
import { DataTypes } from "@/models/Enums";
import { OrcViewColumn } from "@/models/OrcViewColumn";
import { Input } from "@/shadcn/ui/input";
import { Switch } from "@/shadcn/ui/switch";
import { toast } from "@/shadcn/ui/use-toast";
import { Toaster } from "@/shadcn/ui/toaster";
import { OrcViewResult } from "@/models/OrcViewResult";
import { EditType, View } from "@/models/datadictionary/View";
import ViewDataService from "@/services/ui/ViewDataService";
import { Checkbox } from "@/shadcn/ui/checkbox";
import OrcDatePicker from "./OrcDatePicker";
import OrcCheckBox from "./OrcCheckBox";
import moment from 'moment';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/shadcn/ui/dialog";
import { Label } from "@/shadcn/ui/label";
import { Button } from "@/shadcn/ui/button";
import OrcText from "./OrcText";
import OrcTextBox from "./OrcTextBox";
import OrcModal, { OrcFormData } from "./OrcModal";
import OrcSelect from "./OrcSelect";
import { Constants } from "@/models/Consts";
import OrcTimePicker from "./OrcTimePicker";

export interface IOrcView {
  FieldName: string;
  ClassName?: string;
  ParentFieldName: string;
  ViewName : string;
  ViewModel? : OrcViewResult;
  Data? : any;
  TableSaveClicked?: (data: any) => void;
  ButtonClicked?: (buttonName: string, tableData: any, rowData: any) => void;
  // ShowMessage?: (message: string) => void;
  // SetDisplayMode?: () => void;
}

enum ViewStatus {
  DisplayMode = 0,
  EditMode = 1,
  CreateMode = 2,
}

enum RowStatus {
  UnChanged = 1, //server dan gelmiş ve hiç değiştirilmemiş
  Changed = 2, //server dan gelmiş ve burda değiştirilmiş
  Added = 3, //burda yeni eklenmiş
  Deleted = 4, //burda silinmiş
  Empty = 5, //boş ama hiç yazılmamış
}

const OrcView: React.FC<PropsWithChildren<IOrcView>> = (props) => {
  let inactiveColor = "bg-gray-50";
  let activeColor = "bg-white";
  let rowbuttonsize: number = 18;

  const [saveNeeded, setSaveNeeded] = useState(false);

  //undo operation needs last fetch data, saveddata = last fetched data from server
  let _savedData: any[] = [];
  const [saveddata, setSavedData] = useState(_savedData);
  const [tablemode, setTableMode] = useState<ViewStatus>(ViewStatus.DisplayMode);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  //const [openModal, setOpenModal] = useState(false);
  // let _modalData: any = {};
  // const [modalData, setModalData] = useState(_modalData);
  //const modalData = useRef(_modalData);
  //
  let InitialView : OrcViewResult = new OrcViewResult();

  // InitialView.Enable_Add = true;
  // InitialView.Enable_Mod = false;
  // InitialView.Enable_Det = false;
  // InitialView.Enable_Del = true;
  // InitialView.Enable_Exc = false;
  // InitialView.Enable_Copy = false;
  // InitialView.Columns = [
  //   {
  //     ColumnName: "id",
  //     Caption:"ID",
  //     DataType: DataTypes.INT4,
  //     Primary: true,
  //     RollName: "",
  //     Visible: true,
  //     Translate: false,
  //     DomName:""
  //   },
  //   {
  //     ColumnName: "VORNA",
  //     Caption:"Adı",
  //     DataType: DataTypes.CHAR,
  //     Primary: true,
  //     RollName: "",
  //     Visible: true,
  //     Translate: false,
  //     DomName:""
  //   }

  // ];

  const [ViewObject, setData] = useState<OrcViewResult>(InitialView);

  useEffect(() => {
    debugger;
    if(props.ViewModel == null)
    {
      setLoaded(false);
      fetch();
      setLoaded(true);
    }
    else
    {
      setLoaded(false);
      const viewObjectModel : OrcViewResult = props.ViewModel;
      console.log("ViewObjectModel", viewObjectModel);
      if(viewObjectModel.Data)
      {
        viewObjectModel.Data.forEach((obj: any) => {
          obj.__RowStatus = RowStatus.UnChanged;
          obj.__RowError = false;
          obj.__RowMessage = "";  
          // var clone = Object.assign({}, obj);
          // _savedData.push(clone);
        });
      }
      //save view obect
      setData(viewObjectModel);
      //duplicate data in saved obect for undo operations
      if (viewObjectModel.Data) {
        viewObjectModel.Data.forEach((obj: any) => {
        var clone = Object.assign({}, obj);
        _savedData.push(clone);
        });
      }
      setLoaded(true);
    }

   // setTimeout(() => fetch(), 0);

  }, []);

  const fetch = async () => {
    const resp = await ViewDataService.get(props.ViewName);

    if (resp.data) {
      setData(resp.data);
      debugger;
      // let savedObjArray: any[] = { ...resp.data.Data };
      //
      if(props.Data)
      {
        var newVObjectWithData = { ...resp.data };
        newVObjectWithData.Data = props.Data;
        setData(newVObjectWithData);
        props.Data.forEach((obj: any) => {
          var clone = Object.assign({}, obj);
          _savedData.push(clone);
        });
      }
      else
      {
        if (resp.data.Data) {
            resp.data.Data.forEach((obj: any) => {
            var clone = Object.assign({}, obj);
            _savedData.push(clone);
          });
        }
      }

      setSavedData(_savedData);
      setSaveNeeded(false);
      setLoaded(true);
    } else {
      setLoaded(true);
      // setPageFound(false);
    }
    debugger;
  };
  

  /*
    Değişiklik düğmesine basılırsa Edit ve Display arasında tablo değişir.
  */
  const ChangeMode = () => {
    if (tablemode === ViewStatus.EditMode)
      setTableMode(ViewStatus.DisplayMode);
    else {
      setTableMode(ViewStatus.EditMode);
    }

    // alert(tablemode.toString());
  };

  //Yeni satır ekleme düğmesine basılırsa tablo değişir.
  const CreateMode = () => {
    // setLoaded(false);
    // alert(tablemode.toString());
    // return;
    if(ViewObject.EditType == EditType.Inline)
    {
      if (tablemode === ViewStatus.CreateMode) {
        //zaten ekleme modunda ise o zaman görüntülee moduna geç
        setTableMode(ViewStatus.DisplayMode);
        //son halini al
        let tmpArray: any[] = [];
        saveddata!.forEach((obj) => {
          var clone = Object.assign({}, obj);
          tmpArray.push(clone);
        });
        //son halini state e yükle
        let newobj: OrcViewResult = { ...ViewObject };
        newobj.Data = tmpArray;
        setData(newobj);
      } else {
        //yeni satır eklemek için tablo modu değişir
        setTableMode(ViewStatus.CreateMode);
        let newobj: OrcViewResult = { ...ViewObject };
        newobj.Data = [];
        //tabloya boş satırlar eklenir, değiştikçe added olarak işaretlenir
        for (let i = 0; i < 10; i++) {
          let _newrow: any = {};
          newobj.Columns.forEach((col) => {
            _newrow[col.ColumnName] = "";
            _newrow.__RowStatus = RowStatus.Empty;
          });
          newobj.Data.push(_newrow);
        }
        debugger;
      
        // newobj.Data = [];
        // for
        // newobj.TableName="sdsd";
        setData(newobj);
        // setLoaded(true);
        // let newobj: OrcTableResult = { ...ViewObject };
        // newobj.Data = _emptyAddableRow;
        // setViewData(_emptyAddableRow);
        // var millisecondsToWait = 2000;
        // setTimeout(function () {
        //   setData(newobj);
        // }, millisecondsToWait);
        
        // var len = ViewObject.Columns.length;
        // var len2 = ViewData.length;
      }
    }
    else  if(ViewObject.EditType == EditType.Modal)
    {
      //_modalData = [];
      //setModalData([]);
      //Modal gösterme işlemi yapılacak
      setOpenModal(true);

    }
    else  if(ViewObject.EditType == EditType.Page)
    {
       // alert("Page modda yarat düğmesine basıldı");
        props.ButtonClicked ? props.ButtonClicked("Create", ViewObject.Data, null) : console.log("ButtonClicked is not defined");
    }
    else {

    }

    // alert(tablemode.toString());
  };

  //UnDo düğmesine basılırsa tablo en son kaydedilen hale geri döner
  const UnDo = () => {
    debugger;
    //Array Copy
    let tmpArray: any[] = [];
    saveddata!.forEach((obj) => {
      var clone = Object.assign({}, obj);
      tmpArray.push(clone);
    });

    let newobj: OrcViewResult = { ...ViewObject, Data: tmpArray};
    //newobj.Data = tmpArray;

    setData(newobj);
    setTableMode(ViewStatus.DisplayMode);
    setSaveNeeded(false);
    toast({
      title: "Değişiklikler Geri Alındı",
    });
  };

  const ShowCommandColumn = () => {
    let _show = false;

    if (ViewObject.Enable_Det || ViewObject.Enable_Del || ViewObject.Enable_Exc || ViewObject.Enable_Copy || ViewObject.Enable_Mod)
      _show = true;

    return _show;
  }

  /*
    Hücrelerde veri değiştikçe array güncellenir.
  */
  const ChangeData = (
    index: number,
    newValue: any,
    rowData: any,
    colData: OrcViewColumn
  ) => {
    if (
      tablemode === ViewStatus.EditMode ||
      tablemode === ViewStatus.CreateMode
    ) {
      console.log("newValue = ", newValue);
      if (ViewObject.Data) {
       let newobj: OrcViewResult = { ...ViewObject };
       newobj.Data.map((item:any, i : number) => {
          if (index === i) {
            //değişen index bulundu
            //yeni satır durumu ne olarak ona karar ver
            debugger;
            let newRowStatus: RowStatus = RowStatus.Changed;
            if (item.__RowStatus == RowStatus.UnChanged)
              newRowStatus = RowStatus.Changed;
            //değişmemiş bir satır ise değişmiş olur
            else if (item.__RowStatus == RowStatus.Empty)
              newRowStatus = RowStatus.Added;
            //yeni eklenen satır ise eklendi olarak değişir, empty den ayırt etmek lazım çünkü emty ler şemaya gitmez
            else if (item.__RowStatus == RowStatus.Added)
              newRowStatus = RowStatus.Added; //yeni eklenmiş ise durumu aynı kalır

            setSaveNeeded(true);

            item[colData.ColumnName] = newValue;
            item.__RowStatus = newRowStatus
            // return {
            //   ...item,
            //   [colData.ColumnName]: newValue,
            //   __RowStatus: (item.__RowStatus = newRowStatus),
            // };
          } else {
            return item;
          }
        });
       //setData(newArray);
        setData(newobj);
      }
    }
  };

  const RenderValueInDisplayMode = (data: any, col: OrcViewColumn) => {
    if(col.DataType == "DATS")
    {
      const formattedDate = moment(data[col.ColumnName], 'YYYYMMDD').format('DD.MM.YYYY');
      return formattedDate;
    }
    else
    {
      return data[col.ColumnName].toString();
    }


  }


  //Tablo değişiklik ya da görünüm modunda burada alanlar ekrana eklenir.
  const RenderCell2 = (index: number, data: any, col: OrcViewColumn) => {
    //console.log("RenderCell");

    let disabled: boolean = true;
    if (tablemode == ViewStatus.DisplayMode) {
      if (col.DataType == "BOOL") {
        return (
          // <span className="p-1">
            data[col.ColumnName] ?  <Icon icon="icon-park:check" width={16} height={16} /> : <span><span/>
            {/* {data[col.ColumnName] ?  "x" : ""} */}
          // </span>
        );

        //simple-line-icons:check
      }
      else
      {
        //Display mod ise sadece span döner
        return (
          <span className="p-3">
            {data[col.ColumnName] != null ? RenderValueInDisplayMode(data, col) : ""}
          </span>
        );
      }

    } else if (tablemode == ViewStatus.CreateMode) {
      //Yarat modunda ise hepsi span ama yeni eklenen satırlar tamamen açık gelir
      if (
        data.__RowStatus == RowStatus.Empty ||
        data.__RowStatus == RowStatus.Added
      ) {
        console.log("empty row");
        //debugger;
        disabled = false;
      } else {
        //Yarat modunda yeni olmayan satırlar
        return <span className="p-3">{data[col.ColumnName].toString()}</span>;
      }
    } else if (tablemode == ViewStatus.EditMode) {
      //Edit alanlar açık ama primary olan kapalı sadece
      disabled = false;
      if (col.Primary || col.Disabled) disabled = true;
    } else {
      console.log("Bu tablo modu kodlanmadı");
    }

    //console.log(index.toString());
    if (index == 2) console.log(JSON.stringify(data));
    debugger;
    if (col.FieldType=== "textbox") {
      return (
        <OrcTextBox
          FieldName={col.ColumnName}
          ParentFieldName="#"
          Length={col.MaxLen}
          DataType="CHAR"
          Required={col.Required}
          DomName={col.DomName ? col.DomName : ""}
          ClassName={clsx(
            "w-full p-2 text-sm",
            disabled ? inactiveColor : activeColor
          )}
          Data={data[col.ColumnName]}
          OnChange={(val) => ChangeData(index, val, data, col)}
          Disabled={col.Disabled}
        />
      );
    } 
    else if (col.FieldType=== "select") {
      return (
        <OrcSelect
        FieldName={col.ColumnName}
        ParentFieldName="#"
        Data={data[col.ColumnName]}
        Disabled={col.Disabled}
        // HasError={GetPropValue(field, "HasError")}
        // Required={GetPropValue(field, "Required")}
        Items={col.Items}
        OnSelect={(val) => ChangeData(index, val, data, col)}
      />
      );
    } 
    else if (col.FieldType == "checkbox") {
      return (
      <OrcCheckBox 
          FieldName={col.ColumnName} 
          Data={data[col.ColumnName]} 
          ParentFieldName="#" 
          Caption="Evli?" 
          Required={false} 
          Disabled={false} 
          HasError={false}
          CaptionClassName="text-white" 
          OnChecked={(value) => ChangeData(index, value, data,col)}    />
      );
    } else if (col.FieldType == "date") {
      return (
      <OrcDatePicker 
        FieldName={col.ColumnName} 
        Data={data[col.ColumnName]} 
        ParentFieldName="c"  
        OnChange={(value) => ChangeData(index, value, data,col)} />
      );
    
    }
    
    
    else return data[col.ColumnName];
  };

  const RenderCell = (index: number, data: any, col: OrcViewColumn) => {
    //console.log("RenderCell");

    debugger;
    let disabled: boolean = false;


    if (col.Primary || col.Disabled) 
      disabled = true;

    if (tablemode == ViewStatus.CreateMode) {
      //Yarat modunda ise hepsi span ama yeni eklenen satırlar tamamen açık gelir
      if (
        data.__RowStatus == RowStatus.Empty ||
        data.__RowStatus == RowStatus.Added
      ) {
        console.log("empty row");
        //debugger;
        disabled = false;
      }
    }
    else if (tablemode == ViewStatus.DisplayMode) {
      disabled = true;
    }


    if (col.FieldType=== "textbox") {
      return (
        <OrcTextBox
          FieldName={col.ColumnName}
          ParentFieldName="#"
          Length={col.MaxLen}
          DataType="CHAR"
          Required={col.Required}
          DomName={col.DomName ? col.DomName : ""}
          ClassName={clsx(
            "w-full p-2 text-sm",
          )}
          Data={data[col.ColumnName]}
          OnChange={(val) => ChangeData(index, val, data, col)}
          Disabled={disabled}
        />
      );
    } 
    else if (col.FieldType=== "select") {
      return (
        <OrcSelect
        FieldName={col.ColumnName}
        ParentFieldName="#"
        Data={data[col.ColumnName]}
        Disabled={disabled}
        // HasError={GetPropValue(field, "HasError")}
        // Required={GetPropValue(field, "Required")}
        Items={col.Items}
        OnSelect={(val) => ChangeData(index, val, data, col)}
      />
      );
    } 
    else if (col.FieldType == "checkbox") {
      return (
      <OrcCheckBox 
          FieldName={col.ColumnName} 
          Data={data[col.ColumnName]} 
          ParentFieldName="#" 
          Caption="Evli?" 
          Required={false} 
          Disabled={disabled} 
          HasError={false}
          CaptionClassName="text-white" 
          OnChecked={(value) => ChangeData(index, value, data,col)}    />
      );
    } else if (col.FieldType == "date") {
      return (
      <OrcDatePicker 
        FieldName={col.ColumnName} 
        Data={data[col.ColumnName]} 
        ParentFieldName="#" 
        Disabled={disabled} 
        ClassName="w-full"
        OnChange={(value) => ChangeData(index, value, data,col)} />
      );
    
    } else if (col.FieldType == "time") {
      return (
      <OrcTimePicker 
        FieldName={col.ColumnName} 
        Data={data[col.ColumnName]} 
        ParentFieldName="#" 
        Disabled={disabled} 
        ClassName="w-full"
        OnChange={(value) => ChangeData(index, value, data,col)} />
      );
    
    }
    else {
      return <span className="p-3">
      {data[col.ColumnName] != null ? RenderValueInDisplayMode(data, col) : ""}
    </span>
    }
  };

  const RowAction_Delete = (index: number, row: any) => {
    debugger;
    let tmpData = [...ViewObject.Data!];
    debugger;
    if(ViewObject.EditType == EditType.Inline)
    {
      if (
        row.__RowStatus == RowStatus.Empty ||
        row.__RowStatus == RowStatus.Added
      ) {
        //Empty ya da added ise direkt sil
        var index = tmpData.indexOf(row); // Let's say it's Bob.
        tmpData.splice(index, 1);
        //
        let newobj: OrcViewResult = { ...ViewObject };
        newobj.Data = tmpData;
        //
        setData(newobj);
      } else if (row.__RowStatus == RowStatus.UnChanged) {
        var index = tmpData.indexOf(row); // Let's say it's Bob.
        row.__RowStatus = RowStatus.Deleted;
        //
        let newobj: OrcViewResult = { ...ViewObject };
        newobj.Data = tmpData;
        //
        setData(newobj);
        setSaveNeeded(true);
      }
    }
    else if(ViewObject.EditType == EditType.Modal)
    {
        //burası yapılacak 08082024
    }
    else if(ViewObject.EditType == EditType.Page)
    {
      props.ButtonClicked ? props.ButtonClicked("Delete", ViewObject.Data, row) : console.log("ButtonClicked is not defined");
    }

    // setTableMode(ViewStatus.DisplayMode);
    // toast({
    //   title: "Değişiklikler Kaydedildi",
    //   description: "Değişiklikler sisteme kaydedilmiştir.",
    // });
  };

  //Modal ve Page Edit lerinde çağırılır.
  const RowAction_Edit = (index: number, row: any) => {
    debugger;
    console.log("RowAction_Edit with data = ", row);
    if(ViewObject.EditType == EditType.Modal)
    {
      setOpenModal(true);
    }
    else if(ViewObject.EditType == EditType.Page)
    {
      
      props.ButtonClicked ? props.ButtonClicked("Edit", ViewObject.Data, row) : console.log("ButtonClicked is not defined");
    }
    else if(ViewObject.EditType == EditType.Inline)
    {
      //Inline modda zaten değiştir menüden basılır, satırda bu düğmeye basılmaz
    }
   
    // let tmpData = [...data!];
    // debugger;
    // if (row.__RowStatus == RowStatus.UnChanged) {
    //   var index = tmpData.indexOf(row); // Let's say it's Bob.
    //   setTableMode(ViewStatus.EditMode);
    //   setData(tmpData);
    //   setSaveNeeded(true);
    // }
  };

  // const CreateFieldInModal = (field: OrcViewColumn) => {
  //   switch (field.DataType) {
  //     case DataTypes.CHAR:
  //       return <Input className="w-full" key={field.ColumnName} value={modalData[field.ColumnName]} 
  //         onChange={(e) => ModalFieldChanging("deneme", e.target.value)} /> 

  //       // return <OrcTextBox ClassName="w-full" FieldName={field.ColumnName} Data={modalData[field.ColumnName]} ParentFieldName="#" Caption={field.Caption} Required={field.Required} Disabled={false} HasError={false} 
  //       //   />
  //     // case DataTypes.DATS:
  //     //   return <OrcDatePicker ClassName="w-full" FieldName={field.ColumnName} Data={modalData[field.ColumnName]} ParentFieldName="#" Caption={field.Caption} Required={field.Required} Disabled={false} HasError={false} 
  //     //     OnChange={(value) => ModalFieldChanging(field.ColumnName, value)} />
  //   }

  // }

  // const AddNewModal = () => {
  //   //
  //   let testField : OrcViewColumn = new OrcViewColumn();
  //   testField.ColumnName = "VORNA";
  //   testField.Caption = "Adı";
  //   testField.DataType = DataTypes.CHAR;
  //   testField.Required = true;
  //   testField.Visible = true;
  //   //
  //   return <Dialog open={openModal} onOpenChange={(val) => setOpenModal(val)}>
  //   <DialogContent className="sm:max-w-md">
  //   <DialogHeader>
  //     <DialogTitle>Create New Record</DialogTitle>
  //     <DialogDescription>
  //       Do not forget to press save button after submitting new record.
  //       <br/>
  //       {/* { JSON.stringify(_modalData) } */}
  //       {/* { JSON.stringify(_modalData) } */}
  //     </DialogDescription>
  //   </DialogHeader>
  //   { CreateFieldInModal(testField) }
  //   <DialogFooter className="sm:justify-start">
  //     <DialogClose asChild>
  //       <Button type="button" variant="secondary">
  //         Submit Record
  //       </Button>
  //     </DialogClose>
  //   </DialogFooter>
  // </DialogContent>
  // </Dialog>

  // }

  const Save = async () => {
    debugger;
    console.log("Save Clicked");
    debugger;
    if (ViewObject.Data) {
      if(ViewObject.OpenSourceUpdate == false)
      {
        ViewObject.Data = ViewObject.Data.filter((o : any) => o.__RowStatus != RowStatus.Empty);
        const resp = await ViewDataService.post(ViewObject);
        if(resp.data)
        {
          fetch();
          setTableMode(ViewStatus.DisplayMode);
          toast({
            title: "Değişiklikler Kaydedildi",
            description: "Değişiklikler sisteme kaydedilmiştir.",
          });
          //
          // debugger;
          // if(resp.data.Data)
          // {
          //   resp.data.Data = resp.data.Data.filter((o : any) => o.__RowStatus != RowStatus.Empty && o.__RowStatus != RowStatus.Added);
          // }
  
          // setData(resp.data);
  
          
        }
        else
        {
          debugger;
          toast({
            title: resp.usermessage
            //description: "ne oldu bilinmez",
          });
        }
      }
      else
      {
        //OpenSourceUpdate true ise kayıt işlemi dışarıda yapılacak
        if(props.TableSaveClicked) {
          //props.TableSaveClicked(ViewObject.Data);
          props.TableSaveClicked(ViewObject.Data);
          setTableMode(ViewStatus.DisplayMode);
        }
        else
        {
          console.log("TableSaveClicked is not defined");
        }
        // props.TableSaveClicked ? props.TableSaveClicked(ViewObject.Data) : console.log("TableSaveClicked is not defined");
        // setTableMode(ViewStatus.DisplayMode);
      }


      //props.TableSaveClicked(data);
    }
    
    // const resp = await TableService.post(props.SourceName, tmpData);
    // setTableMode(ViewStatus.DisplayMode);
    // Refresh();
    // tmpData = tmpData.filter((o) => o.__RowStatus != RowStatus.Empty);
    // tmpData = tmpData.filter((o) => o.__RowStatus != RowStatus.Added);
    // setData(tmpData);

    // setTableMode(ViewStatus.DisplayMode);
    // toast({
    //   title: "Değişiklikler Kaydedildi",
    //   description: "Değişiklikler sisteme kaydedilmiştir.",
    // });
  };

  //Modal dan gelen yeni kayıt OrcView da buraya eklenir
  const MergeModalData = (modalAddData: OrcFormData[]) => {
    //
    let newobj: OrcViewResult = { ...ViewObject };
    //
    //
    let newVal : any = {};
    modalAddData.forEach((obj : OrcFormData) => {
      newVal[obj.Key] = obj.Value;
    });
    debugger;
    newVal.__RowStatus = RowStatus.Added;
    newVal.__RowError = false;
    newVal.__RowMessage = "";
    
    newobj.Data.push(newVal);


    setData(newobj);
    setSaveNeeded(true);


  }

  return (
    <>
      {true && <div 
        key={props.FieldName}
        className={clsx("bg-white p-2 w-full rounded-sm", props.ClassName)}
      >
        {/* Table menu div */}
        <div className="flex items-center justify-between h-10 w-full mb-2">
          <div className="flex flex-row gap-1">
            {/* Yarat Düğmesi */}
            {ViewObject.Enable_Add && (
              <button
                className={
                  tablemode == ViewStatus.EditMode ||
                  tablemode == ViewStatus.CreateMode
                    ? "w-8 h-8 bg-white text-gray-300 cursor-not-allowed rounded-sm flex items-center justify-center"
                    : "w-8 h-8 bg-white text-gray-800 cursor-pointer rounded-sm flex items-center justify-center"
                }
                onClick={CreateMode}
                disabled={
                  tablemode == ViewStatus.EditMode ||
                  tablemode == ViewStatus.CreateMode
                    ? true
                    : false
                }
              >
                <Icon icon="iconoir:empty-page" width={22} height={22} />
              </button>)}

            {/* Değiştir Düğmesi */}
            {ViewObject.Enable_Mod && ViewObject.EditType == EditType.Inline && (
              <button
                className={
                  tablemode == ViewStatus.EditMode ||
                  tablemode == ViewStatus.CreateMode
                    ? "w-8 h-8 bg-white text-gray-300 cursor-not-allowed rounded-sm flex items-center justify-center"
                    : "w-8 h-8 bg-white text-gray-800 cursor-pointer rounded-sm flex items-center justify-center"
                }
                disabled={
                  tablemode == ViewStatus.EditMode ||
                  tablemode == ViewStatus.CreateMode
                    ? true
                    : false
                }
                onClick={ChangeMode}
              >
                <Icon icon="cil:pencil" width={22} height={22} />
              </button>
            )}
            {/* UnDo Düğmesi */}
            {(ViewObject.Enable_Add || ViewObject.Enable_Mod || ViewObject.Enable_Del) && (
              <button
                className={
                  false
                    ? "w-8 h-8 bg-white text-gray-300 cursor-not-allowed rounded-sm flex items-center justify-center"
                    : "w-8 h-8 bg-white text-gray-800 cursor-pointer rounded-sm flex items-center justify-center"
                }
                //disabled={tablemode == ViewStatus.CreateMode ? true : false}
                //disabled={saveNeeded == false ? true : false}
                onClick={UnDo}
                title="Değişiklikleri geri al"
              >
                <Icon icon="clarity:undo-line" width={22} height={22} />
              </button>
            )}
            {/* Kaydet Düğmesi */}
            {(ViewObject.Enable_Add || ViewObject.Enable_Mod || ViewObject.Enable_Del) && (<button
              className={
                saveNeeded == false
                  ? "w-8 h-8 bg-white text-gray-300 cursor-not-allowed rounded-sm flex items-center justify-center"
                  : "w-8 h-8 bg-white text-gray-800 cursor-pointer rounded-sm flex items-center justify-center"
              }
              disabled={saveNeeded == false ? true : false}
              title="Değişiklikleri kaydet"
              onClick={Save}
            >
              <Icon icon="oui:save" width={22} height={22} />
            </button>)}
          </div>
          { tablemode == ViewStatus.DisplayMode && <div>{ ViewObject.Data ? ViewObject.Data.length : 0} records found</div>}
        </div>
        {/* Table itself */}
        <table className="table-auto text-left w-full">
          <thead>
            <tr>
              {ViewObject.Columns.filter((c) => c.Visible === true).map(
                (col: OrcViewColumn) => (
                  <th>{col.Caption}</th>
                )
              )}
              {/* Add blank header if it has row command columns */}
              {ShowCommandColumn() && tablemode === ViewStatus.DisplayMode && <th>#</th>}
              {ShowCommandColumn() && tablemode !== ViewStatus.DisplayMode && <th></th>}
            </tr>
          </thead>
          <tbody>
            {ViewObject.Data &&
              ViewObject.Data
                .filter((f : any) => f.__RowStatus != RowStatus.Deleted)
                .map((rowdata: any, idx: number) => (
                  <tr className="hover:bg-gray-100 p-2 border-b-2 border-gray-300">
                    {/* adding data columns */}
                    {ViewObject.Columns.filter((c) => c.Visible === true).map((col: OrcViewColumn) => (
                      <td
                        className={clsx(
                          "h-10",
                          col.DataType == "BOOL"
                            ? "text-center align-middle"
                            : ""
                        )}
                      >
                     
                       
                        {RenderCell(idx, rowdata, col)}
                      </td>
                    ))}
                    {/* adding command column */}
                    {ShowCommandColumn() && tablemode == ViewStatus.DisplayMode  && (
                      <td>
                        <div className="flex flex-row gap-1 items-center justify-start">
                          {/* Row Buttons */}
                          {(ViewObject.Enable_Mod && ViewObject.EditType !== EditType.Inline) && (
                            <div className="hover:cursor-pointer hover:scale-125 transform transition duration-200">
                              <Icon
                                icon="cil:pencil"
                                width={rowbuttonsize}
                                height={rowbuttonsize}
                                onClick={() => RowAction_Edit(idx, rowdata)}

                              />
                            </div>
                          )}
                          {ViewObject.Enable_Det && (
                            <div className="hover:cursor-pointer hover:scale-125 transform transition duration-200">
                              <Icon
                                icon="tabler:zoom"
                                width={rowbuttonsize}
                                height={rowbuttonsize}
                              />
                            </div>
                          )}
                          {ViewObject.Enable_Del && (
                            <div className="hover:cursor-pointer hover:scale-125 transform transition duration-200">
                              <Icon
                                icon="material-symbols-light:delete-outline"
                                width={22}
                                height={22}
                                className="text-red-700"
                                onClick={() => RowAction_Delete(idx, rowdata)}
                                
                              />
                            </div>
                          )}
                          {ViewObject.Enable_Exc && (
                            <div className="hover:cursor-pointer hover:scale-125 transform transition duration-200">
                              <Icon
                                icon="clarity:refresh-line"
                                width={rowbuttonsize}
                                height={rowbuttonsize}
                              />
                            </div>
                          )}
                          {ViewObject.Enable_Copy && (
                            <div className="hover:cursor-pointer hover:scale-125 transform transition duration-200">
                              <Icon
                                icon="clarity:copy-line"
                                width={rowbuttonsize}
                                height={rowbuttonsize}
                              />
                            </div>
                          )}
                        </div>
                      </td>
                    )}
                    {ShowCommandColumn() && tablemode !== ViewStatus.DisplayMode && <td className="w-14"></td>}
                  </tr>
                ))} 
          </tbody>
        </table>
      </div>}
      <Toaster />
      <OrcModal OpenModal={openModal} 
        FieldList={ViewObject.Columns} 
        onOpenChange={(val: boolean) => setOpenModal(val)} 
        onSubmitted={(data) => MergeModalData(data)} 
         />
    </>
  );
};
export default OrcView;
