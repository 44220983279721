import QRCode from "react-qr-code";

export interface IOrcQRCode {
  FieldName: string;
  ClassName?: string;
  Title?: string;
  Value: string;
  ParentFieldName?: string;
}

export default function OrcQRCode(props: IOrcQRCode) {
  return (
    <div className="flex flex-col justify-center items-center mt-5 gap-2">
      <div className="font-semibold">{ props.Title }</div>
      <QRCode   style={{ height: "auto", maxWidth: "80%", width: "80%" }} value={props.Value} />
    </div>
  );
}
