import { useContext, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { OrcContext } from "@/context/OrcContext";
import i18n from "@/i18n/config";
import Cookies from "js-cookie";

//https://github.com/rollinmendez000/DataFetchHook-react/blob/master/src/components/CoffeeList.js

const useHttpClient = () => {
    //
  // const [data, setData] = useState(null);
  // const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const sysContext = useContext(OrcContext);

  const baseUrl = process.env.REACT_APP_APIHOSTNAME

  // const addHeader = (key: string, value: string) => {
  //   let app_version = "1";
  //   this.headers = {
  //     ...this.headers,
  //     isMobile: "true",
  //     version: app_version,
  //     [key]: value,
  //   };
  // };
  const request = async <T> (url : string, method : string, data?: any, addheaders?: any,) :  Promise<T|undefined> => {
    setLoading(true);
    //const token =  Cookies.get("token");
    //const token =getCookie("token");
    //document.cookie = "token=" + token;
   //axios.defaults.withCredentials = true;
    try {
      const response = await axios(`${baseUrl}${url}`, {
        headers: {
          "Langu" : i18n.language.toUpperCase(),
         // "Authorization" : `Bearer ${token}`,
          ...addheaders,
        },
        method: method,
        data: data,
        withCredentials: true
      });
      return response.data;
      //setData(response.data);
    } catch (error : any) {
      sysContext.showError(error.message);
      console.log("Server Error ", error);
    } finally {
      setLoading(false);
    }
  };

  const login = async (data?: any)  => {
    setLoading(true);
  //  axios.defaults.withCredentials = true;
    try {
      const response = await axios(`${baseUrl}${"/login"}`, {
        headers: {
          "Langu" : i18n.language.toUpperCase(),
        },
        method: "POST",
        data: data,
        withCredentials: true
      });
      debugger;
      return response;
    } catch (error : any) {
      //
      if(error.response.status === 401)
        sysContext.showError("Invalid Username or Password");
      else if(error.response.status === 423)
        sysContext.showError("User has been locked");
      else
        sysContext.showError(error.message);
      //
      console.log("Server Error ", error);
    } finally {
      setLoading(false);
    }
  };

  function getCookie(cname :any) {
    debugger;
    var name = cname + "=",
        ca = document.cookie.split(';'),
        i,
        c,
        ca_length = ca.length;
    for (i = 0; i < ca_length; i += 1) {
        c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) !== -1) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

//   useEffect(() => {
//     fetchData();
//   }, [url]);

//   const refetch = () => {
//     fetchData();
//   };

  //return { data, error, isLoading, getData };
  return {isLoading, login, request };
};

export default useHttpClient;