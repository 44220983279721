import { Input } from "@/shadcn/ui/input";
import { Label } from "@/shadcn/ui/label";
import React, { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";
import { Button } from "@/shadcn/ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { SearchHelp } from "../../comps/SearchHelp";
import { toast } from "@/shadcn/ui/use-toast";
import { Toaster } from "@/shadcn/ui/toaster";
import { Icon } from "@iconify/react";
import { User, UserPassword } from "@/models/idm/User";
import RoleService from "@/services/idm/RoleService";
import UserService from "@/services/idm/UserService";
import { Checkbox } from "@/shadcn/ui/checkbox";
//
function ChangePassword() {
  const navigate = useNavigate();

  const routeParams = useParams();
  console.log("routeParams = ", JSON.stringify(routeParams));
  const BreadcrumbPart = () => (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/users");
            }}
          >
            User Management
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Change Password</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );

  let InitialUser: UserPassword = {
    UserName: routeParams.username ? routeParams.username : "",
    Password:"",
    ConfirmPassword: "",
    Changed: false
  };


  const [PageObject, SetPage] = useState(InitialUser);
  const [loaded, SetLoaded] = useState(false);
  // const [canbeChanged, setCanBeChanged] = useState(false);


  useEffect(() => {
    // SetLoaded(false);
    fetch();
    // SetLoaded(true);
  }, []);

  const fetch = async () => {
    if (routeParams.username) {
      const resp = await UserService.getByName(routeParams.username);
      if (resp) {
        if (resp) {
          let UserObj: UserPassword = {
            UserName: resp.UserName,
            Password:"",
            ConfirmPassword: "",
            Changed: false
          };
          SetPage(UserObj);
        } else {
          //toast.success(resp.Error); // Displays a success message
          debugger;
          // SetPage(resp.error);
        }
      }
    }
  };


  const PropChanged = (fname: string, val: any) => {
    if (fname === "Password") {
      let newobj: UserPassword = { ...PageObject, Password: val };
      SetPage(newobj);
    }  else if (fname === "ConfirmPassword") {
      let newobj: UserPassword = { ...PageObject, ConfirmPassword: val };
      SetPage(newobj);
    } 
  };







  /*
    Saves
  */
  const SendChangePasswordData = async () => {
    debugger;
    SetLoaded(false);
    let newSaveobj: UserPassword = { ...PageObject };

    if(newSaveobj.Password !== newSaveobj.ConfirmPassword)
    {
      toast({
        title: "Error in Password",
        description: "Password and Confirm Password does not match.",
      });
      SetLoaded(true);
      return;
    }


    const resp = await UserService.changepass(newSaveobj);
    if (resp.data) {
      if(resp.data.Changed)
      {
        let newSaveobj1: UserPassword = { ...newSaveobj };
        SetPage(newSaveobj1);
        toast({
          title: "Successfull:)",
          description: "User password has been changed.",
        });
        navigate("/apps/users");
      }
      else
      {
        toast({
          title: "Error in Service",
          description: resp.data.Errors,
        });
      }

    } else {
      toast({
        title: "Error in Service",
        description: resp.usermessage,
      });
    }

    SetLoaded(true);
  };



  return (
    <div className="p-4 bg-white h-screen">
      <h1 className="font-bold text-2xl text-red-800">Change Password</h1>
      <div className="mb-4">
        <BreadcrumbPart />
      </div>
      <Separator className="my-4" />
      <div className="flex flex-row flex-nowrap my-2 gap-1">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          // variant={"outline"}
          onClick={() => SendChangePasswordData()}
          title="Save user"
        >
          <Icon
            icon="carbon:save"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
      </div>

      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row items-center">
          <Label className="w-48">User Name</Label>
          <Input
            className="bg-gray-100 w-72 lowercase"
            placeholder="?"
            value={PageObject.UserName}
            maxLength={50}
            disabled={true}
          />
        </div>
        {/* <div className="flex flex-row items-center">
          <Label className="w-48">Full Name</Label>
          <Input
            className="bg-gray-100 w-52 uppercase"
            placeholder="?"
            value={PageObject.Ename}
            maxLength={60}
            disabled={true}
            onChange={(e) => PropChanged("Ename", e.target.value)}
          ></Input>
        </div> */}

        {/* Tab Start */}
        <Tabs defaultValue="password" className="md:w-2/3 mt-5">
          <TabsList>
            <TabsTrigger value="password">Password Reset</TabsTrigger>
          </TabsList>
          {/* Tab 1 : Password */}
          <TabsContent value="password" className="flex flex-col gap-2">
            {/* Password */}
            <div className="flex flex-row items-center gap-2">
              <Label className="w-48">New Password</Label>
              <Input
                className="bg-white w-64"
                type="password"
                placeholder=""
                value={PageObject.Password}
                maxLength={50}
                autoComplete="new-password"
                onChange={(e) => PropChanged("Password", e.target.value)}
              ></Input>
            </div>
              {/* Password */}
              <div className="flex flex-row items-center gap-2">
              <Label className="w-48">Confirm Password</Label>
              <Input
                className="bg-white w-64"
                type="password"
                placeholder=""
                autoComplete="new-password"
                value={PageObject.ConfirmPassword}
                maxLength={50}
                onChange={(e) => PropChanged("ConfirmPassword", e.target.value)}
              ></Input>
            </div>
          </TabsContent>
        </Tabs>
        <Toaster />
      </div>

    </div>
  );
}

export default ChangePassword;
