import DIContainer from "@/models/DIContainer";
import { ModulItem, SideNavItem } from "@/models/Types";
import IResponseHelper from "@/models/response/IResponseHelper";

class ModuleService {
  private httpClient = DIContainer.HttpJsonClient;

  get = async () => {
    const response = await this.httpClient.get<IResponseHelper<ModulItem[]>>(
      `/api/v1/nav/module`
    );

    return response;
  };
}

export default new ModuleService();
