import { OrcContext } from "@/context/OrcContext";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tile, { ITile } from "./comps/Tile";
import TileMenuService from "@/services/nav/TileMenuService";
import { TilePageModel } from "@/models/navi/TilePageModel";
//
function TilesPage() {
  //
  const navigate = useNavigate();
  const currentContext = useContext(OrcContext);
  const themename = currentContext.Theme;
  //
  const [loaded, setLoaded] = useState(false);
  var initial: TilePageModel = {
    Title: "",
    SubTitle: "",
    ShowGlobalFilter: false,
    ShowHeader: true,
    Tiles: [],
  };
  const [data, setData] = useState<TilePageModel>(initial);
  const [bg, setBgImage] = useState("/images/bg/bg001.jpg");
  debugger;
  const routeParams = useParams();
  useEffect(() => {
    setLoaded(false);
    fetch();
    setLoaded(true);
  }, []);

  const usr = currentContext.User;

  const fetch = async () => {
    if (routeParams.menuid) {
      const resp = await TileMenuService.get(routeParams.menuid.toUpperCase());
      if (resp.data) {
        setData(resp.data);
      } else {
        //toast.success(resp.Error); // Displays a success message
        debugger;
        alert(resp.error);
      }
    }

    debugger;
  };

  const GotoPage = (lnk: string) => {
    if (lnk == "/logout") {
      currentContext.removeUser();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else navigate(lnk);
    //alert(lnk);
  };

  const Alert = () => {
    alert("deneme");
  };

  const changeTheme = () => {
    currentContext.updateTheme("Dark");
  };

  return (
    <>
      {loaded && (
        <div
          className="p-4 h-screen font-sapFont"
          style={{ backgroundImage: `url(${bg})` }}
        >
          {data.ShowHeader && (
            <div className="flex flex-row justify-between">
              <div className="">
                <div className="text-4xl mb-2 font-normal text-white">
                  {data.Title}
                </div>
                <div className="text-2xl mb-5 font-thin text-white">
                  {data.SubTitle ? data.SubTitle : ""}
                </div>
              </div>
              <div></div>
            </div>
          )}

          <div className="flex flex-row flex-wrap gap-2">
            {data.Tiles.map((item, idx) => {
              return (
                <Tile
                  FieldName={item.FieldName}
                  Title={item.Title}
                  SubTitle={item.SubTitle}
                  IconName={item.IconName}
                  ClassName={item.ClassName}
                  IconClassName={item.IconClassName}
                  NavigateTo={item.NavigateTo?.toLowerCase()}
                  Figure={item.Figure}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default TilesPage;
