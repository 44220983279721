import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { motion } from "framer-motion";
import { Outlet, useLocation } from "react-router-dom";
import HeaderMobile from "@/components/HeaderMobile";
import clsx from "clsx";


export default function AnonymLayout() {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div
      className={clsx(
        "top-0 z-[-2]  font-sapFont"
        //"bg-gradient-to-br from-white to-gray-100"
      )}
    >
      <Header />
      <HeaderMobile />
      <main className="">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 0.55 }}
          key={pathname}
        >
          <Outlet />
        </motion.div>
      </main>
       <Footer />
    </div>
  );
}
