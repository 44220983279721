import { Icon } from "@iconify/react";
import { SideNavItem } from "./models/navi/SideNavItem";
import { HeaderMenuItem } from "./models/navi/HeaderMenuItem";

export const SIDENAV_ITEMS: SideNavItem[] = [
  {
    title: "Home",
    path: "/apps/home",
    iconname: "",
    icon: <Icon icon="carbon:home" width="18" height="18" />,
  },
  {
    title: "Analytics",
    path: "/apps/analytics",
    iconname: "",
    icon: <Icon icon="carbon:home" width="18" height="18" />,
  },
  {
    title: "Organization",
    path: "/apps/organization",
    iconname: "",
    icon: <Icon icon="clarity:organization-line" width="18" height="18" />,
  },
  {
    title: "Recruitment",
    path: "/apps/recruitment",
    iconname: "",
    icon: <Icon icon="pepicons-pencil:people" width="18" height="18" />,
  },
  {
    title: "People",
    path: "/apps/people",
    iconname: "",
    icon: <Icon icon="pepicons-pencil:people" width="18" height="18" />,
  },
  {
    title: "Self Services",
    path: "/apps/selfservices",
    iconname: "",
    icon: <Icon icon="octicon:workflow-24" width="18" height="18" />,
  },
  {
    title: "Time&Attendance",
    path: "/apps/time",
    iconname: "",
    icon: <Icon icon="octicon:workflow-24" width="18" height="18" />,
  },
  {
    title: "Payroll",
    path: "/apps/payroll",
    iconname: "",
    icon: <Icon icon="octicon:workflow-24" width="18" height="18" />,
  },
  {
    title: "Sample 1",
    path: "/apps/sample1",
    iconname: "",
    icon: <Icon icon="octicon:workflow-24" width="18" height="18" />,
  },
  {
    title: "Table Sample",
    path: "/apps/sample2",
    iconname: "",
    icon: <Icon icon="octicon:workflow-24" width="18" height="18" />,
  },

  // {
  //   title: "Workflows",
  //   path: "/apps/workflows",
  //   icon: <Icon icon="octicon:workflow-24" width="18" height="18" />,
  // },
  // {
  //   title: "Dashboards",
  //   path: "/ess",
  //   icon: <Icon icon="mage:dashboard-chart-arrow" width="18" height="18" />,
  //   submenu: true,
  //   subMenuItems: [
  //     { title: "Company Apps", path: "/apps/menu/ess-comp" },
  //     { title: "ESS Master Data", path: "/apps/menu/ess-pa" },
  //     { title: "ESS Time Data", path: "/apps/menu/ess-tim" },
  //     { title: "My Payroll Slips", path: "/apps/menu/ess_py" },
  //     { title: "Time Evaluation Tasks", path: "/apps/menu/ess_myteam" },
  //     { title: "My Objective Cards", path: "/apps/menu/ess-perf" },
  //     { title: "R&D Center Tasks", path: "/apps/menu/ess_rd" },
  //     { title: "My Signed Documents", path: "/apps/menu/ess_esign" },
  //   ],
  // },
  // {
  //   title: "Self Services",
  //   path: "/ess",
  //   icon: <Icon icon="carbon:user" width="18" height="18" />,
  //   submenu: true,
  //   subMenuItems: [
  //     { title: "Company Apps", path: "/apps/menu/ess-comp" },
  //     { title: "ESS Master Data", path: "/apps/menu/ess-pa" },
  //     { title: "ESS Time Data", path: "/apps/menu/ess-tim" },
  //     { title: "My Payroll Slips", path: "/apps/menu/ess_py" },
  //     { title: "Time Evaluation Tasks", path: "/apps/menu/ess_myteam" },
  //     { title: "My Objective Cards", path: "/apps/menu/ess-perf" },
  //     { title: "R&D Center Tasks", path: "/apps/menu/ess_rd" },
  //     { title: "My Signed Documents", path: "/apps/menu/ess_esign" },
  //   ],
  // },
  // {
  //   title: "My Team",
  //   path: "/mss",
  //   icon: <Icon icon="lucide:users" width="18" height="18" />,
  // },
  // {
  //   title: "Human Resources",
  //   path: "/hr",
  //   icon: <Icon icon="mdi:human-queue" width="18" height="18" />,
  //   submenu: true,
  //   subMenuItems: [
  //     { title: "Organization Management", path: "/projects" },
  //     { title: "Personnel Administration", path: "/projects/web-design" },
  //     { title: "Payroll Tasks", path: "/projects/graphic-design" },
  //     { title: "Performance Management", path: "/projects/graphic-design" },
  //   ],
  // },
  // {
  //   title: "CRM",
  //   path: "/settings",
  //   icon: <Icon icon="ri:customer-service-line" width="18" height="18" />,
  //   submenu: true,
  //   subMenuItems: [
  //     { title: "Account", path: "/settings/account" },
  //     { title: "Privacy", path: "/settings/privacy" },
  //   ],
  // },
  // {
  //   title: "Settings",
  //   path: "/settings",
  //   icon: <Icon icon="lucide:settings" width="18" height="18" />,
  // },
  {
    title: "Logout",
    path: "/logout",
    iconname: "",
    icon: <Icon icon="cil:account-logout" width="18" height="18" />,
  },
];

export const HEADER_ITEMS_PLUS: HeaderMenuItem[] = [
  {
    name: "Home",
    href: "/",
    classnames: "",
  },
  {
    name: "UseCases",
    href: "/usecases",
    classnames: "",
  },
  {
    name: "Contact",
    href: "/contact",
    classnames: "",
  },
  {
    name: "Login",
    href: "/login",
    classnames: "",
  },
];

export const HEADER_ITEMS_HR: HeaderMenuItem[] = [
  {
    name: "Home",
    href: "/",
    classnames: "",
  },
  {
    name: "Product",
    href: "/product",
    classnames: "",
  },
  {
    name: "Customers",
    href: "/customers",
    classnames: "",
  },
  {
    name: "Resources",
    href: "https://orchestrahr-com.gitbook.io/docs/",
    classnames: "",
  },
  {
    name: "Events",
    href: "/hrteched",
    classnames: "",
  },
  {
    name: "Register Company",
    href: "/company/register",
    classnames: "",
  },
  {
    name: "Login to Company",
    href: "/company/go",
    classnames: "",
  },
];
