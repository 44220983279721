import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
//import "react-datepicker/dist/react-datepicker.css";
//import "react-datepicker/dist/react-datepicker.min.css";
// import "react-datepicker/dist/react-datepicker-cssmodules.css";

import "react-datepicker/dist/react-datepicker.css"
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
      <Helmet>
        <meta charSet="utf-8" />
        <title>OrchestraHR - Best HR Management Software</title>
        <link rel="icon" href="https://orchestrahr.com/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://orchestrahr.com/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://orchestrahr.com/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://orc365.com/favicon-16x16.png"
        />

        <link rel="canonical" href="https://orchestrahr.com" />
      </Helmet>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
