import { useEffect, useState } from "react"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";
import { useNavigate } from "react-router-dom";
import { Toaster } from "@/shadcn/ui/toaster";
import { Icon } from "@iconify/react";
import { Label } from "@/shadcn/ui/label";
import { toast } from "@/shadcn/ui/use-toast";
import DomainService from "@/services/dd/DomainService";
import { SearchHelp } from "../comps/SearchHelp";
import UIPageService from "@/services/ui/UIPageService";
import { OrcScreen } from "@/models/screen/OrcScreen";
import { ScreenEvent } from "@/models/Enums";
import ScreenService from "@/services/program/ScreenService";


export default function UIPage() {

    const navigate = useNavigate();

    const[pagename,SetPageName] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [pagefound, setPageFound] = useState(false);
    const [unAuthorized, setunAuthorized] = useState(false);

    let initialscreen: OrcScreen = new OrcScreen();
    initialscreen.UIPath =  encodeURIComponent(window.location.pathname.toLowerCase());
    initialscreen.HRef = window.location.href;
    initialscreen.ProgName = "";
    initialscreen.ScreenEvent = ScreenEvent.Get;

    const UnAuthorizedView = (
      <div className="min-h-[500px] flex flex-col items-center justify-center text-4xl text-primary">
        <div className="font-bold text-6xl mb-4">401</div>
        <div>
          Page{" "}
          <span className="text-red-600">
            {decodeURIComponent(initialscreen.ProgName.toLowerCase())}
          </span>{" "}
          Not authorized
        </div>
      </div>
    );

    const GetHistory = () => {
      const _uicode = localStorage.getItem("UICODE");
      if(_uicode)
      {
        SetPageName(_uicode);
      }
    
  
    }

    useEffect(() => {
      fetch();
      GetHistory();
    }, []);
  
    const fetch = async () => {
      setLoaded(false);
      const resp = await ScreenService.post(initialscreen);
      if (resp.data) {
        console.log(resp.data);
        setunAuthorized(resp.data.UnAuthorized);
      } else {
        setLoaded(true);
        setPageFound(false);
      }
      debugger;
    };

    const Create = async () => {
        if(pagename.length>0){
            if (pagename.includes(" ")) {
                toast({
                  title: "Check pagename",
                  description: "pagenames should have no blank.",
                });
              } else {
                var pageExist = await UIPageService.isExist(pagename);
                if (pageExist === false) {
                  localStorage.setItem("UICODE", pagename);
                  navigate("/apps/pages/create/" + pagename);
                } else {
                  toast({
                    title: "Page already exist.",
                    description:
                      "Page already created, check your name and try again.",
                  });
                }
              }
        }

    }

    const Change = async () => {
        if (pagename.length > 0) {
            var pageExist = await UIPageService.isExist(pagename);
            if (pageExist) {
              localStorage.setItem("UICODE", pagename);
              navigate("/apps/pages/change/" + pagename);
            } else {
              toast({
                title: "Page does not exist.",
                description: "Please check your name and try again.",
              });
            }
          } else {
            toast({
              title: "Please select page",
            });
          }
    }

    const Delete = async () => {
        if (pagename.length > 0) {
            var pageExist = await UIPageService.isExist(pagename);
            if (pageExist) {
              localStorage.setItem("UICODE", pagename);
              navigate("/apps/pages/delete/" + pagename);
            } else {
              toast({
                title: "Page does not exist.",
                description: "Please check your name and try again.",
              });
            }
          } else {
            toast({
              title: "Please select page",
            });
          }
    }

    const Display = async () => {
        if (pagename.length > 0) {
            var pageExist = await UIPageService.isExist(pagename);
            if (pageExist) {
              localStorage.setItem("UICODE", pagename);
              navigate("/apps/pages/display/" + pagename);
            } else {
              toast({
                title: "Page does not exist.",
                description: "Please check your name and try again.",
              });
            }
          } else {
            toast({
              title: "Please select page",
            });
          }
    }

    const OnpagenameSelect = (x: string) => {
      SetPageName(x);
      };


    return (unAuthorized === false ? (<div className="bg-white w-full h-screen p-4">
        <h1 className="font-bold text-2xl">Pages</h1>
        <div className="mb-4">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink
                className="hover:cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/apps/exec/basis/develop");
                }}
              >
                Develop
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Select Page</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <Separator className="my-4" />
     {/* Commands Start */}
     <div className="flex flex-row flex-nowrap my-2 gap-1 mb-10">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Create()}
          title="Create"
        >
          <Icon
            icon="akar-icons:paper"
            width="18"
            height="18"
            className="text-black bg-white"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Change()}
          title="Change"
        >
          <Icon
            icon="solar:pen-outline"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Display()}
          title="Display"
        >
          <Icon
            icon="tabler:zoom"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Delete()}
          title="Delete"
        >
          <Icon
            icon="material-symbols-light:delete-outline"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
      </div>
      {/* Commands End */}
      <div className="flex items-center">
        <div className="w-28">
        <Label className="ms-2">Page Name</Label>
        </div>
        <SearchHelp
              SHName="PAGENAME"
              className="ms-20 w-96"
              onChange={(e) => SetPageName(e.target.value)}
              InputClassName="uppercase"
              value={pagename}
              OnSelect={(x) => OnpagenameSelect(x)}
            />
      </div>

    <Toaster />
    </div>)  : UnAuthorizedView)


}