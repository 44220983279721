import React from "react";
import clsx from "clsx";

export interface IOrcHeader4 {
  FieldName: string;
  ClassName?: string;
  Text: string;
  ParentFieldName: string;
}

export default function OrcHeader4(props: IOrcHeader4) {
  return (
    <>
      <h4
        key={props.FieldName}
        id={props.FieldName}
        className={clsx("font-bold text-black", props.ClassName)}
      >
        {props.Text}
      </h4>
    </>
  );
}
