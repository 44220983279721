import React from "react";
import { Enum_Icons } from "../models/Enums";
import { Interface_Icon } from "../models/Interfaces";

import { AiOutlineCreditCard, AiOutlineUser } from "react-icons/ai";
import { BsBuildings, BsFillFileExcelFill } from "react-icons/bs";
import { CiSettings } from "react-icons/ci";
import {
  FaChartPie,
  FaLocationArrow,
  FaMobileAlt,
  FaQuestionCircle,
  FaRegCalendarTimes,
  FaRegFilePdf,
  FaUser,
  FaUserTie,
} from "react-icons/fa";
import { GiBrickWall, GiMoneyStack } from "react-icons/gi";
import { GrContactInfo } from "react-icons/gr";
import { HiOutlineShare } from "react-icons/hi";
import { IoIosLink, IoIosPhonePortrait, IoMdTime } from "react-icons/io";
import {
  IoBarChartSharp,
  IoCalculatorOutline,
  IoCalendarNumber,
  IoHome,
  IoLogoStencil,
  IoPerson,
  IoTodaySharp,
} from "react-icons/io5";
import { MdFamilyRestroom, MdMoreTime, MdOutlineAvTimer } from "react-icons/md";
import { RiOrganizationChart } from "react-icons/ri";
import { TbTargetArrow, TbTransform } from "react-icons/tb";

export default function Icon(props: Interface_Icon) {
  var iconEnum = Enum_Icons.HomeIcon;

  iconEnum = props.icon as Enum_Icons;

  switch (iconEnum) {
    case Enum_Icons.IoIosLink:
      return (
        <IoIosLink
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.GiBrickWall:
      return (
        <GiBrickWall
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.FaMobileAlt:
      return (
        <FaMobileAlt
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.FaRegFilePdf:
      return (
        <FaRegFilePdf
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.FaChartPie:
      return (
        <FaChartPie
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.HomeIcon:
      return (
        <IoHome
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.EssIcon:
      return (
        <AiOutlineUser
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.EssTMIcon:
      return (
        <AiOutlineUser
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.EssTMIcon:
      return (
        <AiOutlineUser
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.WorkflowIcon:
      return (
        <TbTransform
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.GraphicIcon:
      return (
        <IoBarChartSharp
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.SocialIcon:
      return (
        <HiOutlineShare
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.OrgChartIcon:
      return (
        <RiOrganizationChart
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.EmpDataIcon:
      return (
        <GrContactInfo
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.PerformanceIcon:
      return (
        <TbTargetArrow
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.TimeAdminIcon:
      return (
        <IoMdTime
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.PayrollIcon:
      return (
        <IoCalculatorOutline
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.SettingsIcon:
      return (
        <CiSettings
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.PersonalIcon:
      return (
        <IoPerson
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.AddressIcon:
      return (
        <FaLocationArrow
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.FamilyIcon:
      return (
        <MdFamilyRestroom
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.EduIcon:
      return (
        <BsBuildings
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.ExperienceIcon:
      return (
        <IoLogoStencil
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.CommIcon:
      return (
        <IoIosPhonePortrait
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.PayIcon:
      return (
        <GiMoneyStack
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.BankIcon:
      return (
        <AiOutlineCreditCard
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.AssetsIcon:
      return (
        <IoTodaySharp
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.DatesIcon:
      return (
        <IoCalendarNumber
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.OvertimeIcon:
      return (
        <MdOutlineAvTimer
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.TimeEventsIcon:
      return (
        <MdMoreTime
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.AbsenceIcon:
      return (
        <FaRegCalendarTimes
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.UserIcon:
      return (
        <FaUser
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.UserTieIcon:
      return (
        <FaUserTie
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
    case Enum_Icons.Excel:
      return (
        <BsFillFileExcelFill
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );

    default:
      return (
        <IoHome
          size={props.size ? props.size : 18}
          className={props.classnames ? props.classnames : ""}
        />
      );
  }
}
