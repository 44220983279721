import { Input } from "@/shadcn/ui/input";
import { Label } from "@/shadcn/ui/label";
import React, { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";
import { Button } from "@/shadcn/ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { SearchHelp } from "../../comps/SearchHelp";
import { toast } from "@/shadcn/ui/use-toast";
import { Toaster } from "@/shadcn/ui/toaster";
import { Icon } from "@iconify/react";
import { User } from "@/models/idm/User";
import RoleService from "@/services/idm/RoleService";
import UserService from "@/services/idm/UserService";
import { Checkbox } from "@/shadcn/ui/checkbox";
//
function CreateUserDetail() {
  const navigate = useNavigate();

  const routeParams = useParams();
  console.log("routeParams = ", JSON.stringify(routeParams));
  const BreadcrumbPart = () => (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/users");  
            }}
          >
            User Management
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Create User</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );

  let InitialUser: User = {
    UserName: routeParams.username ? routeParams.username : "",
    Pernr: "",
    Aplno: "",
    Ename: "",
    Email: "",
    Phone:"",
    Password:"",
    MFA: false,
    Locked: false,
    Roles: [],
  };

  for (let index = 0; index < 10; index++) {
    InitialUser.Roles.push({
      RoleName: "",
      Definition: "",
    });
  }

  const [PageObject, SetPage] = useState(InitialUser);
  const [loaded, SetLoaded] = useState(false);
  const [canbeChanged, setCanBeChanged] = useState(false);

  const PropChanged = (fname: string, val: any) => {
    if (fname === "UserName") {
      let newobj: User = { ...PageObject, UserName: val };
      SetPage(newobj);
    }     else if (fname === "Password") {
      let newobj: User = { ...PageObject, Password: val };
      SetPage(newobj);
    }   else if (fname === "Pernr") {
      let newobj: User = { ...PageObject, Pernr: val };
      SetPage(newobj);
    } else if (fname === "Aplno") {
      let newobj: User = { ...PageObject, Aplno: val };
      SetPage(newobj);
    }
    else if (fname === "Ename") {
      let newobj: User = { ...PageObject, Ename: val };
      SetPage(newobj);
    } else if (fname === "Email") {
      let newobj: User = { ...PageObject, Email: val };
      SetPage(newobj);
    } else if (fname === "Phone") {
      let newobj: User = { ...PageObject, Phone: val };
      SetPage(newobj);
    } else if (fname === "MFA") {
      let newobj: User = { ...PageObject, MFA: val };
      SetPage(newobj);
    } else if (fname === "Locked") {
      let newobj: User = { ...PageObject, Locked: val };
      SetPage(newobj);
    }
  };

  const RoleListChanged = (fname: string, ind: number, val: any) => {
    debugger;
    if (fname === "RoleName") {
      let newobj: User = { ...PageObject };
      newobj.Roles[ind].RoleName = val;
      SetPage(newobj);
    } 
  };


  const OnRoleNameSelect = async (x: string, ind: number) => {
    debugger;
    let newobj: User = { ...PageObject };
    newobj.Roles[ind].RoleName = x;

    var roleData = await RoleService.getByName(x);
    if (roleData) {
      newobj.Roles[ind].RoleName = roleData.RoleName;
      newobj.Roles[ind].Definition = roleData.Definition;
    }
    else
    {
      newobj.Roles[ind].RoleName = x;
      newobj.Roles[ind].Definition = "";
    }

    SetPage(newobj);
  };

  const OnRoleNameBlur = async (x: string, ind: number) => {
    let newobj: User = { ...PageObject };
    if (x === "") {
      newobj.Roles[ind].RoleName = "";
      newobj.Roles[ind].Definition = "";
      SetPage(newobj);
      return;
    }

    newobj.Roles[ind].RoleName = x;

    var roleData = await RoleService.getByName(x);
    if (roleData) {
      newobj.Roles[ind].RoleName = roleData.RoleName;
      newobj.Roles[ind].Definition = roleData.Definition;
    }
    else
    {
      newobj.Roles[ind].RoleName = x;
      newobj.Roles[ind].Definition = "";
    }

    SetPage(newobj);
  };

  /*
    Saves
  */
  const Save = async () => {
    debugger;
    SetLoaded(false);
    let newSaveobj: User = { ...PageObject, Roles: [] };

    PageObject.Roles.forEach((role) => {
      if (role.RoleName) {
        newSaveobj.Roles.push({
          RoleName: role.RoleName,
          Definition: role.Definition,
        });
      }
    });

    const resp = await UserService.post(newSaveobj);
    if (resp.data) {

      let newSaveobj1: User = { ...newSaveobj };
      SetPage(newSaveobj1);
      toast({
        title: "Successfull:)",
        description: "User has been created.",
      });
      navigate("/apps/users");
    } else {
      toast({
        title: "Error in Service",
        description: resp.usermessage,
      });
    }

    SetLoaded(true);
  };



  return (
    <div className="p-4 bg-white h-screen">
      <h1 className="font-bold text-2xl text-red-800">Create User</h1>
      <div className="mb-4">
        <BreadcrumbPart />
      </div>
      <Separator className="my-4" />
      <div className="flex flex-row flex-nowrap my-2 gap-1">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          // variant={"outline"}
          onClick={() => Save()}
          title="Save user"
        >
          <Icon
            icon="carbon:save"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
      </div>

      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row items-center">
          <Label className="w-48">User Name</Label>
          <Input
            className="bg-gray-100 w-64 lowercase"
            placeholder="?"
            value={PageObject.UserName}
            maxLength={50}
            disabled={true}
          />
        </div>
        <div className="flex flex-row items-center">
          <Label className="w-48">Full Name</Label>
          <Input
            className="bg-white w-96"
            placeholder="?"
            value={PageObject.Ename}
            maxLength={60}
            onChange={(e) => PropChanged("Ename", e.target.value)}
          ></Input>
        </div>

        {/* Tab Start */}
        <Tabs defaultValue="properties" className="md:w-2/3 mt-5">
          <TabsList>
            <TabsTrigger value="properties">Properties</TabsTrigger>
            <TabsTrigger value="roles">Roles</TabsTrigger>
          </TabsList>
          {/* Tab 1 : Properties */}
          <TabsContent value="properties" className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Employee Id</Label>
              <Input
                className="bg-white-100 w-64"
                placeholder=""
                type="number"
                onChange={(e) => PropChanged("Pernr", e.target.value)}
                disabled={false}
                value={PageObject.Pernr}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Applicant Id</Label>
              <Input
                className="bg-white-100 w-64"
                placeholder=""
                type="number"
                onChange={(e) => PropChanged("Aplno", e.target.value)}
                disabled={false}
                value={PageObject.Aplno}
              ></Input>
            </div>
             {/* Email */}
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Email</Label>
              <Input
                className="bg-white w-64"
                placeholder=""
                value={PageObject.Email}
                maxLength={50}
                onChange={(e) => PropChanged("Email", e.target.value)}
              ></Input>
            </div>
            {/* Phone */}
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Phone</Label>
              <Input
                className="bg-white w-64"
                placeholder=""
                value={PageObject.Phone}
                maxLength={50}
                onChange={(e) => PropChanged("Phone", e.target.value)}
              ></Input>
            </div>
            {/* MFA */}
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">MFA</Label>
              <Checkbox
                checked={PageObject.MFA}
                disabled={false}
                onCheckedChange={(checked) =>
                PropChanged("MFA", checked)
                }
                />
            </div>
            {/* Locked */}
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Locked</Label>
              <Checkbox
                checked={PageObject.Locked}
                disabled={false}
                onCheckedChange={(checked) =>
                PropChanged("Locked", checked)
                }
                />
            </div>
            {/* <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Initial Passw</Label>
              <Input
                className="bg-white-100 w-64"
                placeholder=""
                type="password"
                onChange={(e) => PropChanged("Password", e.target.value)}
                disabled={false}
                value={PageObject.Password}
              ></Input>
            </div> */}
          </TabsContent>
          {/* Tab 2 : Fields */}
          <TabsContent value="roles" className="flex flex-col gap-2">
            <table className="table-auto">
              <thead>
                <tr className="font-unbold text-left">
                  <th>Role Name</th>
                  <th>Definition</th>
                </tr>
              </thead>
              <tbody>
                {PageObject.Roles.map((col, index) => (
                  <tr>


                    <td className="w-48">
                      <SearchHelp
                        SHName="ROLENAME"
                        
                        onChange={(e) =>
                          RoleListChanged("RoleName", index, e.target.value)
                        }
                        value={col.RoleName}
                        OnSelect={(x) => OnRoleNameSelect(x, index)}
                        onBlur={(e) => OnRoleNameBlur(e.target.value, index)}
                      />
                    </td>
                    <td className="">
                      {/* Role Definition */}
                      <Input
                        className="bg-gray-100"
                        placeholder=""
                        value={col.Definition}
                        disabled={false}
                      ></Input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabsContent>
        </Tabs>
        <Toaster />
      </div>
    </div>
  );
}

export default CreateUserDetail;
