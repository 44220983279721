import React, { Children, PropsWithChildren, useEffect, useState } from "react";
import clsx from "clsx";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/shadcn/ui/select";
import { ModulItem } from "@/models/Types";
import ModuleService from "@/services/nav/ModuleService";

interface IDevClass {
  Value: string;
  Disabled?: boolean;
  OnSelect?: (_value: string) => void;
}
const DevClass: React.FC<PropsWithChildren<IDevClass>> = (props) => {


  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<ModulItem[]>([]);
  //
  useEffect(() => {
    setLoaded(false);
    fetch();
    setLoaded(true);
  }, []);

  const fetch = async () => {
      const resp = await ModuleService.get();
      if (resp) {
        if (resp.data) {
          setData(resp.data);
        } else {
          debugger;
        }
      }
    
  };


  return (
    <>
    <Select
    
        value={props.Value}
        onValueChange={(e) => props.OnSelect ? props.OnSelect(e) : null}
        disabled={props.Disabled}
        
        >
        <SelectTrigger className="w-[240px] bg-white" disabled={props.Disabled}>
            <SelectValue placeholder="Select dev. class" />
        </SelectTrigger>
        <SelectContent>
        {
                data.map((x) => {
                  return <SelectItem value={x.modulecode}>{x.title}</SelectItem>
                })
              }
        </SelectContent>
    </Select>
    </>
  );
};

export default DevClass;