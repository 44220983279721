import { ContactFormModel } from "@/components/ContactComp";
import { HRDEVSHOPFormModel } from "@/components/HRDEVSHOPForm";
import DIContainer from "@/models/DIContainer";
import IResponseHelper from "@/models/response/IResponseHelper";

class ContactFormService {
  private httpClient = DIContainer.HttpJsonClient;

  post = async (cForm : ContactFormModel) => {
    const response = await this.httpClient.post<IResponseHelper<boolean>>(
      `/api/v1/contactform`,
      cForm
    );

    return response;
  };

  postHRDevShopForm = async (cForm : HRDEVSHOPFormModel) => {
    const response = await this.httpClient.post<IResponseHelper<boolean>>(
      `/api/v1/contactform2`,
      cForm
    );

    return response;
  };
}

export default new ContactFormService();
