import { Button } from "@/shadcn/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/shadcn/ui/card";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "@/shadcn/ui/dropdown-menu";
import { Separator } from "@/shadcn/ui/separator";
import { Icon } from "@iconify/react";
import clsx from "clsx";

export interface IOrcCardFigure {
  FieldName: string;
  ClassName?: string;
  Title?: string;
  Value?: string;
  IconName?: string;
  IconClassName?: string;
  ParentFieldName?: string;
}

export default function OrcCardFigure(props: IOrcCardFigure) {
  return (
    <>
          <Card className={props.ClassName}>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">{props.Title}</CardTitle>
              <Icon icon={props.IconName ? props.IconName : "mdi:chart-line"} className={clsx("w-6 h-6 bottom-3 left-4",props.IconClassName)} />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{props.Value}</div>
              {/* <p className="text-xs text-muted-foreground">
                +19% from last month
              </p> */}
            </CardContent>
          </Card>
    </>
  );
}
