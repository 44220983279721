import { useTranslation } from "react-i18next";
import catalog from "../data/hrmodules.json";
import PageHeader from "./PageHeader";
import { Icon } from "@iconify/react";

function ProductComp() {
  const { t, i18n } = useTranslation("", { keyPrefix: "ProductComp" });

  return (
<section className="px-8 md:px-24 mt-24 mb-6">
  <div className="flex flex-wrap -m-4 mb-32">
    <div className="w-full md:w-1/2 p-4">
      <h1 className="tracking-tight font-heading text-6xl md:text-7xl font-medium">{t("OrchestraHRModules")}</h1>
    </div>
    <div className="w-full md:w-1/2 p-4">
      <p className="text-gray-900 tracking-tight mb-8 max-w-lg">{t("Word1")}.</p>
      <a href="https://blog.orchestrahr.com/orchestrahr-loading-14c0ba76754e" className="group inline-block" target="_blank">
        <div className="flex items-center gap-2">
          <span className="font-semibold text-black group-hover:text-opacity-80 transition duration-200">{t("ReadArticle")}</span>
          <div className="text-black group-hover:text-opacity-80 transition duration-200">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
              <path d="M6.5 3.33341L11.1667 8.00008L6.5 12.6667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div className="flex flex-wrap -m-4">
    <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
      <p className="font-heading mb-8 tracking-tight text-red-800">{t("CoreModules")}</p>
      <ul className="flex flex-col gap-4">
        <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("OrganizationManagement")}</a></li>
        <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("PersonnelAdministration")}</a></li>
        <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("NegativeTimeManagement")}</a></li>
        <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("OrganizationVisualizer")}</a></li>
      </ul>
    </div>
    <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
      <p className="font-heading mb-8 tracking-tight text-red-800">{t("OnBoarding")}</p>
      <ul className="flex flex-col gap-4">
        {/* <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("Recruitment")}</a></li> */}
        <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("OnBoarding")}</a></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
      <p className="font-heading mb-8 tracking-tight text-red-900">{t("EmployeeCycles")}</p>
      <ul className="flex flex-col gap-4">
        <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("PositiveTimeManagement")}</a></li>
        <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("Payroll")}</a></li>
        <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("PerformanceManagement")}</a></li>
        <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("Survey")}</a></li>
      </ul>
    </div>
    <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
      <ul className="flex flex-col gap-4">
        <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("TravelManagement")}</a></li>
        <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("CompensationManagement")}</a></li>
        <li><a href="#" className="tracking-tight text-black hover:text-opacity-80 font-medium transition duration-200">{t("CustomWorkflows")}</a></li>
        <li></li>
      </ul>
    </div>
  </div>
</section>
  );
}

export default ProductComp;
