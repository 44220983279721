import DIContainer from "@/models/DIContainer";
import IResponseHelper from "@/models/response/IResponseHelper";
import { Domain } from "@/models/datadictionary/Domain";
import { Role } from "@/models/idm/Role";

class RoleService {
  private httpClient = DIContainer.HttpJsonClient;

  post = async (data: Role) => {
    const response = await this.httpClient.post<IResponseHelper<Role>>(
      "/api/v1/idm/role",
      data
    );

    return response;
  };


  put = async (data: Role) => {
    const response = await this.httpClient.put<IResponseHelper<Role>>(
      "/api/v1/idm/role",
      data
    );

    return response;
  };

  isExist = async (rolename: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/idm/role/isexist?rolename=" + rolename
    );

    return response.data;
  };

  getAll = async () => {
    const response = await this.httpClient.get<IResponseHelper<Role[]>>(
      "/api/v1/idm/role/getAll"
    );

    return response.data;
  };

  getByName = async (rolename: string) => {
    const response = await this.httpClient.get<IResponseHelper<Role>>(
      "/api/v1/idm/role/getByName?rolename=" + rolename
    );

    return response.data;
  };

  delete = async (rolename: string) => {
    const response = await this.httpClient.delete<IResponseHelper<Boolean>>(
      "/api/v1/idm/role/delete?DomName=" + rolename
    );

    return response.data;
  };
}

export default new RoleService();
