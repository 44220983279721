import { Icon } from "@iconify/react";
import React from "react";

function MyInboxTile() {
  return (
    <div className="relative w-52 h-52 p-4 bg-blue-50 text-foreground hover:bg-opacity-35 hover:cursor-pointer transition duration-200 hover:scale-110">
      My Inbox
      <p className="text-gray-400">All Items</p>
      <div className="absolute w-44 h-16 bottom-4 flex flex-row justify-between">
        <Icon
          icon="mingcute:inbox-line"
          className="w-12 h-12 left-2 text-blue-800 items-baseline bottom-0"
          width="18"
          height="18"
        />
        <p className="text-blue-700 text-6xl items-baseline">13</p>
      </div>
    </div>
  );
}

export default MyInboxTile;
