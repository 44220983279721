import { Input } from "@/shadcn/ui/input";
import { Label } from "@/shadcn/ui/label";
import React, { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/shadcn/ui/select";
import { Domain, FixValue } from "../../../../models/datadictionary/Domain";
// import { Button } from "@/shadcn/ui/button";
import DomainService from "@/services/dd/DomainService";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/shadcn/ui/button";
import { Toaster } from "@/shadcn/ui/toaster";
import { toast } from "@/shadcn/ui/use-toast";
import { Icon } from "@iconify/react";
import DevClass from "../../comps/DevClass";
// import { useSearchParams } from "react-router-dom";
//
//
function ChangeDomainDetail() {
  //
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  // let _domname = searchParams.get("domname");
  const routeParams = useParams();
  // console.log("routeParams = ", JSON.stringify(routeParams));
  const BreadcrumbPart = () => (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/tiles/develop");
            }}
          >
            Developments
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/ddic");
            }}
          >
            Data Dictionary
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Change Domain</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );

  let InitialPageObject: Domain = {
    DomainName: routeParams.domname ? routeParams.domname : "",
    Status: "P",
    DataType: "CHAR",
    Description: "",
    LastChanged: "HRSP",
    LastChangedOn: "20240101",
    Package: "ORC_HR",
    ValueTable: "",
    SourceName: "",
    TextTable: "",
    TextField: "",
    TextClause: "",
    FixValues: [
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
    ],
  };

  const [PageObject, SetPage] = useState(InitialPageObject);

  useEffect(() => {
    setLoaded(false);
    fetch();
    setLoaded(true);
  }, []);

  const fetch = async () => {
    if (routeParams.domname) {
      const resp = await DomainService.getByName(routeParams.domname);
      if (resp) {
        if (resp) {
          SetPage(resp);

          for (let index = 0; index <11; index++) {
            resp.FixValues.push({
              Value: "",
              Text: ""
            });
          }


        } else {
          debugger;
        }
      }
    }
  };

  const PropChanged = (fname: string, val: any) => {
    if (fname == "DomName") {
      let newobj: Domain = { ...PageObject, DomainName: val };
      SetPage(newobj);
    } else if (fname == "Description") {
      let newobj: Domain = { ...PageObject, Description: val };
      SetPage(newobj);
    } else if (fname == "Package") {
      let newobj: Domain = { ...PageObject, Package: val };
      SetPage(newobj);
    } else if (fname == "DataType") {
      let newobj: Domain = { ...PageObject, DataType: val };
      SetPage(newobj);
    } else if (fname == "MaxLength") {
      let newobj: Domain = { ...PageObject, MaxLength: val };
      SetPage(newobj);
    } else if (fname == "ValueTable") {
      let newobj: Domain = { ...PageObject, ValueTable: val };
      SetPage(newobj);
    } else if (fname == "SourceName") {
      let newobj: Domain = { ...PageObject, SourceName: val };
      SetPage(newobj);
    } else if (fname == "TextTable") {
      let newobj: Domain = { ...PageObject, TextTable: val };
      SetPage(newobj);
    } else if (fname == "TextField") {
      let newobj: Domain = { ...PageObject, TextField: val };
      SetPage(newobj);
    } else if (fname == "TextClause") {
      let newobj: Domain = { ...PageObject, TextClause: val };
      SetPage(newobj);
    }
  };

  const FixValueChanged = (item: FixValue, value: string) => {
    let newobj: Domain = { ...PageObject };

    var index: number = newobj.FixValues.indexOf(item);
    newobj.FixValues[index].Value = value;

    SetPage(newobj);
  };
  const FixValueTextChanged = (item: FixValue, value: string) => {
    let newobj: Domain = { ...PageObject };

    var index: number = newobj.FixValues.indexOf(item);
    newobj.FixValues[index].Text = value;

    SetPage(newobj);
  };

  const AddFixRow = (index: number) => {
    debugger;
    let newobj: Domain = { ...PageObject };

    const newFixValues = [
      ...newobj.FixValues.slice(0, index),
      {
        Value: "",
        Text: ""
      },
      ...newobj.FixValues.slice(index)
  ];

    newobj.FixValues = newFixValues;

    SetPage(newobj);
  }

  const RemoveFixRow = (index: number) => {

    let newobj: Domain = { ...PageObject };

    const newFixValues = [
      ...newobj.FixValues
    ];

    newFixValues.splice(index, 1);

    newobj.FixValues = newFixValues;

    SetPage(newobj);
  }

  const Save = async () => {
    debugger;
    setLoaded(false);
    //validation starts
    if (PageObject.Description.trim().length == 0) {
      toast({
        title: "Error in Service",
        description: "Please enter definition for domain",
      });
      return;
    }
    if (PageObject.DataType.trim().length == 0) {
      toast({
        title: "Error in Service",
        description: "Please enter data type for domain",
      });
      return;
    } else {
      if (
        (PageObject.DataType == "CHAR" || PageObject.DataType == "NUMC") &&
        PageObject.MaxLength == null
      ) {
        toast({
          title: "Error in Service",
          description: "Please enter length for char data",
        });
        return;
      }
    }
    //validation ends
    const resp = await DomainService.put(PageObject);
    if (resp.data) {
      SetPage(resp.data);
      //navigate("/apps/ddic");
      toast({
        title: "Successfull:)",
        description: "Domain activated and created in database.",
      });
    } else {
      toast({
        title: "Error in Service",
        description: resp.usermessage,
      });
      return;
    }
    setLoaded(true);
  };

     /*
    Saves and activates
  */
    const Activate = async () => {
      debugger;
      setLoaded(false);

      const resp = await DomainService.activate(PageObject);
      debugger;
      if (resp.data) {
        //navigate("/apps/ddic");
        let newSaveobj: Domain = { ...PageObject, Status: resp.data.Status };
        SetPage(newSaveobj);
        toast({
          title: "Successfull:)",
          description: "Domain activated and created in database.",
        });
      } else {
        toast({
          title: "Error in Service",
          description: resp.usermessage,
        });
      }
      setLoaded(true);
    };


  return (
    <div className="p-4 bg-white h-screen">
      <h1 className="font-bold text-2xl text-red-800">Change Domain</h1>
      <div className="mb-4">
        <BreadcrumbPart />
      </div>
      <Separator className="my-4" />
      <div className="flex flex-row flex-nowrap my-2 gap-2">
        <button
            className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
            onClick={() => Save()}
            title="Save Domain"
          >
            <Icon
              icon="carbon:save"
              width="18"
              height="18"
              className="text-black"
            />
          </button>
          <button
            className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
            onClick={() => Activate()}
            title="Activate Domain"
          >
          <Icon
            icon="line-md:speedometer-loop"
            width="18"
            height="18"
            className="text-black"
          />
          </button>
      </div>

      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row items-center">
          <Label className="w-48">Domain Name</Label>
          <Input
            className="bg-gray-100 w-40 uppercase"
            placeholder="?"
            value={PageObject.DomainName}
            maxLength={50}
            disabled={true}
          />
                    <Input
            className="bg-gray-100 w-10 ms-1"
            value={PageObject.Status}
            maxLength={1}
            disabled={true}
          ></Input>
        </div>
        <div className="flex flex-row items-center">
          <Label className="w-48">Short Description</Label>
          <Input
            className="w-96 bg-white"
            placeholder="?"
            value={PageObject.Description}
            maxLength={60}
            onChange={(e) => PropChanged("Description", e.target.value)}
          ></Input>
        </div>

        {/* Tab Start */}
        <Tabs defaultValue="properties" className="w-[400px] mt-5">
          <TabsList>
            <TabsTrigger value="properties">Properties</TabsTrigger>
            <TabsTrigger value="definition">Definition</TabsTrigger>
            <TabsTrigger value="values">Table Values</TabsTrigger>
            <TabsTrigger value="fixvalues">Fix Values</TabsTrigger>
          </TabsList>
          {/* Tab 1 : Properties */}
          <TabsContent value="properties" className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed By</Label>
              <Input
                className="bg-gray-100 w-64 lowercase"
                placeholder=""
                disabled={true}
                value={PageObject.LastChanged}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed On</Label>
              <Input
                className="bg-gray-100 w-24"
                placeholder="17.02.1998"
                disabled={true}
                value={PageObject.LastChangedOn}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Development Class</Label>
              <DevClass Value={PageObject.Package} OnSelect={(e) => PropChanged("Package", e)}></DevClass>
            </div>
          </TabsContent>
          {/* Tab 2 : Definition */}
          <TabsContent value="definition" className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Data Type</Label>
              <Select
                value={PageObject.DataType}
                onValueChange={(e) => PropChanged("DataType", e)}
              >
                <SelectTrigger className="w-[180px] bg-white">
                  <SelectValue placeholder="Select data type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="ACCP"> ACCP </SelectItem>
                    <SelectItem value="BOOL"> BOOL </SelectItem>
                    <SelectItem value="BYTE"> BYTE </SelectItem>
                    <SelectItem value="CHAR"> CHAR </SelectItem>
                    <SelectItem value="CUKY"> CUKY </SelectItem>
                    <SelectItem value="CURR"> CURR </SelectItem>
                    <SelectItem value="DATS"> DATS </SelectItem>
                    <SelectItem value="DECM"> DECM </SelectItem>
                    <SelectItem value="INT4"> INT4 </SelectItem>
                    <SelectItem value="JSON"> JSON </SelectItem>
                    <SelectItem value="CLNT"> CLNT </SelectItem>
                    <SelectItem value="NUMC"> NUMC </SelectItem>
                    <SelectItem value="QUAN"> QUAN </SelectItem>
                    <SelectItem value="TIMS"> TIMS </SelectItem>
                    <SelectItem value="UNIT"> UNIT </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Length</Label>
              <Input
                className="bg-white w-14"
                value={PageObject.MaxLength}
                onChange={(e) => PropChanged("MaxLength", e.target.value)}
                type="number"
              ></Input>
            </div>
          </TabsContent>
          {/* Tab 3 : Value Range */}
          <TabsContent value="values" className="flex flex-col gap-2">
          <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Source System</Label>
              <Input
                className="bg-white w-24 uppercase"
                maxLength={50}
                value={PageObject.SourceName}
                onChange={(e) => PropChanged("SourceName", e.target.value)}
                placeholder=""
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Value Table</Label>
              <Input
                className="bg-white w-32 uppercase"
                maxLength={50}
                value={PageObject.ValueTable}
                onChange={(e) => PropChanged("ValueTable", e.target.value)}
                placeholder=""
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Text Table</Label>
              <Input
                className="bg-white w-32 uppercase"
                maxLength={50}
                value={PageObject.TextTable}
                onChange={(e) => PropChanged("TextTable", e.target.value)}
                placeholder=""
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Text Field</Label>
              <Input
                className="bg-white w-32 uppercase"
                maxLength={50}
                value={PageObject.TextField}
                onChange={(e) => PropChanged("TextField", e.target.value)}
                placeholder=""
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Text Clause</Label>
              <Input
                className="bg-white w-64 uppercase"
                maxLength={200}
                value={PageObject.TextClause}
                onChange={(e) => PropChanged("TextClause", e.target.value)}
                placeholder=""
              ></Input>
            </div>

          </TabsContent>
          {/* Tab 4 : Fix Values */}
          <TabsContent value="fixvalues" className="flex flex-col gap-2">
            {PageObject.FixValues.map((item, index) => (
              <div className="flex flex-row items-center gap-1">
                <div className="flex flex-row gap-0 items-center justify-center">
                  <div className="h-3 hover:cursor-pointer" onClick={() => AddFixRow(index)}>  <Icon className="text-green-600" icon="mingcute:add-circle-fill" width={22} height={22} /></div>
                  <div className="h-3 hover:cursor-pointer" onClick={() => RemoveFixRow(index)}><Icon className="text-red-600" icon="mingcute:minus-circle-fill" width={22} height={22} /></div>
                  {/* <div className="h-3">+</div> */}
                </div>
                <Input
                  className="bg-white w-28"
                  placeholder="Value"
                  value={item.Value}
                  maxLength={PageObject.MaxLength ? PageObject.MaxLength : 50}
                  onChange={(e) => FixValueChanged(item, e.target.value)}
                ></Input>
                <Input
                  className="bg-white w-80"
                  placeholder="Text"
                  value={item.Text}
                  maxLength={100}
                  onChange={(e) => FixValueTextChanged(item, e.target.value)}
                ></Input>
              </div>
            ))}
          </TabsContent>
        </Tabs>
      </div>
      <Toaster />
      {/* {JSON.stringify(PageObject)} */}
    </div>
  );
}

export default ChangeDomainDetail;
