import DIContainer from "@/models/DIContainer";
import IResponseHelper from "@/models/response/IResponseHelper";
import { FieldTypeModel } from "@/models/uipage/FieldTypeModel";

class FieldTypeService {
  private httpClient = DIContainer.HttpJsonClient;

  get = async () => {
    const response = await this.httpClient.get<IResponseHelper<FieldTypeModel[]>>(
      `/api/v1/ui/ftype/get`
    );

    return response;
  };
}

export default new FieldTypeService();
