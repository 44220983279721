import { Avatar, AvatarImage } from "@/shadcn/ui/avatar";
import clsx from "clsx";


export interface ISomePeople {
  FieldName: string;
  Title: string;
  SubTitle?: string;
  ClassName?: string;
  Data?: any;
  ParentFieldName: string;
}

function SomePeople(props: ISomePeople) {


  return (
    <div
      key={props.FieldName}
      className={clsx(
        "relative w-36 h-36 md:w-72 md:h-44 p-4 text-foreground rounded-sm",
        props.ClassName
      )}
    >
      <p className="font-normal md:text-lg">{props.Title}</p>
      <p className="hidden md:flex font-light text-xs mb-2">
        {" "}
        {props.SubTitle}
      </p>
      {
        props.Data && 
        <div className="flex flex-row gap-1 flex-wrap">
          {
            props.Data.map((item: any, index: number) => (
              <Avatar className="hover:scale-125 hover:cursor-pointer duration-200 transition">
                <AvatarImage src={item.src} title={item.text} />
              </Avatar>
            ))
          }
          </div>
        }
        {
          props.Data == null &&
          <div className="flex flex-row gap-1 flex-wrap">
            <Avatar className="hover:scale-125 hover:cursor-pointer duration-200 transition">  
              <AvatarImage src="https://randomuser.me/api/portraits/women/75.jpg" title="Natalie Birgman"  />
            </Avatar>
            <Avatar className="hover:scale-125 hover:cursor-pointer duration-200 transition">  
              <AvatarImage src="https://randomuser.me/api/portraits/women/74.jpg" title="Ingrid Chelsea"  />
            </Avatar>
            <Avatar className="hover:scale-125 hover:cursor-pointer duration-200 transition">  
              <AvatarImage src="https://randomuser.me/api/portraits/women/64.jpg" title="Jamie Chelsea" />
            </Avatar>
            <Avatar className="hover:scale-125 hover:cursor-pointer duration-200 transition">  
              <AvatarImage src="https://randomuser.me/api/portraits/men/21.jpg" title="John Breau" />
            </Avatar>
            <Avatar className="hover:scale-125 hover:cursor-pointer duration-200 transition">  
              <AvatarImage src="https://randomuser.me/api/portraits/men/14.jpg" title="Brian Chelsea" />
            </Avatar>
          </div>
        }
    </div>
  );
}

export default SomePeople;
