import React from "react";
import ContactForm from "../components/ContactForm";
import ContactComp from "@/components/ContactComp";
import TopBrands from "@/components/TopBrands";

function Contact() {
  return (
    <section className="w-full min-h-[500px]">
      <ContactComp />
      <TopBrands />
      {/* <ContactForm /> */}
    </section>
  );
}

export default Contact;
