import React from "react";
import { CurrentDomain } from "../models/Enums";

// export enum CurrentDomain {
//   orchestraplus = "orchestra.plus",
//   orchestrahr = "orchestrahr.com",
//   hrspcomtr = "hrsp.com.tr",
//   localhost = "localhost",
// }

let DefaultDomain: CurrentDomain = process.env
  .REACT_APP_DEVDOMAIN as CurrentDomain;

export class DomainHelper {
  static SubDomain: string =
    window.location.hostname.split(".").length == 2 &&
    window.location.hostname.indexOf("localhost") != -1
      ? window.location.hostname.split(".")[0]
      : window.location.hostname.split(".").length == 3 &&
        window.location.hostname.indexOf("localhost") == -1
      ? window.location.hostname.split(".")[0]
      : "";

  static HasSubDomain: boolean =
    window.location.hostname.split(".").length >= 2 ? true : false;

  static Domain: string =
    window.location.hostname == "localhost"
      ? DefaultDomain
      : (window.location.hostname);

  // static DomainTitle: string =
  //   this.Domain == CurrentDomain.orchestraplus ? "Orchestra+" : "OrchestraHR";
}
