import DIContainer from "@/models/DIContainer";
import { OrcViewResult } from "@/models/OrcViewResult";
import IResponseHelper from "@/models/response/IResponseHelper";
import { FieldTypeModel } from "@/models/uipage/FieldTypeModel";

class ViewDataService {
  private httpClient = DIContainer.HttpJsonClient;

  get = async (viewname: string) => {
    const response = await this.httpClient.get<IResponseHelper<OrcViewResult>>(
      `/api/v1/ui/viewdata/get?viewname=${viewname}`
    );

    return response;
  };

  post = async (View: OrcViewResult) => {
    const response = await this.httpClient.post<IResponseHelper<OrcViewResult>>(
      `/api/v1/ui/viewdata`, View
    );

    return response;
  };
}

export default new ViewDataService();
