import React, { PropsWithChildren, useState } from "react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/shadcn/ui/select";

interface IPageTypeSelect {
  Value: number;
  Disabled?: boolean;
  OnSelect?: (_value: string) => void;
}
const PageTypeSelect: React.FC<PropsWithChildren<IPageTypeSelect>> = (props) => {
  
  return (
    <>
    <Select
        value={props.Value.toString()}
        onValueChange={(e) => props.OnSelect ? props.OnSelect(e) : null}
        disabled={props.Disabled}
        >
        <SelectTrigger className="w-[200px] bg-white" disabled={props.Disabled}>
            <SelectValue placeholder="Select Type" />
        </SelectTrigger>
        <SelectContent>
            <SelectGroup>
            <SelectItem value="0">Screen</SelectItem>
            <SelectItem value="1">Workflow</SelectItem>
            </SelectGroup>
        </SelectContent>
    </Select>
    </>
  );
};

export default PageTypeSelect;