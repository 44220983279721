import { Input } from "@/shadcn/ui/input";
import { Label } from "@/shadcn/ui/label";
import React, { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/shadcn/ui/select";
import { Domain } from "../../../../models/datadictionary/Domain";
// import { Button } from "@/shadcn/ui/button";
import DomainService from "@/services/dd/DomainService";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { toast } from "@/shadcn/ui/use-toast";
import { Toaster } from "@/shadcn/ui/toaster";
import DevClass from "../../comps/DevClass";
// import { useSearchParams } from "react-router-dom";
//
//
function DeleteDomainDetail() {
  //
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  // let _domname = searchParams.get("domname");
  const routeParams = useParams();
  // console.log("routeParams = ", JSON.stringify(routeParams));
  const BreadcrumbPart = () => (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/tiles/develop");
            }}
          >
            Developments
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/ddic");
            }}
          >
            Data Dictionary
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Delete Domain</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );

  let InitialPageObject: Domain = {
    DomainName: routeParams.domname ? routeParams.domname : "",
    Status: "P",
    DataType: "CHAR",
    Description: "",
    LastChanged: "HRSP",
    LastChangedOn: "20240101",
    Package: "ORC_HR",
    ValueTable: "",
    SourceName: "",
    TextTable: "",
    TextField: "",
    TextClause: "",
    FixValues: [
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
      {
        Value: "",
        Text: "",
      },
    ],
  };

  const [PageObject, SetPage] = useState(InitialPageObject);

  useEffect(() => {
    setLoaded(false);
    fetch();
    setLoaded(true);
  }, []);

  const fetch = async () => {
    if (routeParams.domname) {
      const resp = await DomainService.getByName(routeParams.domname);
      if (resp) {
        if (resp) {
          SetPage(resp);
        } else {
          //toast.success(resp.Error); // Displays a success message
          debugger;
          // SetPage(resp.error);
        }
      }
    }
  };

  const Delete = async () => {
    debugger;
    setLoaded(false);
    //validation starts
    if (routeParams.domname) {
      const resp = await DomainService.delete(routeParams.domname);
      if (resp) {
        navigate("/apps/ddic");
      }
      else
      {
        toast({
          title: "Domain cannot be deleted, it is used in data elements",
        });
        debugger;
      }
    }

    setLoaded(true);
  };

  return (
    <div className="p-4 bg-white h-screen">
      <h1 className="font-bold text-2xl text-red-800">Delete Domain</h1>
      <div className="mb-4">
        <BreadcrumbPart />
      </div>
      <Separator className="my-4" />
      {/* Commands Start */}
      <div className="flex flex-row flex-nowrap my-2 gap-1 mb-10">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Delete()}
          title="Delete"
        >
          <Icon
            icon="material-symbols-light:delete-outline"
            width="18"
            height="18"
            className="text-black bg-white"
          />
        </button>
      </div>
      {/* Commands End */}
      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row items-center">
          <Label className="w-48">Domain Name</Label>
          <Input
            className="bg-gray-100 w-40 uppercase"
            placeholder="?"
            value={PageObject.DomainName}
            maxLength={50}
            disabled={true}
          />
                    <Input
            className="bg-gray-100 w-10 ms-1"
            value={PageObject.Status}
            maxLength={1}
            disabled={true}
          ></Input>
        </div>
        <div className="flex flex-row items-center">
          <Label className="w-48">Short Description</Label>
          <Input
            className="w-96 bg-gray-100"
            placeholder="?"
            value={PageObject.Description}
            disabled={true}
            maxLength={60}
          ></Input>
        </div>

        {/* Tab Start */}
        <Tabs defaultValue="properties" className="w-[400px] mt-5">
          <TabsList>
            <TabsTrigger value="properties">Properties</TabsTrigger>
            <TabsTrigger value="definition">Definition</TabsTrigger>
            <TabsTrigger value="values">Table Values</TabsTrigger>
            <TabsTrigger value="fixvalues">Fix Values</TabsTrigger>
          </TabsList>
          {/* Tab 1 : Properties */}
          <TabsContent value="properties" className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed By</Label>
              <Input
                className="bg-gray-100 w-64 lowercase"
                placeholder=""
                disabled={true}
                value={PageObject.LastChanged}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed On</Label>
              <Input
                className="bg-gray-100 w-24"
                placeholder=""
                disabled={true}
                value={PageObject.LastChangedOn}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Development Class</Label>
              <DevClass Value={PageObject.Package} Disabled={true}></DevClass>
            </div>
          </TabsContent>
          {/* Tab 2 : Definition */}
          <TabsContent value="definition" className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Data Type</Label>
              <Select value={PageObject.DataType} disabled={true}>
                <SelectTrigger className="w-[180px] bg-gray-100">
                  <SelectValue placeholder="Select data type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="ACCP"> ACCP </SelectItem>
                    <SelectItem value="BOOL"> BOOL </SelectItem>
                    <SelectItem value="BYTE"> BYTE </SelectItem>
                    <SelectItem value="CHAR"> CHAR </SelectItem>
                    <SelectItem value="CUKY"> CUKY </SelectItem>
                    <SelectItem value="CURR"> CURR </SelectItem>
                    <SelectItem value="DATS"> DATS </SelectItem>
                    <SelectItem value="DECM"> DECM </SelectItem>
                    <SelectItem value="INT4"> INT4 </SelectItem>
                    <SelectItem value="JSON"> JSON </SelectItem>
                    <SelectItem value="CLNT"> CLNT </SelectItem>
                    <SelectItem value="NUMC"> NUMC </SelectItem>
                    <SelectItem value="QUAN"> QUAN </SelectItem>
                    <SelectItem value="TIMS"> TIMS </SelectItem>
                    <SelectItem value="UNIT"> UNIT </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Length</Label>
              <Input
                className="bg-gray-100 w-14"
                value={PageObject.MaxLength}
                disabled={true}
                type="number"
              ></Input>
            </div>
          </TabsContent>
          {/* Tab 3 : Table Values */}
          <TabsContent value="values" className="flex flex-col gap-2">
          <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Source Name</Label>
              <Input
                className="bg-gray-100 w-64 uppercase"
                maxLength={50}
                value={PageObject.SourceName}
                placeholder=""
                disabled={true}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Value Table</Label>
              <Input
                className="bg-gray-100 w-64 uppercase"
                maxLength={50}
                value={PageObject.ValueTable}
                placeholder=""
                disabled={true}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Text Table</Label>
              <Input
                className="bg-gray-100 w-64 uppercase"
                maxLength={50}
                value={PageObject.TextTable}
                placeholder=""
                disabled={true}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Text Field</Label>
              <Input
                className="bg-gray-100 w-64 uppercase"
                maxLength={50}
                value={PageObject.TextField}
                placeholder=""
                disabled={true}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Text Clause</Label>
              <Input
                className="bg-gray-100 w-96 uppercase"
                maxLength={200}
                value={PageObject.TextClause}
                placeholder=""
                disabled={true}
              ></Input>
            </div>
          </TabsContent>
          {/* Tab 4 : Fix Values */}
          <TabsContent value="fixvalues" className="flex flex-col gap-2">
            {PageObject.FixValues.map((item, index) => (
              <div className="flex flex-row items-center gap-1">
                <Input
                  className="bg-gray-100 w-28"
                  placeholder="Value"
                  value={item.Value}
                  disabled={true}
                ></Input>
                <Input
                  className="bg-gray-100 w-80"
                  placeholder="Text"
                  value={item.Text}
                  disabled={true}
                ></Input>
              </div>
            ))}
          </TabsContent>
        </Tabs>
      </div>
      <Toaster />
      {/* {JSON.stringify(PageObject)} */}
    </div>
  );
}

export default DeleteDomainDetail;
