import { useTranslation } from "react-i18next";
import catalog from "../data/hrmodules.json";
import PageHeader from "./PageHeader";
import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { toast } from "@/shadcn/ui/use-toast";
import ClientExistCheckService from "@/services/clientexist/ClientExistCheckService";
import { Input } from "@/shadcn/ui/input";
import { Toaster } from "@/shadcn/ui/toaster";
import { ErrorField } from "./ContactComp";
import clsx from "clsx";
import ContactFormService from "@/services/contactform/ContactFormService";
import { useNavigate } from "react-router-dom";
import CompanyFormService from "@/services/companyform/CompanyFormService";

export class RegisterCompanyModel {
  EName : string = "";
  EMail : string = "";
  CompanyTitle : string = "";

}


function ClientRegisterComp() {

  const { t, i18n } = useTranslation("", { keyPrefix: "ClientRegisterComp" });
  const[enabled, setEnabled] = React.useState(true);
  const[subdomain, setSubDomain] = React.useState("");
  const navigate = useNavigate();
  const[hostname, setHostName] = React.useState(process.env.REACT_APP_HOSTNAME);

  const[scheme, setSchema] = React.useState("http://");

  let ContactFormObject : RegisterCompanyModel = { EName : "", EMail:"", CompanyTitle:""};
  let ErrorFieldArray : ErrorField[] = [];

  const[contactData, setContactData] = useState<RegisterCompanyModel>(ContactFormObject);
  const[errorFields, setErrorField] = useState<ErrorField[]>(ErrorFieldArray);

  const ValidateForm = () => {
    
    let errFields: ErrorField[] = [];
    const tagRegex = /<.+?>/g;   
    //
    if(contactData.EName.length==0)
      errFields.push({ FieldName : "EName" });
    else if (tagRegex.test(contactData.EName)) // Check if email matches the regex pattern
      errFields.push({ FieldName : "EName", Error: "Forbidden characted in text" }); // Add specific error for invalid email format
    else
      errFields = errFields.filter(o=>o.FieldName != "EName");


      
    if(contactData.CompanyTitle.length==0)
      errFields.push({ FieldName : "CompanyTitle" });
    else if (tagRegex.test(contactData.CompanyTitle)) // Check if email matches the regex pattern
      errFields.push({ FieldName : "CompanyTitle", Error: "Forbidden characted in text" }); // Add specific error for invalid email format
    else
      errFields = errFields.filter(o=>o.FieldName != "CompanyTitle");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(contactData.EMail.length==0)
      errFields.push({ FieldName : "EMail" });
    else if (!emailRegex.test(contactData.EMail)) // Check if email matches the regex pattern
      errFields.push({ FieldName : "EMail", Error: "Invalid email format" }); // Add specific error for invalid email format
    else
      errFields = errFields.filter(o=>o.FieldName != "EMail");

     


    

      debugger;
    setErrorField(errFields);

    if(errFields.length>0)
      return false;
    else
    {
     
      return true;
    }
     
  
  }

  const PropChanged = (fname: string, val: any) => {
    // Sanitize the input

    //val = DOMPurify.sanitize(val);
    // Update the state
    if (fname == "EName") {
      let newobj: RegisterCompanyModel = { ...contactData, EName: val };
      setContactData(newobj);
    } else if (fname == "EMail") {
      let newobj: RegisterCompanyModel = { ...contactData, EMail: val };
      setContactData(newobj);
    } else if (fname == "CompanyTitle") {
      let newobj: RegisterCompanyModel = { ...contactData, CompanyTitle: val };
      setContactData(newobj);
    } 
  };

  const SubmitForm = async () => {
    debugger;

    if(ValidateForm() == false)
      return;

    const res = await CompanyFormService.post(contactData);

    if(res.data == true)
    {
      navigate("/thankyou");
    }


    //alert(JSON.stringify(contactData));
  };


  const HasError = (fieldname : string) => {

    return errorFields.filter(o=>o.FieldName == fieldname).length > 0;
  }

 return (
      true ? (<section className="px-8 md:px-24 py-32">
        <div className="flex flex-wrap -m-4">
          <div className="w-full lg:w-2/3 p-4">
            <p className="tracking-tight text-gray-600 mb-4">{t("SetupOrchestra")}</p>
            <h1 className="font-heading tracking-tight text-5xl md:text-6xl font-medium mb-14">{t("RegisterCompany")}</h1>
            <form action="#">
              <div className="max-w-2xl">
                <div className="flex flex-wrap -m-4 mb-3">
                  <div className="w-full sm:w-1/2 p-4">
                    <label className="block mb-3 text-sm font-medium tracking-tight">{t("YourName")}</label>
                    <input 
                      type="text" 
                      id="contact1-input1" 
                      className={clsx(
                        "w-full px-6 py-4 rounded-full border border-gray-900 placeholder-gray-500 focus:ring-4 focus:ring-orange-200 outline-none transition duration-200",
                        HasError("EName") ? "bg-red-50" : ""
                      )}  
                      placeholder="" 
                      value={contactData.EName}
                      maxLength={80}
                      onChange={(e) => PropChanged("EName", e.target.value)}
                    />
                  </div>
                  <div className="w-full sm:w-1/2 p-4">
                    <label className="block mb-3 text-sm font-medium tracking-tight">{t("EMail")}</label>
                    <input 
                    type="email" 
                    id="contact1-input2" 
                    className={clsx(
                      "w-full px-6 py-4 rounded-full border border-gray-900 placeholder-gray-500 focus:ring-4 focus:ring-orange-200 outline-none transition duration-200",
                      HasError("EMail") ? "bg-red-50" : ""
                    )}
                    value={contactData.EMail}
                    maxLength={80}
                    onChange={(e) => PropChanged("EMail", e.target.value)}
                    
                    />
                  </div>
                </div>
                <label className="block mb-3 text-sm font-medium tracking-tight">{t("CompanyTitle")}</label>
                <input 
                  type="text" 
                  id="contact1-input3" 
                  className={clsx(
                    "w-full mb-3 px-6 py-4 rounded-full border border-gray-900 placeholder-gray-500 focus:ring-4 focus:ring-orange-200 outline-none transition duration-200",
                    HasError("Subject") ? "bg-red-50" : ""
                  )}
                  value={contactData.CompanyTitle}
                  maxLength={120}
                  onChange={(e) => PropChanged("CompanyTitle", e.target.value)}
                  
                  />
                <button 
                  type="button" 
                  onClick={SubmitForm}
                  className="bg-black h-16 rounded-full px-6 py-4 inline-flex items-center justify-center gap-2 hover:bg-orange-600 focus:bg-orange-500 focus:ring-4 focus:ring-orange-200 transition duration-200">
                  <span className="text-white font-bold tracking-tight">{t("StartCompany")}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                    <path d="M14 6.66669H7.33333C4.38781 6.66669 2 9.0545 2 12V13.3334M14 6.66669L10 10.6667M14 6.66669L10 2.66669" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </button>
              </div>
            </form>
          </div>
          <div className="w-full lg:w-1/3 p-4">
            <img className="rounded-2xl w-full object-cover h-[600px]"  src="/images/products/contactimage.png" alt="" />
          </div>
        </div>
      </section>) : <div></div>
  );
}

export default ClientRegisterComp;
