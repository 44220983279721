import React, { Children, PropsWithChildren, useEffect, useState } from "react";
import clsx from "clsx";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/shadcn/ui/select";
import LeftMenuService from "@/services/nav/LeftMenuService";
import { SideNavItem } from "@/models/Types";
import { FieldTypeModel } from "@/models/uipage/FieldTypeModel";
import FieldTypeService from "@/services/ui/FieldTypeService";

interface IFieldTypeSelect {
  Value: string;
  Disabled?: boolean;
  OnSelect?: (_value: string) => void;
}
const FieldTypeSelect: React.FC<PropsWithChildren<IFieldTypeSelect>> = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<FieldTypeModel[]>([]);
  //
  useEffect(() => {
    setLoaded(false);
    fetch();
    setLoaded(true);
  }, []);

  const fetch = async () => {
      const resp = await FieldTypeService.get();
      if (resp) {
        if (resp.data) {
          setData(resp.data);
        } else {
          debugger;
        }
      }
    
  };


  return (
    <>
    <Select
    
        value={props.Value}
        onValueChange={(e) => props.OnSelect ? props.OnSelect(e) : null}
        disabled={props.Disabled}
        
        >
        <SelectTrigger className="w-[140px] bg-white" disabled={props.Disabled}>
            <SelectValue placeholder="" />
        </SelectTrigger>
        <SelectContent>
            <SelectGroup>
              {
                data.map((x) => {
                  return <SelectItem value={x.FieldType}>{x.Definition}</SelectItem>
                })
              }
               <SelectItem value={"None"}>&nbsp;</SelectItem>
            </SelectGroup>
        </SelectContent>
    </Select>
    </>
  );
};

export default FieldTypeSelect;