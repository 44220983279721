import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Button } from "@/shadcn/ui/button";
import { Label } from "@/shadcn/ui/label";
import { Separator } from "@/shadcn/ui/separator";
import { Toaster } from "@/shadcn/ui/toaster";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "@/shadcn/ui/use-toast";
import { SearchHelp } from "../../comps/SearchHelp";
import ProgService from "@/services/dev/ProgService";
import { OrcScreen } from "@/models/screen/OrcScreen";
import { ScreenEvent } from "@/models/Enums";
import ScreenService from "@/services/program/ScreenService";
import { Icon } from "@iconify/react";

function Programming() {
  //selected dict element

  const [progname, setProgName] = useState("");
  const [unAuthorized, setunAuthorized] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [pagefound, setPageFound] = useState(false);

  let initialscreen: OrcScreen = new OrcScreen();
  initialscreen.UIPath =  encodeURIComponent(window.location.pathname.toLowerCase());
  initialscreen.HRef = window.location.href;
  initialscreen.ProgName = "";
  initialscreen.ScreenEvent = ScreenEvent.Get;

  const navigate = useNavigate();

  useEffect(() => {
    fetch();
    GetHistory();
  }, []);

  const GetHistory = () => {
    const _progname = localStorage.getItem("PROGNAME");
    if(_progname)
    {
      setProgName(_progname);
    }
  

  }

  const fetch = async () => {
    setLoaded(false);
    const resp = await ScreenService.post(initialscreen);
    if (resp.data) {
      console.log(resp.data);
      setunAuthorized(resp.data.UnAuthorized);
    } else {
      setLoaded(true);
      setPageFound(false);
    }
    debugger;
  };

  const UnAuthorizedView = (
    <div className="min-h-[500px] flex flex-col items-center justify-center text-4xl text-primary">
      <div className="font-bold text-6xl mb-4">401</div>
      <div>
        Page{" "}
        <span className="text-red-600">
          {decodeURIComponent(initialscreen.ProgName.toLowerCase())}
        </span>{" "}
        Not authorized
      </div>
    </div>
  );

  const Display = async () => {
    if (progname.length > 0) {
      var domExist = await ProgService.isExist(progname);
      if (domExist) {
        localStorage.setItem("PROGNAME", progname);
        navigate("/apps/program/display/" + progname);
      } else {
        toast({
          title: "Program does not exist.",
          description: "Please check your name and try again.",
        });
      }
    }
  };

  const Change = async () => {
    if (progname.length > 0) {
      var domExist = await ProgService.isExist(progname);
      if (domExist) {
        localStorage.setItem("PROGNAME", progname);
        navigate("/apps/program/change/" + progname);

      } else {
        toast({
          title: "Program does not exist.",
          description: "Please check your name and try again.",
        });
      }
    }
  };

  const Create = async () => {
    //
    if (progname.length > 0) {
      if (progname.includes(" ")) {
        toast({
          title: "Check Program Name",
          description: "Program names should have no blank.",
        });
      } else {
        var progExist = await ProgService.isExist(progname);
        if (progExist == false) {
          localStorage.setItem("PROGNAME", progname);
          const _progname = encodeURIComponent(progname);
          navigate("/apps/program/create/" + _progname);
        } else {
          toast({
            title: "Program already exist.",
            description:
              "Program already created, check your name and try again.",
          });
        }
      }
    } else {
      toast({
        title: "Enter program name",
        description: "Please enter program name to be created.",
      });
    }
  };

  const OnProgSelect = (x: string) => {
    setProgName(x);
  };

  return (unAuthorized === false ? (
    <div className="bg-white w-full h-screen p-4">
      <h1 className="font-bold text-2xl text-red-800">Programs</h1>
      <div className="mb-4">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink
                className="hover:cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/apps/exec/basis/develop");
                }}
              >
                Developments
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Programs</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <Separator className="my-4" />
            {/* Commands Start */}
            <div className="flex flex-row flex-nowrap my-2 gap-1 mb-10">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Create()}
          title="Create"
        >
          <Icon
            icon="akar-icons:paper"
            width="18"
            height="18"
            className="text-black bg-white"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Change()}
          title="Change"
        >
          <Icon
            icon="solar:pen-outline"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Display()}
          title="Display"
        >
          <Icon
            icon="tabler:zoom"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
         // onClick={() => Delete()}
          title="Delete"
        >
          <Icon
            icon="material-symbols-light:delete-outline"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
      </div>
      {/* Commands End */}
      <div>
        <div className="flex items-center">
          <div className="w-28">
            <Label className="ms-2">Program</Label>
          </div>
          <SearchHelp
            SHName="PROGNAME"
            className="ms-20 w-96"
            onChange={(e) => setProgName(e.target.value)}
            value={progname}
            OnSelect={(x) => OnProgSelect(x)}
          />
        </div>
      </div>
      <Toaster />
    </div>
  ) : UnAuthorizedView)
}

export default Programming;
