import DIContainer from "@/models/DIContainer";
import CodeCheckResult from "@/models/orcide/CodeCheckResult";
import HoverInfoResult from "@/models/orcide/HoverInfoResult";
import SignatureHelpResult from "@/models/orcide/SignatureHelpResult";
import TabCompletionResult from "@/models/orcide/TabCompletionResult";
import IResponseHelper from "@/models/response/IResponseHelper";

class OrcIdeService {
  private httpClient = DIContainer.HttpJsonClient;

  complete = async (data: any) => {
    const response = await this.httpClient.post<
      IResponseHelper<TabCompletionResult[]>
    >("/api/v1/ide/complete", data);

    return response;
  };

  signature = async (data: any) => {
    const response = await this.httpClient.post<
      IResponseHelper<SignatureHelpResult>
    >("/api/v1/ide/signature", data);

    return response;
  };

  hover = async (data: any) => {
    const response = await this.httpClient.post<
      IResponseHelper<HoverInfoResult>
    >("/api/v1/ide/hover", data);

    return response;
  };

  codecheck = async (data: any) => {
    const response = await this.httpClient.post<
      IResponseHelper<CodeCheckResult[]>
    >("/api/v1/ide/codecheck", data);

    return response;
  };
}

export default new OrcIdeService();
