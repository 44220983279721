import React, { PropsWithChildren } from "react";
import clsx from "clsx";

export interface IOrcDiv {
  FieldName: string;
  ClassName?: string;
  ParentFieldName: string;
}

const OrcDiv: React.FC<PropsWithChildren<IOrcDiv>> = (props) => {
  return (
    <>
      <div
        key={props.FieldName}
        id={props.FieldName}
        className={clsx(props.ClassName, "")}
      >
        {props.children}
      </div>
    </>
  );
};
export default OrcDiv;
