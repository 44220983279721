import React from "react";
import clsx from "clsx";

export interface IOrcHeader2 {
  FieldName: string;
  ClassName?: string;
  Text: string;
  ParentFieldName: string;
}

export default function OrcHeader2(props: IOrcHeader2) {
  return (
    <>
      <h2
        key={props.FieldName}
        id={props.FieldName}
        className={clsx("text-2xl font-bold text-black", props.ClassName)}
      >
        {props.Text}
      </h2>
    </>
  );
}
