import React from "react";
import Hero from "../components/Hero";
import Login from "../components/Login";

function LoginPage() {
  return <Login />;
   
}

export default LoginPage;
