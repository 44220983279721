import TopBrands from "@/components/TopBrands";
import ThankContactComp from "@/components/ThankContactComp";

function ThankPage() {
  return (
    <section className="w-full min-h-[500px]">
      <ThankContactComp />
      <TopBrands />
      {/* <ContactForm /> */}
    </section>
  );
}

export default ThankPage;
