import DIContainer from "@/models/DIContainer";
import { TilePageModel } from "@/models/navi/TilePageModel";
import IResponseHelper from "@/models/response/IResponseHelper";

class TileMenuService {
  private httpClient = DIContainer.HttpJsonClient;

  get = async (menuid: string) => {
    const response = await this.httpClient.get<IResponseHelper<TilePageModel>>(
      `/api/v1/nav/tilemenu?menuid=` + menuid
    );

    return response;
  };

  // post = async (data: Client) => {
  //   const response = await this.httpClient.post(`/api/v1/blog`, data);
  //   return response;
  // };

  // put = async (data: Client) => {
  //   const response = await this.httpClient.put(`api/v1/blog`, data);
  //   return response;
  // };

  // delete = async (bid: string) => {
  //   const response = await this.httpClient.delete(`/api/v1/blogbsid=${bid}`);
  //   return response;
  // };
}

export default new TileMenuService();
