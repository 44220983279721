import React from "react";
import clsx from "clsx";

export interface IOrcHeader3 {
  FieldName: string;
  ClassName?: string;
  Text: string;
  ParentFieldName: string;
}

export default function OrcHeader3(props: IOrcHeader3) {
  return (
    <>
      <h3
        key={props.FieldName}
        id={props.FieldName}
        className={clsx("text-xl font-bold text-black", props.ClassName)}
      >
        {props.Text}
      </h3>
    </>
  );
}
