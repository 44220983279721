function NotFound2() {
  return (
    <div className="min-h-[500px] flex flex-col items-center justify-center text-4xl text-primary">
      <div className="font-bold text-6xl mb-4">404</div>
      <div> Not found</div>
    </div>
  );
}

export default NotFound2;
