import React from "react";

function EmployeeTile1() {
  return (
    <div className="relative w-52 h-52 p-4 bg-yellow-50 text-foreground hover:bg-opacity-35 hover:cursor-pointer transition duration-200 hover:scale-110">
      Donna Moore
      <p className="text-primary">Incentives Manager</p>
      <img
        className="absolute rounded-full w-12 h-12 bottom-3 left-4"
        src="https://randomuser.me/api/portraits/thumb/men/75.jpg"
      ></img>
    </div>
  );
}

export default EmployeeTile1;
