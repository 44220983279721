
import clsx from "clsx";
import DOMPurify from "dompurify";
import { useState } from "react";
import { useTranslation } from "react-i18next";



export class ErrorField {
  FieldName : string = "";
  Error? : string = "";

}

function ThankContactComp() {
  //
  const { t, i18n } = useTranslation("", { keyPrefix: "ThankContactComp" });
  //



  return (
    true ? (<section className="px-8 md:px-24 py-32">
      <div className="flex flex-wrap -m-4">
        <div className="w-full lg:w-2/3 p-4">
          <div className="font-heading tracking-tight text-4xl md:text-6xl font-medium mb-14">{t("Thanks")}</div>
          <div className="font-heading tracking-tight text-2xl md:text-6xl font-medium mb-14">{t("WeWillBackToYou")}</div>
        </div>
        <div className="w-full lg:w-1/3 p-4">
          <img className="rounded-2xl w-full object-cover h-[600px]"  src="/images/products/contactimage.png" alt="" />
        </div>
      </div>
    </section>) : <div></div>
  );
}

export default ThankContactComp;
