import React from "react";
import Hero from "../components/Hero";
import Login from "../components/Login";
import ContactForm from "@/components/ContactForm";
import { Input } from "@/shadcn/ui/input";
import { Label } from "@/shadcn/ui/label";
import ClientExistCheckService from "@/services/clientexist/ClientExistCheckService";
import { toast } from "@/shadcn/ui/use-toast";
import { Toaster } from "@/shadcn/ui/toaster";
import TopBrands from "@/components/TopBrands";
import ClientLoginRedirectComp from "@/components/ClientLoginRedirectComp";
import ClientRegisterComp from "@/components/ClientRegisterComp";

function ClientRegisterPage() {
  //

  return (
    <>
     <ClientRegisterComp />
     <TopBrands />
    </>
   
  
) }

export default ClientRegisterPage;
