import { FieldType } from "../Enums";

export class UIPageModel {
  UICode: string = "";
  UIPath: string = "";
  UIPageType : UIPageType = UIPageType.Screen;
  Title: string = "";
  SubTitle?: string = "";
  BGClassName?: string = "";
  TileClassName?: string;
  TileIconName?: string = "";
  TileIconClassName?: string = "";
  PageTitleClassName?: string = "";
  PageInfoIconsClassName?: string = "";

  TextClassName?: string = "";
  Seqnr: number = 0;
  FigureClassName?: string = "";
  MenuCode?: string = "";
  RoleName?: string = "";
  ProgName?: string = "";
  ModuleCode?: string = "";
  IsActive: boolean = true;
  IsMobile:boolean = true;
  ShowAsTile:boolean = true;
  Figure?: string = "";
  PageJSON?: string = "";
  CreatedBy?: string = "";
  CreatedOn?: string = "";
  ChangedBy?: string = "";
  ChangedOn?: string = "";
  Fields : UIPageField[] = [];
  Statuses : UIPageStatus[] = [];
  Params: UIPageParam[] = [];
  Words: UIPageWord[] = [];
  //Params: Key<string, string> = [];
}

export class UIPageField {
  FieldType: string = "";
  FieldName: string = "";
  ParentFieldName: string = "";
  RollName: string = "";
 
  Visible: boolean = true;
  Enabled: boolean = true;
  Required: boolean = false;
  PostBack: boolean = false;
  Seqnr: number = 0;
  ClassName?: string;
  Data?: string;
  Props?: string;
  
}

export class UIPageStatus {
  StatusName: string = "";
  FirstStatus: boolean = true;
  LastStatus: boolean = true;
}

export class UIPageParam {
  Name: string = "";
  Value: string = "";
}

export class UIPageWord {
  WordName: string = "";
  WordText: string = "";
}

export enum UIPageType {
  Screen = 0,
  Workflow = 1
}
