import React from "react";
import { useTranslation } from "react-i18next";
import catalog from "../data/products.json";
import PageHeader from "./PageHeader";
import Icon from "./Icon";
import { Enum_Icons } from "../models/Enums";

function ProductBoxes() {
  const { t } = useTranslation();

  return (
    <section className="py-10 xl:pt-10">
      <div className="mx-auto">
        <PageHeader
          title={t("ProductsHeading")}
          subtitle={t("ProductsSubHeading")}
        />
        {/* <div className="mb-8 text-center">
          <h3 className="mb-4 text-2xl md:text-4xl text-coolGray-900 font-bold tracking-tighter">
            {t("ProductsHeading")}
          </h3>
          <p className="text-lg md:text-3xl text-coolGray-500 font-medium">
            {t("ProductsSubHeading")}
          </p>
        </div> */}
        <div className="flex flex-wrap lg:mx-20">
          {catalog.map((pro) => (
            <div className="md:w-1/3 w-full">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                {/* <img
                  className="lg:h-48 md:h-36 w-full object-cover object-center"
                  src="https://dummyimage.com/720x400"
                  alt="blog"
                /> */}
                <div className="lg:h-36 md:h-24 h-24 flex justify-center items-center bg-gray-700">
                  <Icon icon={pro.icon} size={60} classnames="text-white" />
                  {/* <img
                    src="images/products/Dashboard Designer.png"
                    className=""
                  ></img> */}
                </div>
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-800 mb-1">
                    {t("CATEGORY_" + pro.code)}
                  </h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                    {t("PRODUCT_" + pro.code)}
                  </h1>
                  <p className="leading-relaxed mb-3">
                    {t("DESC_" + pro.code)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProductBoxes;
