import React, { useState } from "react";
import clsx from "clsx";
import { OrcViewColumn } from "@/models/OrcViewColumn";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/shadcn/ui/dialog";
import { Button } from "@/shadcn/ui/button";
import { DataTypes } from "@/models/Enums";
import { Input } from "@/shadcn/ui/input";
import OrcTextBox from "./OrcTextBox";
import OrcDatePicker from "./OrcDatePicker";
import OrcTimePicker from "./OrcTimePicker";
import OrcCheckBox from "./OrcCheckBox";

export interface IOrcModal {
  ClassName?: string;
  OpenModal: boolean;
  FieldList: OrcViewColumn[];
  onOpenChange: (val: boolean) => void;
  onSubmitted: (val: any) => void;
}

export class OrcFormData {
  Key: string = "";
  Value?: any;
}

export default function OrcModal(props: IOrcModal) {

  // let testField : OrcViewColumn = new OrcViewColumn();
  // testField.ColumnName = "VORNA";
  // testField.Caption = "Adı";
  // testField.DataType = DataTypes.CHAR;
  // testField.Required = true;
  // testField.Visible = true;

  

  // let _modalData: any = [];
  // const [modalData, setModalData] = useState(_modalData);

  let formDataInitial : OrcFormData[] = [];

  props.FieldList.forEach((col) => {
    formDataInitial.push({Key: col.ColumnName, Value: ""});
  });

  const [FData, setFData] = useState(formDataInitial);

  const ModalFieldChanging = (colname: string, val : any) => {
    debugger;
    let newFData: OrcFormData[] = [...FData];
    const fld = newFData.filter((c) => c.Key === colname)[0];
    if(fld) {
      fld.Value = val;
    }
    else {
      newFData.push({Key: colname, Value: val});
    }

    setFData(newFData);
  }

  const GetValue = (colname: string) => {
    const fld = FData.filter((c) => c.Key === colname)[0];
    if(fld) {
      return fld.Value;
    }
    else
      return "";
  }

  const CreateFieldInModal = (field: OrcViewColumn) => {
    switch (field.DataType) {
      case "CHAR":
        return <OrcTextBox ClassName="w-full mt-2" FieldName={field.ColumnName} Data={GetValue(field.ColumnName)} ParentFieldName="#" Caption={field.Caption} Required={field.Required} Disabled={false} HasError={false} 
          OnChange={(value) => ModalFieldChanging(field.ColumnName, value)} />
      case "DATS":
        return <OrcDatePicker ClassName="w-full" FieldName={field.ColumnName} Data={GetValue(field.ColumnName)} ParentFieldName="#" Caption={field.Caption} Required={field.Required} Disabled={false} HasError={false} 
          OnChange={(value) => ModalFieldChanging(field.ColumnName, value)} />
      case "BOOL":
        return <OrcCheckBox ClassName="w-full" FieldName={field.ColumnName} Data={GetValue(field.ColumnName)} ParentFieldName="#" Caption={field.Caption} Required={field.Required} Disabled={false} HasError={false} 
          OnChecked={(value) => ModalFieldChanging(field.ColumnName, value)} />
    }

  }

  return (<Dialog open={props.OpenModal} onOpenChange={(val) => props.onOpenChange(val)}>
    <DialogContent className="sm:max-w-md font-sapFont">
    <DialogHeader>
      <DialogTitle>Create New Record</DialogTitle>
      <DialogDescription>
        Do not forget to press save button after submitting new record.
         {JSON.stringify(FData)}
        <br/>
        {props.FieldList.filter((c) => c.Visible === true).map((col: OrcViewColumn) => (
            CreateFieldInModal(col)
         ))}
      </DialogDescription>
    </DialogHeader>
    <DialogFooter className="sm:justify-start">
      <DialogClose asChild>
        <Button type="button" variant="secondary" onClick={() => props.onSubmitted(FData)}>
          Submit Record
        </Button>
      </DialogClose>
    </DialogFooter>
  </DialogContent>
  </Dialog>
  );
}
