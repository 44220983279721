import clsx from "clsx";
import { Label } from "@/shadcn/ui/label";
import { Checkbox } from "@/shadcn/ui/checkbox";
import { Button } from "@/shadcn/ui/button";


import { MouseEventHandler } from "react";

export interface IOrcButton {
  FieldName: string;
  ClassName?: string;
  CaptionClassName?: string;
  ParentFieldName: string;
  Disabled? : boolean;
  Caption?: string;
  NavigateTo?: string;
  GoToServer?: boolean;
  ScreenName?: string;
  OnClicked?:  MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function OrcButton(props: IOrcButton) {
  //
  

  return (
    <div className="flex flex-col gap-1" >
      
       <div>
       <Button
          className={clsx("bg-gray-800 hover:bg-gray-600 text-white hover:cursor-pointer",
            props.ClassName,
            props.Disabled ? "cursor-not-allowed" : ""
        )
      }
          disabled={props.Disabled}
          onClick={props.OnClicked}
          type="button"
        >
          { props.Caption }
          {/* {loading && (
            // <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          )} */}
          {/* {t("SignIn")} */}
        </Button>


       </div>

       
    </div>
  );
}
