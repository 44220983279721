import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function TrainingComp() {
  const { t, i18n } = useTranslation("", { keyPrefix: "TrainingComp" });

  return (
<section className="py-24">
    <div className="container mx-auto px-4">
      <h1 className="tracking-tight font-heading text-4xl md:text-6xl font-medium mb-16">{t("JoinHRTeched")}</h1>
      <div className="flex flex-wrap -m-4">
        <div className="w-full md:w-1/2 p-4">
        {/* HRDevShop */}
          <div className="rounded-2xl bg-gray-800 p-8 relative h-auto">
            <p className="tracking-tight text-2xl font-medium text-white mb-4">{t("Course1")}</p>
            <p className="text-gray-300 tracking-tight truncate text-wrap overflow-hidden">
              {t("Word1")}
              <br/>  <br/> 
              {t("Word2")}
              <br/>  <br/> 
              {t("Word3")}
              <br/> <br/> 
              {t("Word4")}
              <br/><br/>
              #orchestrahr #opensource #buildtogether
            </p>
            <div className="absolute bottom-6 right-6">
              <a href="#" className="rounded-full bg-white w-11 h-11 flex items-center justify-center hover:bg-opacity-80 focus:bg-opacity-80 focus:ring-4 focus:ring-gray-300 transition duration-200">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  fill="none">
                  <path d="M10 3.33325V16.6666M16.6667 9.99992L3.33337 9.99992" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-4">
          {/* Create HR Headcount Dashboard */}
          <div className="rounded-2xl bg-gray-800 p-8 relative h-auto">

            <p className="tracking-tight text-2xl font-medium text-white mb-6">{t("Course2")}</p>
            <p className="text-gray-300 tracking-tight truncate text-wrap overflow-hidden">
              {t("Word1")}
              <br/>  <br/> 
              {t("Word6")}
              <br/>  <br/> 
              {t("Word3")}
              <br/>  <br/> 
              {t("Word4")}
              <br/>    <br/> 
              #orchestrahr #opensource #buildtogether
            </p>
            <div className="absolute bottom-6 right-6">
              <Link to="/hrdevshop" className=" rounded-full bg-white w-11 h-11 flex items-center justify-center hover:bg-opacity-80 focus:bg-opacity-80 focus:ring-4 focus:ring-gray-300 transition duration-200">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
                  <path d="M10 3.33325V16.6666M16.6667 9.99992L3.33337 9.99992" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default TrainingComp;
