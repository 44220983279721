import DIContainer from "@/models/DIContainer";
import { Table } from "@/models/datadictionary/Table";
import IResponseHelper from "@/models/response/IResponseHelper";

class TableService {
  private httpClient = DIContainer.HttpJsonClient;

  post = async (data: Table) => {
    const response = await this.httpClient.post<IResponseHelper<Table>>(
      "/api/v1/dd/table",
      data
    );

    return response;
  };

  activate = async (data: Table) => {
    const response = await this.httpClient.post<IResponseHelper<Table>>(
      "/api/v1/dd/table/activate",
      data
    );

    return response;
  };

  put = async (data: Table) => {
    const response = await this.httpClient.put<IResponseHelper<Table>>(
      "/api/v1/dd/table",
      data
    );

    return response;
  };

  isExist = async (tabname: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/dd/table/isexist?tabname=" + tabname
    );

    return response.data;
  };

  getByName = async (tabname: string) => {
    const response = await this.httpClient.get<IResponseHelper<Table>>(
      "/api/v1/dd/table/getByName?tabname=" + tabname
    );

    return response.data;
  };

  delete = async (tabname: string) => {
    const response = await this.httpClient.delete<IResponseHelper<Boolean>>(
      "/api/v1/dd/table/delete?tabname=" + tabname
    );

    return response.data;
  };
}

export default new TableService();
