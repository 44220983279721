import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import ClientListService from "@/services/clientlist/ClientListService";
import Client from "@/models/client/Client";

function ClientsPage() {
  const [loaded, setLoaded] = useState(false);

  let initialData: Client[] = [];
  const [data, setData] = useState(initialData);

  useEffect(() => {
    setLoaded(false);
    fetch();
    setLoaded(true);
  }, []);

  const fetch = async () => {
    const resp = await ClientListService.get();
    if (resp.data) {
      setData(resp.data);
    } else {
      //toast.success(resp.Error); // Displays a success message
      debugger;
      alert(resp.error);
    }
    debugger;
  };

  return (
    <div>
      {loaded &&
        data.map((item) => (
          <div>
            {item.Title} - {item.SubTitle}{" "}
          </div>
        ))}
    </div>
  );
}

export default ClientsPage;
