import React from "react";
import Hero from "../components/Hero";
import { DomainHelper } from "@/lib/DomainHelper";
import { CurrentDomain } from "@/models/Enums";
import HeroHR from "@/components/HeroHR";
import Roadmap from "@/components/Roadmap";
import BuildHRToday from "@/components/BuildHRToday";
import TopBrands from "@/components/TopBrands";
import HRSystem from "@/components/HRSystem";

function Home() {
  return (
  <>
  {/* <HeroHR />  */}
  <HRSystem />
  <TopBrands />
  <BuildHRToday/>
  
  {/* <TopBrands />
  <TopBrands />
  <TopBrands /> */}
  <Roadmap/>
  </>
)
}

export default Home;
