export default class BaseError extends Error {
  msg?: string;
  innerException?: Error;

  constructor(msg?: string, innerException?: Error) {
    super(msg);
    this.msg = msg;
    this.innerException = innerException;
  }
}
