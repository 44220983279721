import { useTranslation } from "react-i18next";
import catalog from "../data/hrmodules.json";
import PageHeader from "./PageHeader";
import { Icon } from "@iconify/react";

function TopBrands() {
  const { t, i18n } = useTranslation("", { keyPrefix: "TopBrands" });

  return (
<div className="mt-16 mb-6">
  <div className="container mx-auto px-4">
    <p className="tracking-tight text-center text-2xl md:text-4xl font-bold mb-4 font-sapFont">{t("Word1")}</p>
    
      <div className="hidden md:flex items-center justify-center gap-20">
        <img className="flex-shrink-0" src="/images/logo/refcompanies.png" alt="" />
      </div>

      <div className="flex md:hidden items-center justify-center gap-20">
        <img className="flex-shrink-0" src="/images/logo/refbrandssmall.png" alt="" />
      </div>
   
  </div>
</div>
  );
}

export default TopBrands;
