import { Toaster } from "@/shadcn/ui/toaster";
import { toast } from "@/shadcn/ui/use-toast";
import Cookies from "js-cookie";
import * as React from "react";
import { createContext } from "react";

//https://blog.logrocket.com/how-to-use-react-context-typescript/

//Context ile paylaşıcak veri arayüzü, bu arayüz çok önemli çünkü komp lar bunu alıp nesne oluşturup context metodları kullanacaklar
export interface IUser {
  UserName: string;
  Pernr: string;
  Title: string;
  Ename: string;
}

type ThemeColor = "Black" | "Blue";
type Theme = "Light" | "Dark";

//Context ile paylaşıcak veri tipi (metodları ile)
export type UserContextType = {
  User: IUser | null;
  Theme: Theme;
  updateTheme: (t: Theme) => void;
  saveUser: (user: IUser) => void;
  updateUser: (UserName: string) => void;
  removeUser: () => void;
  showError: (mess: string) => void;
};

let initialData: UserContextType = {
  User: null,
  Theme: "Dark",
  updateTheme(t: Theme) {},
  saveUser() {},
  removeUser() {},
  updateUser(UserName) {},
  showError(mess) {},
};

export const OrcContext = createContext<UserContextType>(initialData);

interface IOrcContextProvider {
  children: React.ReactNode;
}

export const OrcContextProvider = ({ children }: IOrcContextProvider) => {
  const [user, setUser] = React.useState<IUser | null>(null);
  const [TempTheme, setTheme] = React.useState<Theme>("Dark");

  React.useEffect(() => {
    const _darkModeStorage = localStorage.getItem("darkmode");

    debugger;
    // if (_darkModeStorage) {
    //   const darkModeTypeData = _darkModeStorage as Theme;
    //   if (darkModeTypeData == "Dark") document.body.classList.add("dark");
    // } else {
    //   updateTheme("Light");
    //   localStorage.setItem("darkmode", "Light");
    // }
  }, []);

  // const Theme: Theme = "Black";
  const saveUser = (_user: IUser) => {
    setUser(_user);
  };

  const removeUser = () => {
    Cookies.remove("token");
    setUser(null);
  };

  const updateUser = (_username: string) => {};

  const showError = (_mess: string) => {
    //alert(_mess);
    toast({
      title: _mess
    });
  };

  const updateTheme = (_t: Theme) => {
    setTheme(_t);
    //localStorage.setItem("darkmode", _t);
    document.body.classList.toggle("dark");
  };

  return (
    <OrcContext.Provider
      value={{
        Theme: TempTheme,
        User: user,
        updateTheme: updateTheme,
        saveUser: saveUser,
        removeUser: removeUser,
        updateUser: updateUser,
        showError: showError,
      }}
    >
      {children}
      <Toaster />
    </OrcContext.Provider>
  );
};
