import BaseApiService from "./BaseApiService";
import LoginResponseNew from "@/models/login/LoginResponseNew";
import TokenResponseModel from "@/models/login/TokenResponseModel";
import Cookies from "js-cookie";

export class AuthApiService extends BaseApiService {
  login = async (
    username: string,
    password: string,
    companyid: string
  ): Promise<TokenResponseModel> => {
    //Mevcut Orchestra Token Request
    // const formData = {
    //   grant_type: "password",
    //   username: email,
    //   password: password,
    // };

    // const loginResponse = await this.httpClient.post<LoginResponse>(
    //   "/token",
    //   this.encodeForm(formData)
    // );

    //Yeni Orchestra Token Request
    const formDataNew = {
      companyid: companyid,
      username: username,
      password: password,
    };
    const tokenResponse = await this.httpJsonClient.post<TokenResponseModel>(
      "/token",
      formDataNew
    );
    debugger;
    if (tokenResponse.Token == null) {
      return tokenResponse;
    }
    this.httpJsonClient.headers = {
      ...this.httpJsonClient.headers,
      Authorization: `Bearer ${tokenResponse.Token}`,
    };
    debugger; 
    const token = tokenResponse.Token;
    if(process.env.NODE_ENV === "development") {
       Cookies.set("token", token, { expires: 7 });
       //Cookies.set("token", token, { expires: 7, secure: false, httpOnly: true });
     // Cookies.set("token", token, { expires: 7, secure: true, httpOnly: true, sameSite: "Lax" });
    }
    else
    {
      Cookies.set("token", token, { expires: 7, secure: true, httpOnly: true });
      //Cookies.set("token", token, { expires: 7, secure: true, httpOnly: true, sameSite: "Lax" });
    }


    return tokenResponse;
  };

  //   configureMicrosoftAuthLogin = async (authToken: AuthToken) => {
  //     const MY_LANGU = AppStore.language.substr(0, 2).toUpperCase();
  //     this.httpClient.addHeader("MY_LANGU", MY_LANGU);

  //     this.httpClient.headers = {
  //       ...this.httpClient.headers,
  //       Authorization: `Bearer ${authToken.token}`,
  //     };
  //   };

  //   me = async () => {
  //     const response = await this.httpClient.get<BaseApiResponse<ApiUser>>(
  //       "/orcapi/people/getperson"
  //     );
  //     return response.data;
  //   };

  encodeForm = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
}

export default new AuthApiService();
