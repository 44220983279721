import { useTranslation } from "react-i18next";
import catalog from "../data/hrmodules.json";
import PageHeader from "./PageHeader";
import { Icon } from "@iconify/react";

function Roadmap() {
  const { t } = useTranslation();

  return (
<section className="px-4 md:px-24 mb-24">
<p className="tracking-tight text-center text-2xl md:text-4xl font-bold mb-12 font-sapFont">Follow Roadmap</p>
  <a href="#" className="group">
    <div className="flex justify-between items-center py-8 relative border-t border-b border-black border-opacity-10">
      <div className="flex gap-7">
        <p className="font-medium tracking-tight">01.</p>
        <h2 className="tracking-tight font-heading text-2xl md:text-3xl font-medium text-gray-400 group-hover:text-black transition duration-200">Register Community</h2>
      </div>
      <span className="text-orange-300 group-hover:text-orange-400 transition duration-200"></span>
      <div className="absolute right-20 top-4">
        <img className="hidden lg:block rounded-2xl object-cover h-64" src="https://images.unsplash.com/photo-1556745753-b2904692b3cd?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwxMHx8cmVnaXN0ZXJ8ZW58MHx8fHwxNzE4OTYyNjgzfDA&ixlib=rb-4.0.3&q=85&w=1920" alt="" />
      </div>
    </div>
  </a>
  <a href="#" className="group">
    <div className="flex gap-7 py-8 border-b border-black border-opacity-10">
      <p className="font-medium tracking-tight text-gray-400 group-hover:text-black transition duration-200">02.</p>
      <h2 className="tracking-tight font-heading text-2xl md:text-3xl font-medium text-gray-400 group-hover:text-black transition duration-200">Join Training Sessions</h2>
    </div>
  </a>
  <a href="#" className="group">
    <div className="flex gap-7 py-8 border-b border-black border-opacity-10">
      <p className="font-medium tracking-tight text-gray-400 group-hover:text-black transition duration-200">03.</p>
      <h2 className="tracking-tight font-heading text-2xl md:text-3xl font-medium text-gray-400 group-hover:text-black transition duration-200">Build, Share and Sell Your Product</h2>
    </div>
  </a>
</section>
  );
}

export default Roadmap;
