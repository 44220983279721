import { Input } from "@/shadcn/ui/input";
import { Label } from "@/shadcn/ui/label";
import React, { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";

import { Button } from "@/shadcn/ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { DataElement } from "@/models/datadictionary/DataElement";
import DataElementService from "@/services/dd/DataElementService";
import { SearchHelp } from "../../comps/SearchHelp";
import { Icon } from "@iconify/react";
import { toast } from "@/shadcn/ui/use-toast";
import { Toaster } from "@/shadcn/ui/toaster";
import DevClass from "../../comps/DevClass";

function DeleteDataElementDetail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let _domname = searchParams.get("domname");
  const routeParams = useParams();
  console.log("routeParams = ", JSON.stringify(routeParams));
  const BreadcrumbPart = () => (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/tiles/develop");
            }}
          >
            Developments
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/ddic");
            }}
          >
            Data Dictionary
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Delete Data Element</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );

  let InitialObject: DataElement = {
    RollName: routeParams.rollname ? routeParams.rollname : "",
    Status: "P",
    DomName: "",
    Definition: "",
    Caption: "",
    Datatype: "",
    PlaceHolder: "",
    LastChanged: "HRSP",
    LastChangedOn: "20240101",
    Package: "ORC_HR",
  };

  const [PageObject, SetPage] = useState(InitialObject);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    fetch();
    setLoaded(true);
  }, []);

  const fetch = async () => {
    if (routeParams.rollname) {
      const resp = await DataElementService.getByName(routeParams.rollname);
      if (resp) {
        if (resp) {
          SetPage(resp);
        } else {
          //toast.success(resp.Error); // Displays a success message
          debugger;
          // SetPage(resp.error);
        }
      }
    }
  };

  const Delete = async () => {
    debugger;
    setLoaded(false);
    //validation starts
    if (routeParams.rollname) {
      const resp = await DataElementService.delete(routeParams.rollname);
      if (resp) {
        navigate("/apps/ddic");
      }
      else
      {
        toast({
          title: "Data element cannot be deleted, it is used in tables",
        });
        debugger;
      }
    }

    setLoaded(true);
  };

  return (
    <div className="p-4 bg-white h-screen">
      <h1 className="font-bold text-2xl text-red-800">
        Delete Data Element
      </h1>
      <div className="mb-4">
        <BreadcrumbPart />
      </div>
      <Separator className="my-4" />
      {/* Commands Start */}
      <div className="flex flex-row flex-nowrap my-2 gap-1 mb-10">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Delete()}
          title="Delete"
        >
          <Icon
            icon="material-symbols-light:delete-outline"
            width="18"
            height="18"
            className="text-black bg-white"
          />
        </button>
      </div>
      {/* Commands End */}

      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row items-center">
          <Label className="w-48">Data Element Name</Label>
          <Input
            className="bg-gray-100 w-40 uppercase"
            placeholder="?"
            value={PageObject.RollName}
            maxLength={50}
            disabled={true}
          />
        </div>
        <div className="flex flex-row items-center">
          <Label className="w-48">Definition</Label>
          <Input
            className="bg-gray-100 w-96"
            placeholder="?"
            value={PageObject.Definition}
            maxLength={100}
            disabled={true}
          ></Input>
        </div>

        {/* Tab Start */}
        <Tabs defaultValue="properties" className="w-[400px] mt-5">
          <TabsList>
            <TabsTrigger value="properties">Properties</TabsTrigger>
            <TabsTrigger value="definition">Definition</TabsTrigger>
          </TabsList>
          {/* Tab 1 : Properties */}
          <TabsContent value="properties" className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed By</Label>
              <Input
                className="bg-gray-100 w-64 lowercase"
                placeholder="SAP"
                disabled={true}
                value={PageObject.LastChanged}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed On</Label>
              <Input
                className="bg-gray-100 w-24"
                placeholder=""
                disabled={true}
                value={PageObject.LastChangedOn}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Development Class</Label>
              <DevClass Value={PageObject.Package} Disabled={true}></DevClass>
            </div>
          </TabsContent>
          {/* Tab 2 : Definition */}
          <TabsContent value="definition" className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Domain</Label>
              <Input
                className="bg-gray-100 w-64"
                value={PageObject.DomName}
                maxLength={50}
                disabled={true}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Caption</Label>
              <Input
                className="bg-gray-100 w-64"
                placeholder="Enter caption"
                value={PageObject.Caption}
                maxLength={100}
                disabled={true}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">PlaceHolder</Label>
              <Input
                className="bg-gray-100 w-64"
                placeholder="Enter caption"
                value={PageObject.PlaceHolder}
                maxLength={200}
                disabled={true}
              ></Input>
            </div>
          </TabsContent>
        </Tabs>
      </div>
      <Toaster />
      {/* {JSON.stringify(PageObject)} */}
    </div>
  );
}

export default DeleteDataElementDetail;
