import HttpClient from "./HttpClient";


export default class HttpJsonClient extends HttpClient {
  constructor(settings: { baseUrl?: string; headers?: any }) {
    super(settings);

    this.addHeader("Content-Type", "application/json");
  }
}
