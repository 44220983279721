import React from "react";

interface IPageHeader {
  title: string;
  subtitle: string;
}

function PageHeader(props: IPageHeader) {
  return (
    <div className="mb-8 text-center mt-4">
      <h3 className="mb-4 text-2xl md:text-5xl text-coolGray-900 tracking-tighter font-sapFont">
        {props.title}
      </h3>
      <p className="text-lg md:text-3xl text-coolGray-500 font-medium font-sapFont">
        {props.subtitle}
      </p>
    </div>
  );
}

export default PageHeader;
