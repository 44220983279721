import DIContainer from "@/models/DIContainer";
import Client from "@/models/client/Client";
import IResponseHelper from "@/models/response/IResponseHelper";

class ClientListService {
  private httpClient = DIContainer.HttpJsonClient;

  get = async () => {
    const response = await this.httpClient.get<IResponseHelper<Client[]>>(
      `/orcapi/clientlisting/get?langu=TR`
    );

    return response;
  };

  // post = async (data: Client) => {
  //   const response = await this.httpClient.post(`/api/v1/blog`, data);
  //   return response;
  // };

  // put = async (data: Client) => {
  //   const response = await this.httpClient.put(`api/v1/blog`, data);
  //   return response;
  // };

  // delete = async (bid: string) => {
  //   const response = await this.httpClient.delete(`/api/v1/blogbsid=${bid}`);
  //   return response;
  // };
}

export default new ClientListService();
