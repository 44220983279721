import React, { PropsWithChildren, useState } from "react";
import clsx from "clsx";
import { Icon } from "@iconify/react";
import { DataTypes } from "@/models/Enums";
import { OrcViewColumn } from "@/models/OrcViewColumn";
import { Input } from "@/shadcn/ui/input";
import { Switch } from "@/shadcn/ui/switch";
import { toast } from "@/shadcn/ui/use-toast";
import { Toaster } from "@/shadcn/ui/toaster";

export interface IOrcTable {
  FieldName: string;
  ClassName?: string;
  ParentFieldName: string;
  InlineEditing: boolean;
  HasRowCommand: boolean;
  HasEditCommand: boolean;
  HasDetailCommand: boolean;
  HasRefreshCommand: boolean;
  HasDeleteCommand: boolean;
  HasCopyCommand: boolean;
  TableData?: any[];
  Columns: OrcViewColumn[];
  TableSaveClicked: (newData: any) => void;
}

enum TableStatus {
  DisplayMode = 0,
  EditMode = 1,
  CreateMode = 2,
}

enum RowStatus {
  UnChanged = 1, //server dan gelmiş ve hiç değiştirilmemiş
  Changed = 2, //server dan gelmiş ve burda değiştirilmiş
  Added = 3, //burda yeni eklenmiş
  Deleted = 4, //burda silinmiş
  Empty = 5, //boş ama hiç yazılmamış
}

const OrcTable: React.FC<PropsWithChildren<IOrcTable>> = (props) => {
  let inactiveColor = "bg-gray-200";
  let activeColor = "bg-white";
  let rowbuttonsize: number = 18;

  const [saveNeeded, setSaveNeeded] = useState(false);
  const [tablemode, setTableMode] = useState<TableStatus>(
    TableStatus.DisplayMode
  );

  if (props.TableData) {
    props.TableData.forEach((obj: any) => {
      obj.__RowStatus = RowStatus.UnChanged;
    });
  }

  //Bu data props.TableData dan alınır ilk olarak ama kullanıcı değiştirebilir.
  //Değişen data ekranda gösterilir ama kaydedilmez, o nedenle kaydedilen data için ayrıca saveddata yapılmıştır.
  //Undo yapıldığından saveddata data ya geri yüklenir,
  const [data, setData] = useState(props.TableData);
  let _savedData: any[] = [];
  if (data) {
    data.forEach((obj: any) => {
      var clone = Object.assign({}, obj);
      _savedData.push(clone);
    });
  }

  const [saveddata, setSavedData] = useState(_savedData);
  /*
    Değişiklik düğmesine basılırsa Edit ve Display arasında tablo değişir.
  */
  const ChangeMode = () => {
    if (tablemode === TableStatus.EditMode)
      setTableMode(TableStatus.DisplayMode);
    else {
      setTableMode(TableStatus.EditMode);
    }

    // alert(tablemode.toString());
  };

  const CreateMode = () => {
    if (tablemode === TableStatus.CreateMode) {
      setTableMode(TableStatus.DisplayMode);
      let tmpArray: any[] = [];
      saveddata!.forEach((obj) => {
        var clone = Object.assign({}, obj);
        tmpArray.push(clone);
      });
      setData(tmpArray);
    } else {
      setTableMode(TableStatus.CreateMode);
      let _emptyAddableRow: any[] = [];
      //
      for (let i = 0; i < 10; i++) {
        let _newrow: any = {};
        props.Columns.forEach((col) => {
          _newrow[col.ColumnName] = "";
          _newrow.__RowStatus = RowStatus.Added;
        });
        _emptyAddableRow.push(_newrow);
      }

      setData(_emptyAddableRow);
    }

    // alert(tablemode.toString());
  };

  const UnDo = () => {
    debugger;
    //Array Copy
    let tmpArray: any[] = [];
    saveddata!.forEach((obj) => {
      var clone = Object.assign({}, obj);
      tmpArray.push(clone);
    });

    setData(tmpArray);
    setTableMode(TableStatus.DisplayMode);
    setSaveNeeded(false);
    toast({
      title: "Değişiklikler Geri Alındı",
    });
  };

  /*
    Hücrelerde veri değiştikçe array güncellenir.
  */
  const ChangeData = (
    index: number,
    newValue: any,
    rowData: any,
    colData: OrcViewColumn
  ) => {
    if (
      tablemode === TableStatus.EditMode ||
      tablemode === TableStatus.CreateMode
    ) {
      console.log("newValue = ", newValue);
      if (data) {
        const newArray = data.map((item, i) => {
          if (index === i) {
            //değişen index bulundu
            //yeni satır durumu ne olarak ona karar ver
            debugger;
            let newRowStatus: RowStatus = RowStatus.Changed;
            if (item.__RowStatus == RowStatus.UnChanged)
              newRowStatus = RowStatus.Changed;
            //değişmemiş bir satır ise değişmiş olur
            else if (item.__RowStatus == RowStatus.Empty)
              newRowStatus = RowStatus.Added;
            //yeni eklenen satır ise eklendi olarak değişir, empty den ayırt etmek lazım çünkü emty ler şemaya gitmez
            else if (item.__RowStatus == RowStatus.Added)
              newRowStatus = RowStatus.Added; //yeni eklenmiş ise durumu aynı kalır

            setSaveNeeded(true);
            return {
              ...item,
              [colData.ColumnName]: newValue,
              __RowStatus: (item.__RowStatus = newRowStatus),
            };
          } else {
            return item;
          }
        });
        setData(newArray);
      }
    }
  };

  const RenderCell = (index: number, data: any, col: OrcViewColumn) => {
    let disabled: boolean = true;
    if (tablemode == TableStatus.DisplayMode) {
      //Display mod ise sadece span döner
      return (
        <span className="p-3">
          {data[col.ColumnName] != null ? data[col.ColumnName].toString() : ""}
        </span>
      );
    } else if (tablemode == TableStatus.CreateMode) {
      //Yarat modunda ise hepsi span ama yeni eklenen satırlar tamamen açık gelir
      if (
        data.__RowStatus == RowStatus.Empty ||
        data.__RowStatus == RowStatus.Added
      ) {
        console.log("empty row");
        //debugger;
        disabled = false;
      } else {
        //Yarat modunda yeni olmayan satırlar
        return <span className="p-3">{data[col.ColumnName].toString()}</span>;
      }
    } else if (tablemode == TableStatus.EditMode) {
      //Edit alanlar açık ama primary olan kapalı sadece
      disabled = false;
      if (col.Primary) disabled = true;
    } else {
      console.log("Bu tablo modu kodlanmadı");
    }

    console.log(index.toString());
    if (index == 2) console.log(JSON.stringify(data));

    if (col.DataType == "CHAR") {
      return (
        <Input
          type="text"
          placeholder=""
          height={20}
          className={clsx(
            "w-full p-2 text-sm border-gray-200 border-2",
            disabled ? inactiveColor : activeColor
          )}
          value={data[col.ColumnName]}
          onChange={(e) => ChangeData(index, e.target.value, data, col)}
          disabled={disabled}
        />
      );
    } else if (col.DataType == "INT4") {
      return (
        <Input
          type="number"
          placeholder="sdsd"
          height={20}
          className={clsx(
            "w-full p-2 text-sm border-gray-200 border-2 text-right",
            disabled ? inactiveColor : activeColor
          )}
          value={data[col.ColumnName]}
          //onChange={(e) => ChangeData(index, e.target.value, data, col)}
          disabled={disabled}
        />
      );
    } else if (col.DataType == "BOOL") {
      return (
        <Switch
          //className={clsx("", disabled ? inactiveColor : activeColor)}
          checked={data[col.ColumnName]}
          //  onCheckedChange={(e) => ChangeData(index, e, data, col)}
          disabled={disabled}
        />
      );
    } else return data[col.ColumnName];
  };

  const RowAction_Delete = (index: number, row: any) => {
    debugger;
    let tmpData = [...data!];
    debugger;
    if (
      row.__RowStatus == RowStatus.Empty ||
      row.__RowStatus == RowStatus.Added
    ) {
      //Empty ya da added ise direkt sil
      var index = tmpData.indexOf(row); // Let's say it's Bob.
      tmpData.splice(index, 1);
      setData(tmpData);
    } else if (row.__RowStatus == RowStatus.UnChanged) {
      var index = tmpData.indexOf(row); // Let's say it's Bob.
      row.__RowStatus = RowStatus.Deleted;
      setData(tmpData);
      setSaveNeeded(true);
    }

    // setTableMode(TableStatus.DisplayMode);
    // toast({
    //   title: "Değişiklikler Kaydedildi",
    //   description: "Değişiklikler sisteme kaydedilmiştir.",
    // });
  };

  //Satır da edit olmadı, şu anda sadece üst menüden change ile yapıyorum
  const RowAction_Edit = (index: number, row: any) => {
    debugger;
    // let tmpData = [...data!];
    // debugger;
    // if (row.__RowStatus == RowStatus.UnChanged) {
    //   var index = tmpData.indexOf(row); // Let's say it's Bob.
    //   setTableMode(TableStatus.EditMode);
    //   setData(tmpData);
    //   setSaveNeeded(true);
    // }
  };

  const Save = async () => {
    if (data) {
      //let tmpData = [...data];
      setTableMode(TableStatus.DisplayMode);
      props.TableSaveClicked(data);
    }

    // const resp = await TableService.post(props.SourceName, tmpData);
    // setTableMode(TableStatus.DisplayMode);
    // Refresh();
    // tmpData = tmpData.filter((o) => o.__RowStatus != RowStatus.Empty);
    // tmpData = tmpData.filter((o) => o.__RowStatus != RowStatus.Added);
    // setData(tmpData);

    // setTableMode(TableStatus.DisplayMode);
    // toast({
    //   title: "Değişiklikler Kaydedildi",
    //   description: "Değişiklikler sisteme kaydedilmiştir.",
    // });
  };

  return (
    <>
      <div
        key={props.FieldName}
        className={clsx("bg-white p-2 w-full rounded-sm", props.ClassName)}
      >
        {/* Table menu div */}
        <div className="flex items-center justify-between h-10 w-full mb-2">
          <div className="flex flex-row gap-1">
            {/* Yarat Düğmesi */}
            <button
              className={
                tablemode == TableStatus.EditMode ||
                tablemode == TableStatus.CreateMode
                  ? "w-8 h-8 bg-white text-gray-300 cursor-not-allowed rounded-sm flex items-center justify-center"
                  : "w-8 h-8 bg-white text-gray-800 cursor-pointer rounded-sm flex items-center justify-center"
              }
              onClick={CreateMode}
              disabled={
                tablemode == TableStatus.EditMode ||
                tablemode == TableStatus.CreateMode
                  ? true
                  : false
              }
            >
              <Icon icon="iconoir:empty-page" width={22} height={22} />
            </button>
            {/* Değiştir Düğmesi */}
            {props.InlineEditing && (
              <button
                className={
                  tablemode == TableStatus.EditMode ||
                  tablemode == TableStatus.CreateMode
                    ? "w-8 h-8 bg-white text-gray-300 cursor-not-allowed rounded-sm flex items-center justify-center"
                    : "w-8 h-8 bg-white text-gray-800 cursor-pointer rounded-sm flex items-center justify-center"
                }
                disabled={
                  tablemode == TableStatus.EditMode ||
                  tablemode == TableStatus.CreateMode
                    ? true
                    : false
                }
                onClick={ChangeMode}
              >
                <Icon icon="cil:pencil" width={22} height={22} />
              </button>
            )}
            {/* UnDo Düğmesi */}
            {props.InlineEditing && (
              <button
                className={
                  false
                    ? "w-8 h-8 bg-white text-gray-300 cursor-not-allowed rounded-sm flex items-center justify-center"
                    : "w-8 h-8 bg-white text-gray-800 cursor-pointer rounded-sm flex items-center justify-center"
                }
                //disabled={tablemode == TableStatus.CreateMode ? true : false}
                //disabled={saveNeeded == false ? true : false}
                onClick={UnDo}
                title="Değişiklikleri geri al"
              >
                <Icon icon="clarity:undo-line" width={22} height={22} />
              </button>
            )}
            {/* Kaydet Düğmesi */}
            <button
              className={
                saveNeeded == false
                  ? "w-8 h-8 bg-white text-gray-300 cursor-not-allowed rounded-sm flex items-center justify-center"
                  : "w-8 h-8 bg-white text-gray-800 cursor-pointer rounded-sm flex items-center justify-center"
              }
              disabled={saveNeeded == false ? true : false}
              title="Değişiklikleri kaydet"
              onClick={Save}
            >
              <Icon icon="oui:save" width={22} height={22} />
            </button>
          </div>
          <div>search kısmı olacak</div>
        </div>
        {/* Table itself */}
        <table className="table-auto text-left w-full">
          <thead>
            <tr>
              {props.Columns.filter((c) => c.Visible === true).map(
                (col: OrcViewColumn) => (
                  <th>{col.Caption}</th>
                )
              )}
              {/* Add blank header if it has row command columns */}
              {props.HasRowCommand && <th></th>}
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                .filter((f) => f.__RowStatus != RowStatus.Deleted)
                .map((rowdata: any, idx) => (
                  <tr className="hover:bg-gray-100 p-2 border-b-2 border-gray-300">
                    {/* adding data columns */}
                    {props.Columns.map((col: OrcViewColumn) => (
                      <td
                        className={clsx(
                          "h-10",
                          col.DataType == "BOOL"
                            ? "text-center align-middle"
                            : ""
                        )}
                      >
                        {/* <div>{JSON.stringify(rowdata)}</div> */}
                        {RenderCell(idx, rowdata, col)}
                      </td>
                    ))}
                    {/* adding command column */}
                    {props.HasRowCommand && (
                      <td>
                        <div className="flex flex-row gap-1 items-center justify-start">
                          {/* Row Buttons */}
                          {props.HasEditCommand && (
                            <div className="hover:cursor-pointer hover:scale-125 transform transition duration-200">
                              <Icon
                                icon="cil:pencil"
                                width={rowbuttonsize}
                                height={rowbuttonsize}
                                onClick={() => RowAction_Edit(idx, rowdata)}
                              />
                            </div>
                          )}
                          {props.HasDetailCommand && (
                            <div className="hover:cursor-pointer hover:scale-125 transform transition duration-200">
                              <Icon
                                icon="tabler:zoom"
                                width={rowbuttonsize}
                                height={rowbuttonsize}
                              />
                            </div>
                          )}
                          {props.HasDeleteCommand && (
                            <div className="hover:cursor-pointer hover:scale-125 transform transition duration-200">
                              <Icon
                                icon="material-symbols-light:delete-outline"
                                width={22}
                                height={22}
                                className="text-red-700"
                                onClick={() => RowAction_Delete(idx, rowdata)}
                              />
                            </div>
                          )}
                          {props.HasRefreshCommand && (
                            <div className="hover:cursor-pointer hover:scale-125 transform transition duration-200">
                              <Icon
                                icon="clarity:refresh-line"
                                width={rowbuttonsize}
                                height={rowbuttonsize}
                              />
                            </div>
                          )}
                          {props.HasCopyCommand && (
                            <div className="hover:cursor-pointer hover:scale-125 transform transition duration-200">
                              <Icon
                                icon="clarity:copy-line"
                                width={rowbuttonsize}
                                height={rowbuttonsize}
                              />
                            </div>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <Toaster />
    </>
  );
};
export default OrcTable;
