import DIContainer from "@/models/DIContainer";
import IResponseHelper from "@/models/response/IResponseHelper";
import { Domain } from "@/models/datadictionary/Domain";
import { User, UserPassword } from "@/models/idm/User";

class UserService {
  private httpClient = DIContainer.HttpJsonClient;

  post = async (data: User) => {
    const response = await this.httpClient.post<IResponseHelper<User>>(
      "/api/v1/idm/user",
      data
    );

    return response;
  };


  put = async (data: User) => {
    const response = await this.httpClient.put<IResponseHelper<User>>(
      "/api/v1/idm/user",
      data
    );

    return response;
  };

  isExist = async (username: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/idm/user/isexist?username=" + username
    );

    return response.data;
  };

  getByName = async (username: string) => {
    const response = await this.httpClient.get<IResponseHelper<User>>(
      "/api/v1/idm/user/getByName?username=" + username
    );

    return response.data;
  };

  delete = async (username: string) => {
    const response = await this.httpClient.delete<IResponseHelper<Boolean>>(
      "/api/v1/idm/user/delete?username=" + username
    );

    return response.data;
  };

  changepass = async (data: UserPassword) => {
    const response = await this.httpClient.put<IResponseHelper<UserPassword>>(
      "/api/v1/idm/user/changepass",
      data
    );

    return response;
  };
}

export default new UserService();
