import { Constants } from "@/models/Consts";
import { Label } from "@/shadcn/ui/label";
import clsx from "clsx";
import { useState } from "react";
import DatePicker from "react-datepicker";
//https://refine.dev/blog/react-date-picker/#conditionally-disable-dates
//https://reactdatepicker.com/
export interface IOrcTimePicker {
  FieldName: string;
  ClassName?: string;
  CaptionClassName?: string;
  Data: string;
  ParentFieldName: string;
  Disabled? : boolean;
  Required? : boolean;
  HasError?: boolean;
  Caption?: string;
  ScreenName?: string;
  RollName?: string;
  DomName?: string;
  DataType?: string;
  Length?: string;
  OnChange?: (_value: any) => void;
  HelpRequested?: (e:any) => void;
}

export default function OrcTimePicker(props: IOrcTimePicker) {

  //
  function convertHHMMSSToDate(timeStr: string): Date | null {
    // Validate input
    if (timeStr.length !== 6 || isNaN(Number(timeStr))) {
      console.error("Invalid time format. Expected HHMMSS.");
      return null;
    }
  
    // Extract hours, minutes, and seconds
    const hours = parseInt(timeStr.substring(0, 2), 10);
    const minutes = parseInt(timeStr.substring(2, 4), 10);
    const seconds = parseInt(timeStr.substring(4, 6), 10);
  
    // Create a new Date object for the current date
    const date = new Date();
    // Set hours, minutes, and seconds
    date.setHours(hours, minutes, seconds);
  
    return date;
  }

  function convertDateToHHMMSS(date: Date | null): string | null {
    //
    console.log("time data", date);
    //
    if(date == null) return null;
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
  
    return `${hours}${minutes}${seconds}`;
  }

  const [startDate, setStartDate] = useState<Date | null>(new Date());
  return (
    <div className="flex flex-col gap-1" >
        {props.Caption ? <Label htmlFor={props.FieldName} className={clsx("", props.CaptionClassName ? props.CaptionClassName : "")}>{props.Caption + (props.Required ? " *" : "")}</Label>: null} 
        <div>
       <DatePicker 
         key={props.FieldName}
         selected={convertHHMMSSToDate(props.Data)}
         onChange={(date) => props.OnChange ? props.OnChange(convertDateToHHMMSS(date)) : null} 
         onKeyDown={(e) => props.HelpRequested ? (e.key == "F1" ? props.HelpRequested(e) : null) : null} 
         showTimeSelect
         showTimeSelectOnly
         timeIntervals={15}
         timeCaption="Saat"
         dateFormat ="HH:mm"
         disabled={props.Disabled}
         className={clsx("rounded-md p-2 text-right w-20 border-gray-100 border",
            props.Disabled ? Constants.InActive : ""

         )}
         
         />
         </div>
    </div>
  );
}
