import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {

  const { t, i18n } = useTranslation("", { keyPrefix: "Footer" });
  //
  return (<div className="py-32 relative bg-black">
  <img className="absolute bottom-0 right-0" src="consulty-assets/footer/shapes2.svg" alt="" />
  <div className="container mx-auto px-4">
    <div className="flex flex-wrap -m-4">
      <div className="w-full lg:w-1/3 p-4">
        <div className="flex flex-col gap-8 h-full">
          <div>
            <img className="h-11 mb-6 rounded-xl" src="logob.png" alt="" />
            <p className="tracking-tight text-gray-200 max-w-sm">
            {t("Word1")}

            </p>
          </div>
          
          <div className="inline-flex mt-4">
          <a
            href="https://www.linkedin.com/showcase/18293663/admin/feed/posts/"
            className="hover:opacity-75"
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only"> LinkedIn </span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                fill="#869AB8"
              />
            </svg>
          </a>
          <a
            href="https://blog.orchestrahr.com"
            className="hover:opacity-75 ms-2 text-gray-500"
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only"> Medium </span>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>medium</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="invisible_box" data-name="invisible box">
                  <rect width="48" height="48" fill="none" />
                </g>
                <g id="Q3_icons" data-name="Q3 icons">
                  <path
                    fill="#869AB8"
                    d="M2,40.2l5.3-6.1v-21L2.6,7.8V7H15.1l10,21.2L33.9,7H46v.8l-4,3.7V36.6l4,3.6V41H28.6v-.8l4.1-4.8V16.6L22.7,41H21.4L9.8,17.1V33.9l5.3,6.3V41H2Z"
                  />
                </g>
              </g>
            </svg>
          </a>
          <a
            href="https://github.com/orchestrahr"
            className="hover:opacity-75 ms-2 text-gray-500"
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only"> GitHub </span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                clipRule="evenodd"
              />
            </svg>
          </a>
          </div>
            {/* <input type="text" className="flex-1 placeholder-gray-500 bg-transparent outline-none text-white" placeholder="Enter email address" />
            <a href="#" className="text-orange-400 hover:text-orange-500 transition duration-200">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
                <path d="M18.75 6.1875V15.9375C18.75 16.1364 18.671 16.3272 18.5303 16.4678C18.3897 16.6085 18.1989 16.6875 18 16.6875C17.8011 16.6875 17.6103 16.6085 17.4697 16.4678C17.329 16.3272 17.25 16.1364 17.25 15.9375V7.99781L6.53062 18.7181C6.38988 18.8589 6.19901 18.9379 5.99999 18.9379C5.80097 18.9379 5.6101 18.8589 5.46936 18.7181C5.32863 18.5774 5.24957 18.3865 5.24957 18.1875C5.24957 17.9885 5.32863 17.7976 5.46936 17.6569L16.1897 6.9375H8.24999C8.05108 6.9375 7.86031 6.85848 7.71966 6.71783C7.57901 6.57718 7.49999 6.38641 7.49999 6.1875C7.49999 5.98859 7.57901 5.79782 7.71966 5.65717C7.86031 5.51652 8.05108 5.4375 8.24999 5.4375H18C18.1989 5.4375 18.3897 5.51652 18.5303 5.65717C18.671 5.79782 18.75 5.98859 18.75 6.1875Z" fill="currentColor"></path>
              </svg>
            </a> */}
         
        </div>
      </div>
      <div className="w-full lg:w-2/3 p-4">
        <div className="flex flex-wrap justify-between gap-12">
          <div>
            <p className="tracking-tight text-gray-100 font-medium mb-6">{t("Product")}</p>
            <ul className="flex flex-col gap-2">
             <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("OrganizationManagement")}</li>
             <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("PersonnelAdministration")}</li>
             <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("NegativeTimeManagement")}</li>
             <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("OrganizationVisualizer")}</li>
             <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("OnBoarding")}</li>
             <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("Recruitment")}</li>
             <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("PositiveTimeManagement")}</li>
             <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("Payroll")}</li>
             <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("PerformanceManagement")}</li>
             <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("Survey")}</li>
             <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("TravelManagement")}</li>
             <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("CompensationManagement")}</li>
              {/* <li><a href="#" className="tracking-tight text-white hover:text-orange-500 transition duration-200">Small Business</a></li> */}
            </ul>
          </div>
          <div>
            <p className="tracking-tight text-gray-100 font-medium mb-6">{t("Company")}</p>
            <ul className="flex flex-col gap-2">
              <li><a href="https://hrsp.com.tr" target="_blank" className="tracking-tight text-white hover:text-orange-500 transition duration-200">HRSP</a></li>
              <li><a href="https://hrsp.orchestra-bu.com" target="_blank" className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("Support")}</a></li>
            </ul>
          </div>
          <div>
            <p className="tracking-tight text-gray-500 font-medium mb-6">{t("Getintouch")}</p>
            <ul className="flex flex-col gap-2">
              <li><a href="#" className="tracking-tight text-white hover:text-orange-500 transition duration-200">+90 555 555 4 777</a></li>
              <li><Link to="/contact" className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("Contact")}</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  );
}

export default Footer;
