import { Input } from "@/shadcn/ui/input";
import { Label } from "@/shadcn/ui/label";
import React, { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/shadcn/ui/select";

import { Button } from "@/shadcn/ui/button";
import DomainService from "@/services/dd/DomainService";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { DataElement } from "@/models/datadictionary/DataElement";
import DataElementService from "@/services/dd/DataElementService";
import { SearchHelp } from "../../comps/SearchHelp";
import { Toaster } from "@/shadcn/ui/toaster";
import { toast } from "@/shadcn/ui/use-toast";
import { Icon } from "@iconify/react";
import DevClass from "../../comps/DevClass";

function CreateDataElementDetail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let _domname = searchParams.get("domname");
  const routeParams = useParams();
  console.log("routeParams = ", JSON.stringify(routeParams));
  const BreadcrumbPart = () => (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/tiles/develop");
            }}
          >
            Developments
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/ddic");
            }}
          >
            Data Dictionary
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage> Create Data Element</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );

  let InitialObject: DataElement = {
    RollName: routeParams.rollname ? routeParams.rollname : "",
    Status: "P",
    DomName: "",
    Definition: "",
    Datatype: "",
    Caption: "",
    PlaceHolder: "",
    LastChanged: "",
    LastChangedOn: "",
    Package: "ORC_HR",
  };

  const [PageObject, SetPage] = useState(InitialObject);
  const [loaded, SetLoaded] = useState(false);

  const PropChanged = (fname: string, val: any) => {
    if (fname == "RollName") {
      let newobj: DataElement = { ...PageObject, RollName: val };
      SetPage(newobj);
    } else if (fname == "Package") {
      let newobj: DataElement = { ...PageObject, Package: val };
      SetPage(newobj);
    } else if (fname == "DomName") {
      let newobj: DataElement = { ...PageObject, DomName: val };
      SetPage(newobj);
    } else if (fname == "Caption") {
      let newobj: DataElement = { ...PageObject, Caption: val };
      SetPage(newobj);
    } else if (fname == "Definition") {
      let newobj: DataElement = { ...PageObject, Definition: val };
      SetPage(newobj);
    } else if (fname == "PlaceHolder") {
      let newobj: DataElement = { ...PageObject, PlaceHolder: val };
      SetPage(newobj);
    }
  };

  const Save = async () => {
    debugger;
    SetLoaded(false);
    //validation starts
    if (PageObject.Definition.trim().length == 0) {
      toast({
        title: "Error in Service",
        description: "Please enter definition for data element",
      });
      return;
    }
    if (PageObject.DomName.trim().length == 0) {
      toast({
        title: "Error in Service",
        description: "Please define domain for data element",
      });
      return;
    }
    if (PageObject.Caption.trim().length == 0) {
      toast({
        title: "Error in Service",
        description: "Please define caption for data element",
      });
      return;
    }

    const r = await DomainService.isExist(PageObject.DomName);
    if (r == false) {
      toast({
        title: "Error in Service",
        description: "Domain does not exist for data element",
      });
      return;
    }
    //validation ends
    const resp = await DataElementService.post(PageObject);
    if (resp.data) {
      //navigate("/apps/ddic");
      toast({
        title: "Data Element Created Successfully:)",
        description: resp.usermessage,
      });
    } else {
      toast({
        title: "Error in Service",
        description: resp.usermessage,
      });
    }
    SetLoaded(true);
  };

       /*
    Saves and activates
  */
    const Activate = async () => {
      debugger;
      SetLoaded(false);

      const resp = await DataElementService.activate(PageObject);
      debugger;
      if (resp.data) {
        //navigate("/apps/ddic");
        let newSaveobj: DataElement = { ...PageObject, Status: "A" };
        SetPage(newSaveobj);
        toast({
          title: "Successfull:)",
          description: "Data Element activated and created in database.",
        });
      } else {
        toast({
          title: "Error in Service",
          description: resp.usermessage,
        });
      }
      SetLoaded(true);
    };

  const OnDomainSelect = (x: string) => {
    let newobj: DataElement = { ...PageObject, DomName: x };
    SetPage(newobj);
  };

  return (
    <div className="p-4 bg-white h-screen">
      <h1 className="font-bold text-2xl text-red-800">
        Create Data Element
      </h1>
      <div className="mb-4">
        <BreadcrumbPart />
      </div>
      <Separator className="my-4" />
      <div className="flex flex-row flex-nowrap my-2 gap-1">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          // variant={"outline"}
          onClick={() => Save()}
          title="Save Data Element"
        >
          <Icon
            icon="carbon:save"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
            className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
            onClick={() => Activate()}
            title="Activate Data Element"
          >
          <Icon
            icon="line-md:speedometer-loop"
            width="18"
            height="18"
            className="text-black"
          />
          </button>
        {/* <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Activate()}
          title="Activate program"
        >
          <Icon
            icon="line-md:speedometer-loop"
            width="18"
            height="18"
            className="text-black"
          />
        </button> */}
      </div>

      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row items-center">
          <Label className="w-48">Data Element Name</Label>
          <Input
            className="bg-gray-100 w-40 uppercase"
            placeholder="?"
            value={PageObject.RollName}
            maxLength={50}
            disabled={true}
            onChange={(e) => PropChanged("RollName", e.target.value.toUpperCase())}
          />
                    <Input
            className="bg-gray-100 w-10 ms-1"
            value={PageObject.Status}
            maxLength={1}
            disabled={true}
          ></Input>
        </div>
        <div className="flex flex-row items-center">
          <Label className="w-48">Definition</Label>
          <Input
            className="bg-white w-96"
            placeholder="?"
            value={PageObject.Definition}
            maxLength={60}
            onChange={(e) => PropChanged("Definition", e.target.value)}
          ></Input>
        </div>

        {/* Tab Start */}
        <Tabs defaultValue="properties" className="w-[400px] mt-5">
          <TabsList>
            <TabsTrigger value="properties">Properties</TabsTrigger>
            <TabsTrigger value="definition">Definition</TabsTrigger>
          </TabsList>
          {/* Tab 1 : Properties */}
          <TabsContent value="properties" className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed By</Label>
              <Input
                className="bg-gray-100 w-64 lowercase"
                placeholder=""
                disabled={true}
                value={PageObject.LastChanged}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed On</Label>
              <Input
                className="bg-gray-100 w-24"
                placeholder=""
                disabled={true}
                value={PageObject.LastChangedOn}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Development Class</Label>
              <DevClass Value={PageObject.Package} OnSelect={(e) => PropChanged("Package", e)}></DevClass>
            </div>
          </TabsContent>
          {/* Tab 2 : Definition */}
          <TabsContent value="definition" className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Domain</Label>
              <SearchHelp
                SHName="DOMNAME"
                onChange={(e) => PropChanged("DomName", e.target.value.toUpperCase())}
                value={PageObject.DomName}
                InputClassName="uppercase"
                OnSelect={(x) => OnDomainSelect(x)}
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">Caption</Label>
              <Input
                className="bg-white w-64"
                placeholder="Enter caption"
                value={PageObject.Caption}
                maxLength={100}
                onChange={(e) => PropChanged("Caption", e.target.value)}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-24">PlaceHolder</Label>
              <Input
                className="bg-white w-64"
                placeholder="Enter placeholder"
                value={PageObject.PlaceHolder}
                maxLength={200}
                onChange={(e) => PropChanged("PlaceHolder", e.target.value)}
              ></Input>
            </div>
          </TabsContent>
        </Tabs>
      </div>
      <Toaster />
      {/* {JSON.stringify(PageObject)} */}
    </div>
  );
}

export default CreateDataElementDetail;
