import React from "react";
import clsx from "clsx";

export interface IOrcText {
  FieldName: string;
  ClassName?: string;
  Text: string;
  ParentFieldName: string;
}

export default function OrcText(props: IOrcText) {
  return (
    <>
      <p
        key={props.FieldName}
        id={props.FieldName}
        className={clsx("text-black", props.ClassName)}
      >
        {props.Text}
      </p>
    </>
  );
}
