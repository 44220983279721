import { useTranslation } from "react-i18next";
import catalog from "../data/hrmodules.json";
import PageHeader from "./PageHeader";
import { Icon } from "@iconify/react";

function HRModules() {
  const { t } = useTranslation();

  return (
    <section className="py-10 xl:pt-10">
      <div className="mx-auto">
        <PageHeader
          title={t("HRModulesHeading")}
          subtitle={""}
          // subtitle={t("HRModulesSubHeading")}
        />
        <div className="flex flex-wrap lg:mx-20">
          {catalog.map((pro) => (
            <div className="md:w-1/3 w-full">
              <div className="h-full rounded-lg overflow-hidden">
                <div className="lg:h-36 md:h-24 h-24 flex justify-center items-center">
                  <Icon
                    icon={pro.icon}
                    width="64"
                    height="64"
                    className="text-cyan-400"
                  />
                </div>
                <div className="p-6">
                  {/* <h2 className="tracking-widest text-xs title-font font-medium text-gray-800 mb-1">
                    {t("CATEGORY_" + pro.code)}
                  </h2> */}
                  <h1 className="title-font text-lg font-semibold text-gray-900 mb-3">
                    {t("PRODUCT_" + pro.code)}
                  </h1>
                  <p className="leading-relaxed mb-3">
                    {t("DESC_" + pro.code)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default HRModules;
