import { Input } from "@/shadcn/ui/input";
import { Label } from "@/shadcn/ui/label";
import React, { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";
import { useNavigate, useParams } from "react-router-dom";
import { SearchHelp } from "../../comps/SearchHelp";
import { Checkbox } from "@/shadcn/ui/checkbox";
import DataElementService from "@/services/dd/DataElementService";
import { toast } from "@/shadcn/ui/use-toast";
import { Toaster } from "@/shadcn/ui/toaster";
import { EditType, View } from "@/models/datadictionary/View";
import ViewService from "@/services/dd/ViewService";
import { Icon } from "@iconify/react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/shadcn/ui/select";
import DevClass from "../../comps/DevClass";
import SortType from "../../comps/SortType";
import ModuleSelect from "../../comps/ModuleSelect";
//
function ChangeViewDetail() {
  const navigate = useNavigate();

  const routeParams = useParams();
  console.log("routeParams = ", JSON.stringify(routeParams));
  const BreadcrumbPart = () => (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/tiles/develop");
            }}
          >
            Developments
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/ddic");
            }}
          >
            Data Dictionary
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Change View</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );

  let InitialTable: View = {
    ViewName: routeParams.tabname ? routeParams.tabname : "",

    KeyTable: "",
    ModuleCode: "SYSTEM",
    Enable_Add: true,
    Enable_Mod: true,
    Enable_Det: true,
    Enable_Del: true,
    Enable_Exc: true,
    MaxRow: 50,
    EditType: EditType.Inline,
    Status: "",
    Definition: "",
    LastChanged: "HRSP",
    LastChangedOn: "20240101",
    Package: "ORC_HR",
    ViewType: "CONFIG",
    SourceName: "",
    ExitProg: "",
    FilterText: "",
    Columns: [],
    Relations: [],
  };

  for (let index = 0; index < 10; index++) {
    InitialTable.Columns.push({
      TabName: "",
      DScreen: false,
      OScreen: false,
      Enabled: false,
      Obligatory: false,
      SourceName: "",
      DefaultValue: "",
      Primary: false,
      Seqnr: 0,
      SortType: 0,
      SortOrder: 0,
      FieldName: "",
      Rollname: "",
      Domname: "",
      Datatype: "",
      Definition: "",
      FieldType: "",
      Length: 0,
    });
  }

  const [PageObject, SetPage] = useState(InitialTable);
  const [loaded, SetLoaded] = useState(false);

  // const [canbeChanged, setCanBeChanged] = useState(true);

  useEffect(() => {
    SetLoaded(false);
    fetch();
    SetLoaded(true);
  }, []);

  const fetch = async () => {
    if (routeParams.tabname) {
      const resp = await ViewService.getByName(routeParams.tabname);
      if (resp) {
        if (resp) {
          SetPage(resp);
          for (let index = 0; index < 10; index++) {
            resp.Columns.push({
              TabName: "",
              DScreen: false,
              OScreen: false,
              Enabled: false,
              Obligatory: false,
              SourceName: "",
              DefaultValue: "",
              Primary: false,
              Seqnr: 0,
              SortType: 0,
              SortOrder: 0,
              FieldName: "",
              FieldType: "",
              Rollname: "",
              Domname: "",
              Datatype: "",
              Definition: "",
              Length: 0,
            });
          }
          for (let index = 0; index < 5; index++) {
            resp.Relations.push({
              RTabName: "",
              RFieldName: "",
              FieldName: "",
              Seqnr: index,
            });
          }
        } else {
          //toast.success(resp.Error); // Displays a success message
          debugger;
          // SetPage(resp.error);
        }
      }
    }
  };

  const PropChanged = (fname: string, val: any) => {
    if (fname === "TabName") {
      let newobj: View = { ...PageObject, ViewName: val };
      SetPage(newobj);
    } else if (fname === "Definition") {
      let newobj: View = { ...PageObject, Definition: val };
      SetPage(newobj);
    } else if (fname === "Package") {
      let newobj: View = { ...PageObject, Package: val };
      SetPage(newobj);
    }
    else if (fname === "Enable_Add") {
      let newobj: View = { ...PageObject, Enable_Add: val };
      SetPage(newobj);
    }
    else if (fname === "Enable_Mod") {
      let newobj: View = { ...PageObject, Enable_Mod: val };
      SetPage(newobj);
    }
    else if (fname === "Enable_Del") {
      let newobj: View = { ...PageObject, Enable_Del: val };
      SetPage(newobj);
    }
    else if (fname === "Enable_Det") {
      let newobj: View = { ...PageObject, Enable_Det: val };
      SetPage(newobj);
    }
    else if (fname === "Enable_Exc") {
      let newobj: View = { ...PageObject, Enable_Exc: val };
      SetPage(newobj);
    }
    else if (fname === "MaxRow") {
      let newobj: View = { ...PageObject, MaxRow: val };
      SetPage(newobj);
    }
    else if (fname === "KeyTable") {
      let newobj: View = { ...PageObject, KeyTable: val };
      SetPage(newobj);
    }
    else if (fname === "ModuleCode") {
      let newobj: View = { ...PageObject, ModuleCode: val };
      SetPage(newobj);
    }
    else if (fname === "ViewType") {
      let newobj: View = { ...PageObject, ViewType: val };
      SetPage(newobj);
    }
    else if (fname === "EditType") {
      if(val.toString() === "0") 
        val = EditType.Inline;
      else if(val.toString() === "1") 
        val = EditType.Modal;
      else if(val.toString() === "2") 
        val = EditType.Page;
      let newobj: View = { ...PageObject, EditType: val };
      SetPage(newobj);
    }
  };

  const FieldListChanged = (fname: string, ind: number, val: any) => {
    if (fname === "RollName") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].Rollname = val;
      SetPage(newobj);
    } else if (fname === "TabName") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].TabName = val;
      SetPage(newobj);
    } else if (fname === "FieldName") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].FieldName = val;
      SetPage(newobj);
    } else if (fname === "OScreen") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].OScreen = val;
      SetPage(newobj);
    } else if (fname === "DScreen") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].DScreen = val;
      SetPage(newobj);
    } else if (fname === "Enabled") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].Enabled = val;
      SetPage(newobj);
    } else if (fname === "Obligatory") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].Obligatory = val;
      SetPage(newobj);
    }  else if (fname === "SortOrder") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].SortOrder = val;
      SetPage(newobj);
    }  else if (fname === "DefaultValue") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].DefaultValue = val;
      SetPage(newobj);
    }
    else if (fname === "SortType") {
      let newobj: View = { ...PageObject };
      if(val.toString() === "0")
        newobj.Columns[ind].SortType = 0;
      else if(val.toString() === "1")
        newobj.Columns[ind].SortType = 1;
      else if(val.toString() === "2")
        newobj.Columns[ind].SortType = 2;

      SetPage(newobj);
    } else if (fname === "FieldType") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].FieldType = val;
      SetPage(newobj);
    } else if (fname === "ValueSource") {
      debugger;
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].ValueSource = val;
      SetPage(newobj);
    } else if (fname === "ValueTable") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].ValueTable = val;
      SetPage(newobj);
    } else if (fname === "ValueField") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].ValueField = val;
      SetPage(newobj);
    } else if (fname === "TextField") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].TextField = val;
      SetPage(newobj);
    } else if (fname === "ValueFilter") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].ValueFilter = val;
      SetPage(newobj);
    }

  };

  const RelatFieldListChanged = (fname: string, ind: number, val: any) => {
    if (fname === "RTabName") {
      let newobj: View = { ...PageObject };
      newobj.Relations[ind].RTabName = val;
      SetPage(newobj);
    }
    else if (fname === "RFieldName") {
      let newobj: View = { ...PageObject };
      newobj.Relations[ind].RFieldName = val;
      SetPage(newobj);
    }
    else if (fname === "FieldName") {
      let newobj: View = { ...PageObject };
      newobj.Relations[ind].FieldName = val;
      SetPage(newobj);
    }
  };

  const OnRollNameSelect = async (x: string, ind: number) => {
    let newobj: View = { ...PageObject };
    newobj.Columns[ind].Rollname = x;

    var delData = await DataElementService.getByName(x);
    if (delData) {
      newobj.Columns[ind].Domname = delData.DomName;
      newobj.Columns[ind].Datatype = delData.Datatype;
      newobj.Columns[ind].Definition = delData.Definition;
      newobj.Columns[ind].Length = delData.Length;
    }

    SetPage(newobj);
  };

  const OnRollNameBlur = async (x: string, ind: number) => {
    let newobj: View = { ...PageObject };
    newobj.Columns[ind].Rollname = x;
    if (x === "") {
      newobj.Columns[ind].Domname = "";
      newobj.Columns[ind].Datatype = "";
      newobj.Columns[ind].Definition = "";
      newobj.Columns[ind].Length = 0;
      SetPage(newobj);
      return;
    }
    var delData = await DataElementService.getByName(x);
    if (delData) {
      newobj.Columns[ind].Domname = delData.DomName;
      newobj.Columns[ind].Datatype = delData.Datatype;
      newobj.Columns[ind].Definition = delData.Definition;
      newobj.Columns[ind].Length = delData.Length;
    } else {
      newobj.Columns[ind].Domname = "";
      newobj.Columns[ind].Datatype = "";
      newobj.Columns[ind].Definition = "";
      newobj.Columns[ind].Length = 0;
    }

    SetPage(newobj);
  };

  /*
    Saves
  */
  const Save = async () => {
    debugger;
    SetLoaded(false);
    let newSaveobj: View = { ...PageObject, Columns: [], Relations: []};
    //add view columns
    PageObject.Columns.forEach((col) => {
      if (col.FieldName) {
        newSaveobj.Columns.push({
          TabName: col.TabName,
          DScreen: col.DScreen,
          OScreen: col.OScreen,
          Enabled: col.Enabled,
          SortType: col.SortType,
          SortOrder: col.SortOrder,
          Obligatory: col.Obligatory,
          Seqnr: col.Seqnr,
          SourceName: col.SourceName,
          Primary: col.Primary,
          FieldName: col.FieldName,
          Rollname: col.Rollname,
          DefaultValue: col.DefaultValue,
          Domname: col.Domname,
          Datatype: col.Datatype,
          Definition: col.Definition,
          FieldType: col.FieldType,
          Length: col.Length,
          ValueSource: col.ValueSource,
          ValueTable: col.ValueTable,
          ValueField: col.ValueField,
          TextField: col.TextField,
          ValueFilter: col.ValueFilter,
        });
      }
    });
    //add view relations
    PageObject.Relations.forEach((col) => {
      if (col.RTabName) {
        newSaveobj.Relations.push({
          RTabName: col.RTabName,
          RFieldName: col.RFieldName,
          FieldName: col.FieldName,
          Seqnr: col.Seqnr,
        });
      }
    });

    const resp = await ViewService.put(newSaveobj);
    if (resp.data) {
      //navigate("/apps/ddic");
      let newSaveobj1: View = { ...newSaveobj, Status: resp.data.Status };
      SetPage(newSaveobj1);
      toast({
        title: "Successfull:)",
        description: "View activated and created in database.",
      });
    } else {
      toast({
        title: "Error in Service",
        description: resp.usermessage,
      });
    }

    SetLoaded(true);
  };

  /*
    Saves and activates
  */
  const Activate = async () => {
    debugger;
    SetLoaded(false);
    let newSaveobj: View = { ...PageObject, Columns: [], Relations: []};

    PageObject.Columns.forEach((col) => {
      if (col.FieldName) {
        newSaveobj.Columns.push({
          TabName: col.TabName,
          DScreen: col.DScreen,
          OScreen: col.OScreen,
          Enabled: col.Enabled,
          Obligatory: col.Obligatory,
          Seqnr: col.Seqnr,
          SourceName: col.SourceName,
          Primary: col.Primary,
          FieldName: col.FieldName,
          SortType: col.SortType,
          SortOrder: col.SortOrder,
          DefaultValue: col.DefaultValue,
          Rollname: col.Rollname,
          Domname: col.Domname,
          Datatype: col.Datatype,
          Definition: col.Definition,
          FieldType: col.FieldType,
          Length: col.Length,
          ValueSource: col.ValueSource,
          ValueTable: col.ValueTable,
          ValueField: col.ValueField,
          TextField: col.TextField,
          ValueFilter: col.ValueFilter,
        });
      }
    });

    //add view relations
    PageObject.Relations.forEach((col) => {
      if (col.RTabName) {
        newSaveobj.Relations.push({
          RTabName: col.RTabName,
          RFieldName: col.RFieldName,
          FieldName: col.FieldName,
          Seqnr: col.Seqnr,
        });
      }
    });

    const resp = await ViewService.activate(newSaveobj);
    debugger;
    if (resp.data) {
      //navigate("/apps/ddic");
      let newSaveobj: View = { ...PageObject, Status: resp.data.Status};
      SetPage(newSaveobj);
      toast({
        title: "Successfull:)",
        description: "Table activated and created in database.",
      });
    } else {
      toast({
        title: "Error in Service",
        description: resp.usermessage,
      });
    }
    SetLoaded(true);
  };

  
  const AddFieldRow = (index: number) => {
    debugger;
    let newobj: View = { ...PageObject };

    const newFixValues = [
      ...newobj.Columns.slice(0, index),
      {
        TabName: "",
        FieldName: "",
        Rollname: "",
        OScreen: false,
        DScreen: false,
        Enabled: false,
        Obligatory: false,
        SourceName: "",
        DefaultValue: "",
        Primary: false,
        Seqnr: 0,
        SortType: 0,
        SortOrder: 0,
        Domname: "",
        Datatype: "",
        Definition: "",
        FieldType: "",
        Length: 0,

      },
      ...newobj.Columns.slice(index)
  ];

    newobj.Columns = newFixValues;

    SetPage(newobj);
  }

  const RemoveFieldRow = (index: number) => {

    let newobj: View = { ...PageObject };

    const newFixValues = [
      ...newobj.Columns
    ];

    newFixValues.splice(index, 1);

    newobj.Columns = newFixValues;

    SetPage(newobj);
  }

  return (
    <div className="p-4 bg-white h-screen">
      <h1 className="font-bold text-2xl text-red-800">Change View</h1>
      <div className="mb-4">
        <BreadcrumbPart />
      </div>
      <Separator className="my-4" />
      <div className="flex flex-row flex-nowrap my-2 gap-1">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          // variant={"outline"}
          onClick={() => Save()}
          title="Save program"
        >
          <Icon
            icon="carbon:save"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Activate()}
          title="Activate program"
        >
          <Icon
            icon="line-md:speedometer-loop"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
      </div>

      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row items-center">
          <Label className="w-48">View Name</Label>
          <Input
            className="bg-gray-100 w-40 uppercase"
            placeholder="?"
            value={PageObject.ViewName}
            maxLength={50}
            disabled={true}
            onChange={(e) => PropChanged("TabName", e.target.value)}
          />
          <Input
            className="bg-gray-100 w-10 ms-1"
            value={PageObject.Status}
            maxLength={1}
          ></Input>
        </div>
        <div className="flex flex-row items-center">
          <Label className="w-48">Short Description</Label>
          <Input
            className="bg-white w-96"
            placeholder="?"
            value={PageObject.Definition}
            maxLength={60}
            disabled={false}
            onChange={(e) => PropChanged("Definition", e.target.value)}
          ></Input>
        </div>

        {/* Tab Start */}
        <Tabs defaultValue="properties" className="md:w-4/5 mt-5">
          <TabsList>
            <TabsTrigger value="properties">Properties</TabsTrigger>
            <TabsTrigger value="tables">Tables</TabsTrigger>
            <TabsTrigger value="fields">Field List</TabsTrigger>
            <TabsTrigger value="fieldprops">Field Props</TabsTrigger>
            <TabsTrigger value="fieldsource">Field Source</TabsTrigger>
          </TabsList>
          {/* Tab 1 : Properties */}
          <TabsContent value="properties" className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed By</Label>
              <Input
                className="bg-gray-100 w-64 lowercase"
                placeholder=""
                disabled={true}
                value={PageObject.LastChanged}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed On</Label>
              <Input
                className="bg-gray-100 w-24"
                placeholder=""
                disabled={true}
                value={PageObject.LastChangedOn}
              ></Input>
            </div>
            {/* <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Development Class</Label>
              <DevClass Value={PageObject.Package} OnSelect={(e) => PropChanged("Package", e)}></DevClass>
            </div> */}
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Key Table</Label>
              <SearchHelp SHName="TABNAME" disabled={false} 
                  value={PageObject.KeyTable} 
                  OnSelect={(e) => PropChanged("KeyTable", e)} 
                  onChange={(e) => PropChanged("KeyTable", e.target.value)}
                />
              <Select
                value={PageObject.ViewType.toString()} // Convert EditType to string
                onValueChange={(e) =>
                  PropChanged("ViewType", e)
                }>
                <SelectTrigger className="w-[180px]">
                  <SelectValue />
                </SelectTrigger>
               <SelectContent>
                  <SelectGroup>
                    <SelectItem value="CONFIG">Customizing </SelectItem>
                    <SelectItem value="ESSINF">ESS Infotype</SelectItem>
                    <SelectItem value="OMOBJ">OM Object</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Module</Label>
              <ModuleSelect Value={PageObject.ModuleCode} OnSelect={(e) => PropChanged("ModuleCode", e)}></ModuleSelect>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Add Button</Label>
              <Checkbox
                checked={PageObject.Enable_Add}
                onCheckedChange={(checked) =>
                  PropChanged("Enable_Add", checked)
                }
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Edit Button</Label>
              <Checkbox
                checked={PageObject.Enable_Mod}
                onCheckedChange={(checked) =>
                  PropChanged("Enable_Mod", checked)
                }
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Delete Button</Label>
              <Checkbox
                checked={PageObject.Enable_Del}
                onCheckedChange={(checked) =>
                  PropChanged("Enable_Del", checked)
                }
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Detail Button</Label>
              <Checkbox
                checked={PageObject.Enable_Det}
                onCheckedChange={(checked) =>
                  PropChanged("Enable_Det", checked)
                }
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Execute Button</Label>
              <Checkbox
                checked={PageObject.Enable_Exc}
                onCheckedChange={(checked) =>
                  PropChanged("Enable_Exc", checked)
                }
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Max. Row</Label>
              <Input
              className="w-24 bg-white text-right"
              title="Number of rows to display in the table. Default is 50."
                value={PageObject.MaxRow}
                type="number"
                onChange={(e) =>
                  PropChanged("MaxRow", e.target.value)
                }
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Edit Type</Label>
              <Select
                value={PageObject.EditType.toString()} // Convert EditType to string
                onValueChange={(e) =>
                  PropChanged("EditType", e)
                }>
                <SelectTrigger className="w-[180px]">
                  <SelectValue />
                </SelectTrigger>
               <SelectContent>
                  <SelectGroup>
                    <SelectItem value="0">Inline </SelectItem>
                    <SelectItem value="1">Modal </SelectItem>
                    <SelectItem value="2">Page </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </TabsContent>
            {/* Tab 2 : Tables */}
            <TabsContent value="tables" className="flex flex-col gap-2 w-[130px]">
            <table>
              <thead>
                <tr className="font-unbold text-left">
                  <th>Related Table</th>
                  <th>Related Field</th>
                  <th>Fieldname</th>
                </tr>
              </thead>
              <tbody>
                {PageObject.Relations.map((rel, index) => (
                  <tr>
                    <td>
                      <Input
                        className="bg-white w-32 uppercase"
                        placeholder=""
                        value={rel.RTabName}
                        disabled={false}
                        onChange={(e) =>
                          RelatFieldListChanged("RTabName", index, e.target.value)
                        }
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-white w-32 uppercase"
                        placeholder=""
                        value={rel.RFieldName}
                        disabled={false}
                        onChange={(e) =>
                          RelatFieldListChanged("RFieldName", index, e.target.value)
                        }
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-white w-32 uppercase"
                        placeholder=""
                        value={rel.FieldName}
                        disabled={false}
                        onChange={(e) =>
                          RelatFieldListChanged("FieldName", index, e.target.value)
                        }
                      ></Input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </TabsContent>
          {/* Tab 3 : Fields */}
          <TabsContent value="fields" className="flex flex-col gap-2">
            <table>
              <thead>
                <tr className="font-unbold text-left">
                  <th></th>
                  <th>Table Name</th>
                  <th>Field Name</th>
                  <th title="Overview?">Ov</th>
                  <th title="Detail?">Dt</th>
                  <th title="Enabled?">En</th>
                  <th title="Required?">Rq</th>
                  <th>Data Element</th>
                  <th>Domain</th>
                  <th>Data type</th>
                  <th>Length</th>
                  <th>Definition</th>
                </tr>
              </thead>
              <tbody>
                {PageObject.Columns.map((col, index) => (
                  <tr>
                    <td className="flex flex-row items-center h-8">
                      <div className="h-3 hover:cursor-pointer" onClick={() => AddFieldRow(index)}><Icon className="text-green-600" icon="mingcute:add-circle-fill" width={18} height={18} /></div>
                      <div className="h-3 hover:cursor-pointer" onClick={() => RemoveFieldRow(index)}><Icon className="text-red-600" icon="mingcute:minus-circle-fill" width={18} height={18} /></div>
                    </td>
                    <td>


                      <Input
                        className="bg-white w-32 uppercase"
                        placeholder=""
                        value={col.TabName}
                        disabled={false}
                        onChange={(e) =>
                          FieldListChanged("TabName", index, e.target.value)
                        }
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-white w-32 uppercase"
                        placeholder=""
                        value={col.FieldName}
                        disabled={false}
                        onChange={(e) =>
                          FieldListChanged("FieldName", index, e.target.value)
                        }
                      ></Input>
                    </td>
                    <td className="w-12">
                      <Checkbox
                        checked={col.OScreen}
                        disabled={false}
                        onCheckedChange={(checked) =>
                          FieldListChanged("OScreen", index, checked)
                        }
                      />
                    </td>
                    <td className="w-12">
                      <Checkbox
                        checked={col.DScreen}
                        disabled={false}
                        onCheckedChange={(checked) =>
                          FieldListChanged("DScreen", index, checked)
                        }
                      />
                    </td>
                    <td className="w-12">
                      <Checkbox
                        checked={col.Enabled}
                        disabled={false}
                        onCheckedChange={(checked) =>
                          FieldListChanged("Enabled", index, checked)
                        }
                      />
                    </td>
                    <td className="w-12">
                      <Checkbox
                        checked={col.Obligatory}
                        disabled={false}
                        onCheckedChange={(checked) =>
                          FieldListChanged("Obligatory", index, checked)
                        }
                      />
                    </td>

                    <td>
                    {/* <Input
                        className="bg-gray-100 w-28"
                        placeholder=""
                        value={col.Rollname}
                        disabled={true}
                        type="text"
                      ></Input> */}
                      <SearchHelp
                        SHName="ROLLNAME"
                        disabled={false} //view da girilen satırdaki tablo database de olmayabilir, o nedenle burda değişilmesine izin ver
                        onChange={(e) =>
                          FieldListChanged("RollName", index, e.target.value)
                        }
                        value={col.Rollname}
                        InputClassName="uppercase"
                        OnSelect={(x) => OnRollNameSelect(x, index)}
                        onBlur={(e) => OnRollNameBlur(e.target.value, index)}
                      />
                    </td>
                    <td>
                      <Input
                        className="bg-gray-100 w-32"
                        placeholder=""
                        value={col.Domname}
                        disabled={true}
                      ></Input>
                    </td>
                    <td>
                      {/* Data Type */}
                      <Input
                        className="bg-gray-100 w-24"
                        placeholder=""
                        disabled={false}
                        value={col.Datatype}
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-gray-100 w-16"
                        placeholder=""
                        disabled={false}
                        value={col.Length == 0 ? "" : col.Length}
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-gray-100 w-64"
                        placeholder=""
                        disabled={false}
                        value={col.Definition}
                      ></Input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabsContent>
          {/* Tab 4 : Field Props */}
          <TabsContent value="fieldprops" className="flex flex-col gap-2">
            <table>
              <thead>
                <tr className="font-unbold text-left">
                  <th>Table Name</th>
                  <th>Field Name</th>
                  <th title="Sort?">So</th>
                  <th title="SortOrder?">Or</th>
                  <th title="Default?">Default?</th>
                  <th title="FieldType">FieldType</th>
                </tr>
              </thead>
              <tbody>
                {PageObject.Columns.map((col, index) => (
                  <tr>
                    <td>
                      <Input
                        className="bg-white uppercase"
                        placeholder=""
                        value={col.TabName}
                        disabled={true}
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-white uppercase"
                        placeholder=""
                        value={col.FieldName}
                        disabled={true}
                      ></Input>
                    </td>
                    <td>
                      <SortType Value={col.SortType.toString()}  Disabled={false} OnSelect={(val) =>   FieldListChanged("SortType", index, val) } />
                    </td>
                    <td>
                      <Input
                        className="bg-white w-20"
                        placeholder=""
                        value={col.SortOrder}
                        disabled={false}
                        type="number"
                        onChange={(e) =>
                          FieldListChanged("SortOrder", index, e.target.value)
                        }
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-white w-28"
                        placeholder=""
                        value={col.DefaultValue}
                        disabled={false}
                        type="text"
                        onChange={(e) =>
                          FieldListChanged("DefaultValue", index, e.target.value)
                        }
                      ></Input>
                    </td>
                    <td>
                    <Input
                        className="bg-white lowercase"
                        placeholder=""
                        value={col.FieldType}
                        disabled={false}
                        onChange={(e) =>
                          FieldListChanged("FieldType", index, e.target.value)
                        }
                      ></Input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabsContent>
          {/* Tab 5 : Field Source */}
          <TabsContent value="fieldsource" className="flex flex-col gap-2">
            <table className="w-full">
              <thead>
                <tr className="font-unbold text-left">
                  <th>Table</th>
                  <th>Field</th>
                  <th title="VSource?">Value Source</th>
                  <th title="VTable?">Value Table</th>
                  <th title="VField?">Value Field</th>
                  <th title="TField?">Text Field</th>
                  <th title="Filter=">Filter</th>
                </tr>
              </thead>
              <tbody>
                {PageObject.Columns.map((col, index) => (
                  <tr>
                 <td className="w-[120px]">
                      <Input
                        className="bg-white uppercase"
                        placeholder=""
                        value={col.TabName}
                        disabled={true}
                      ></Input>
                    </td>
                    <td className="w-[120px]">
                      <Input
                        className="bg-white uppercase"
                        placeholder=""
                        value={col.FieldName}
                        disabled={true}
                      ></Input>
                    </td>
                    <td className="w-[120px]">
                      <Input
                        className="bg-white uppercase"
                        placeholder=""
                        value={col.ValueSource}
                        onChange={(e) =>
                          FieldListChanged("ValueSource", index, e.target.value)
                        }
                        disabled={false}
                      ></Input>
                    </td>
                    <td className="w-[120px]">
                      <Input
                        className="bg-white uppercase"
                        placeholder=""
                        value={col.ValueTable}
                        onChange={(e) =>
                          FieldListChanged("ValueTable", index, e.target.value)
                        }
                        disabled={false}
                      ></Input>
                    </td>
                    <td className="w-[120px]">
                      <Input
                        className="bg-white uppercase"
                        placeholder=""
                        value={col.ValueField}
                        onChange={(e) =>
                          FieldListChanged("ValueField", index, e.target.value)
                        }
                        disabled={false}
                      ></Input>
                    </td>
                    <td className="w-[120px]">
                      <Input
                        className="bg-white uppercase"
                        placeholder=""
                        value={col.TextField}
                        onChange={(e) =>
                          FieldListChanged("TextField", index, e.target.value)
                        }
                        disabled={false}
                      ></Input>
                    </td>
                    <td className="w-auto">
                      <Input
                        className="bg-white uppercase w-full"
                        placeholder=""
                        value={col.ValueFilter}
                        onChange={(e) =>
                          FieldListChanged("ValueFilter", index, e.target.value)
                        }
                        disabled={false}
                      ></Input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabsContent>
        </Tabs>
        <Toaster />
      </div>
    </div>
  );
}

export default ChangeViewDetail;
