import React from "react";
import Hero from "../components/Hero";
//import { Switch } from "@/shadcn/ui/switch";
// import { Switch } from "@/shadcn/ui/switch";
// import { Switch } from "@/shadcn/ui/switch";
// import { Switch } from "@/shadcn/ui/switch";
//import { Switch } from "@/shadcn/ui/switch";
// import { Switch } from "@/shadcn/ui/switch";

//import { Switch } from "./../shadcn/ui/switch";
import { Switch } from "@/shadcn/ui/switch";

function NotFound() {
  return (
    <div className="min-h-[500px] flex flex-col items-center justify-center text-4xl">
      <div className="font-bold text-6xl">404</div> <br />
      <div> Not found</div>
      <Switch id="airplane-mode" />
    </div>
  );
}

export default NotFound;
