import { useEffect, useState } from "react"
import { SearchHelp } from "../../comps/SearchHelp";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";
import { useNavigate } from "react-router-dom";
import { Toaster } from "@/shadcn/ui/toaster";
import { Icon } from "@iconify/react";
import { Label } from "@/shadcn/ui/label";
import { toast } from "@/shadcn/ui/use-toast";
import DomainService from "@/services/dd/DomainService";
import UserService from "@/services/idm/UserService";
import ScreenService from "@/services/program/ScreenService";
import { OrcScreen } from "@/models/screen/OrcScreen";
import { ScreenEvent } from "@/models/Enums";



export default function UserManagement() {

    const navigate = useNavigate();

    let initialscreen: OrcScreen = new OrcScreen();
    initialscreen.UIPath =  encodeURIComponent(window.location.pathname.toLowerCase());
    initialscreen.HRef = window.location.href;
    initialscreen.ProgName = "";
    initialscreen.ScreenEvent = ScreenEvent.Get;

    const[username,SetUsername] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [pagefound, setPageFound] = useState(false);
    const [unAuthorized, setunAuthorized] = useState(false);

    
  const GetHistory = () => {
    if(localStorage.getItem("USERNAME"))
    {
      const uname = localStorage.getItem("USERNAME");
      if(uname!==null)
        SetUsername(uname);
    }

  }

    useState(() => {
      //get history
      GetHistory();
      
      
    });

    
  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoaded(false);
    const resp = await ScreenService.post(initialscreen);
    if (resp.data) {
      console.log(resp.data);
      setunAuthorized(resp.data.UnAuthorized);
    } else {
      setLoaded(true);
      setPageFound(false);
    }
    debugger;
  };

    const Create = async () => {
        if(username.length>0){
            if (username.includes(" ")) {
                toast({
                  title: "Check UserName",
                  description: "Usernames should have no blank.",
                });
              } else {
                var userExist = await DomainService.isExist(username);
                if (userExist === false) {
                  localStorage.setItem("USERNAME", username);
                  navigate("/apps/users/create/" + username);
                } else {
                  toast({
                    title: "User already exist.",
                    description:
                      "User already created, check your name and try again.",
                  });
                }
              }
        }
        else
        {
            toast({
                title: "Please enter username to create"
              });
        }


    }

    const Change = async () => {
        if (username.length > 0) {
            var userExist = await UserService.isExist(username);
            if (userExist) {
              localStorage.setItem("USERNAME", username);
              navigate("/apps/users/change/" + username);
            } else {
              toast({
                title: "User does not exist.",
                description: "Please check your name and try again.",
              });
            }
          } else {
            toast({
              title: "Please select user",
            });
          }
    }

    const Delete = async () => {
        if (username.length > 0) {
            var userExist = await UserService.isExist(username);
            if (userExist) {
              localStorage.setItem("USERNAME", username);
              navigate("/apps/users/delete/" + username);
            } else {
              toast({
                title: "User does not exist.",
                description: "Please check your name and try again.",
              });
            }
          } else {
            toast({
              title: "Please select user",
            });
          }
    }

    const PasswordChange = async () => {
      if (username.length > 0) {
          var userExist = await UserService.isExist(username);
          if (userExist) {
            localStorage.setItem("USERNAME", username);
            navigate("/apps/users/passchange/" + username);
          } else {
            toast({
              title: "User does not exist.",
              description: "Please check your name and try again.",
            });
          }
        } else {
          toast({
            title: "Please select user",
          });
        }
  }

  const UnAuthorizedView = (
    <div className="min-h-[500px] flex flex-col items-center justify-center text-4xl text-primary">
      <div className="font-bold text-6xl mb-4">401</div>
      <div>
        Page{" "}
        <span className="text-red-600">
          {decodeURIComponent(initialscreen.ProgName.toLowerCase())}
        </span>{" "}
        Not authorized
      </div>
    </div>
  );

    const Display = async () => {
        if (username.length > 0) {
            var userExist = await UserService.isExist(username);
            if (userExist) {
              localStorage.setItem("USERNAME", username);
              navigate("/apps/users/display/" + username);
            } else {
              toast({
                title: "User does not exist.",
                description: "Please check your name and try again.",
              });
            }
          } else {
            toast({
              title: "Please select user",
            });
          }
    }

    const OnUserNameSelect = (x: string) => {
        SetUsername(x);
      };


    return (unAuthorized === false ? (<div className="bg-white w-full h-screen p-4">
        <h1 className="font-bold text-2xl">User Management</h1>
        <div className="mb-4">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink
                className="hover:cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/apps/exec/basis/develop");
                }}
              >
                Development
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Select User</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <Separator className="my-4" />
     {/* Commands Start */}
     <div className="flex flex-row flex-nowrap my-2 gap-1 mb-10">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Create()}
          title="Create"
        >
          <Icon
            icon="akar-icons:paper"
            width="18"
            height="18"
            className="text-black bg-white"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Change()}
          title="Change"
        >
          <Icon
            icon="solar:pen-outline"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Display()}
          title="Display"
        >
          <Icon
            icon="tabler:zoom"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Delete()}
          title="Delete"
        >
          <Icon
            icon="material-symbols-light:delete-outline"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => PasswordChange()}
          title="Password Change"
        >
          <Icon
            icon="carbon:password"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
      </div>
      {/* Commands End */}
      <div className="flex items-center">
        <div className="w-28">
        <Label className="ms-2">User Name</Label>
        </div>
        <SearchHelp
              SHName="USERNAME"
              className="ms-20 w-96"
              onChange={(e) => SetUsername(e.target.value)}
              value={username}
              OnSelect={(x) => OnUserNameSelect(x)}
            />
      </div>

    <Toaster />
    </div>)  : UnAuthorizedView)


}