import { Icon } from "@iconify/react";
import React from "react";

function MyAbsenceDaysTile() {
  return (
    <div className="relative w-52 h-52 p-4 bg-red-100 text-foreground hover:bg-opacity-35 hover:cursor-pointer transition duration-200 hover:scale-110">
      My Absence Days
      <div className="w-auto absolute rounded-full h-16 bottom-12 left-4 ">
        <p className="text-red-700 text-7xl">10</p>
        <p className="w-full text-gray-600">days missing</p>
      </div>
    </div>
  );
}

export default MyAbsenceDaysTile;
