
import React, { Component } from 'react';
import OrgChart from './MyTree';
import OrgChartNS from '@balkangraph/orgchart.js';

///https://balkan.app/OrgChartJS/Docs/Fields
////https://code.balkan.app/org-chart-js/first-look#JS

export default class OrcOrgChart extends Component {
  constructor(props: {}) {
      super(props);

      
  }

  

  render() {
      return (
          <div style={{height: '100%', width:'1200px'}}>

              <OrgChart   
              layout={OrgChartNS.tree}           
                nodes={[
                    // { id: 1, name: "my Company", title: "??", img: "https://cdn.balkan.app/shared/2.jpg" },
                    { id:1, field_0: "New Organization", field_1: "??"},
                    // { id: 2, pid: 1, name: "Ashley Barnett", title: "Sales Manager", img: "https://cdn.balkan.app/shared/3.jpg" },
                    // { id: 3, pid: 1, name: "Caden Ellison", title: "Dev Manager", img: "https://cdn.balkan.app/shared/4.jpg" },
                    // { id: 4, pid: 2, name: "Elliot Patel", title: "Sales", img: "https://cdn.balkan.app/shared/5.jpg" },
                    // { id: 5, pid: 2, name: "Lynn Hussain", title: "Sales", img: "https://cdn.balkan.app/shared/6.jpg" },
                    // { id: 6, pid: 3, name: "Tanner May", title: "Developer", img: "https://cdn.balkan.app/shared/7.jpg" },
                    // { id: 7, pid: 3, name: "Fran Parsons", title: "Developer", img: "https://cdn.balkan.app/shared/8.jpg" }
                ]}

               
              
              />
          </div>
      );
  }
}
